@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.aside {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: auto 1fr auto;
   background: $white;

   grid-row-gap: vw(24px, 1920px);
   padding: vw(24px, 1920px);
   border-right: vw(1px, 1920px) solid $light;

   z-index: 2;

   @media screen and (min-width: 1600px) {
      grid-row-gap: 24px;
      padding: 24px;
      border-right: 1px solid $light;
   }
}
