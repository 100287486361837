@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.yaRek {
   margin: 0px auto;
   width: 100%;
   padding: 32px 16px;

   @media screen and (min-width: 526px) {
      padding: 32px 24px;
   }
   @media screen and (min-width: 921px) {
      width: vw(1200px);
      max-height: vw(260px);
      padding: 64px 0px;
   }
   @media screen and (min-width: 1440px) {
      width: 1200px;
      max-height: 260px;
      padding: 64px 0px;
   }

   &:empty {
      display: none;
   }
}
