@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.block {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: flex-start;

   gap: vw(12px, 1920px);

   &_column {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: flex-start;

      img {
         object-fit: cover;
         background-size: cover;
         width: vw(256px, 1920px);
         height: vw(128px, 1920px);
         border-radius: vw(10px, 1920px);

         @media screen and (min-width: 1600px) {
            width: 256px;
            height: 128px;
            border-radius: 10px;
         }
      }
   }

   span {
      font-weight: 500;
      letter-spacing: 0em;
      text-align: left;

      font-size: vw(20px, 1920px);
      line-height: vw(20px, 1920px);
      @media screen and (min-width: 1600px) {
         font-size: 20px;
         line-height: 20px;
      }
   }

   @media screen and (min-width: 1600px) {
      gap: 12px;
   }
}

.field {
   font-weight: 600 !important;
   letter-spacing: 0em;
   text-align: left;

   font-size: vw(20px, 1920px);
   line-height: vw(20px, 1920px);

   cursor: pointer;

   @media screen and (min-width: 1600px) {
      font-size: 20px;
      line-height: 20px;
   }

   &__wait {
      background: $light;
      

      width: 30%;
      height: vw(24px, 1920px);
      border-radius: vw(4px, 1920px);

      cursor: not-allowed;

      @media screen and (min-width: 1600px) {
         height: 24px;
         border-radius: 4px;
      }
   }
}

.bio {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: repeat(2, auto);
   column-gap: vw(24px, 1920px);
   row-gap: vw(24px, 1920px);

   @media screen and (min-width: 1600px) {
      column-gap: 24px;
      row-gap: 24px;
   }
}

.passport {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: auto;
   column-gap: vw(24px, 1920px);
   row-gap: vw(24px, 1920px);

   &_row {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: auto;
      column-gap: vw(24px, 1920px);
      row-gap: vw(24px, 1920px);

      @media screen and (min-width: 1600px) {
         column-gap: 24px;
         row-gap: 24px;
      }
   }

   @media screen and (min-width: 1600px) {
      column-gap: 24px;
      row-gap: 24px;
   }
}

.incomes {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: auto;
   column-gap: vw(24px, 1920px);
   row-gap: vw(24px, 1920px);

   @media screen and (min-width: 1600px) {
      column-gap: 24px;
      row-gap: 24px;
   }
}

.actions {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: flex-start;
   gap: vw(24px, 1920px);

   @media screen and (min-width: 1600px) {
      gap: 24px;
   }
}

.red {
   background: $error !important;
   color: $white !important;
}

.buttons {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: space-between;
}

.attantion {
   font-weight: 400;
   letter-spacing: -0.01em;
   text-align: left;

   font-size: vw(18px, 1920px);
   line-height: vw(22px, 1920px);

   @media screen and (min-width: 1600px) {
      font-size: 18px;
      line-height: 22px;
   }

   &_small {
      font-weight: 500;
      letter-spacing: -0.01em;
      text-align: left;

      font-size: vw(14px, 1920px);
      line-height: vw(17px, 1920px);

      @media screen and (min-width: 1600px) {
         font-size: 14px;
         line-height: 17px;
      }
   }
}

.wrapper {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   justify-content: space-between;
   height: 100%;

   &__accept {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: vw(24px, 1920px);
      @media screen and (min-width: 1600px) {
         gap: 24px;
      }
   }
}
