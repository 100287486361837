@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.container {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: auto 1fr;
   row-gap: vw(48px, 1920px);
   margin-bottom: vw(64px, 1920px);

   @media screen and (min-width: 1600px) {
      row-gap: 48px;
      margin-bottom: 64px;
   }

   h2 {
      font-weight: 600;
      letter-spacing: -0.01em;
      text-align: left;

      font-size: vw(32px, 1920px);
      line-height: vw(32px, 1920px);

      @media screen and (min-width: 1600px) {
         font-size: 32px;
         line-height: 32px;
      }
   }
}
