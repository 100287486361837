@import "../../../styles/helpers.scss";

.contentButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 0px vw(32px, 320px);

    @media screen and (min-width: 526px) {
        padding: 0px 0px vw(32px, 744px);
    }

    @media screen and (min-width: 921px) {
        padding: 0px 0px vw(64px);
    }

    @media screen and (min-width: 1440px) {
        padding: 0px 0px 64px;
    }
    
    .contentButton__button {
        width: vw(240px, 320px);

        @media screen and (min-width: 526px) {
            width: vw(240px, 744px);
        }

        @media screen and (min-width: 921px) {
            width: vw(240px);
        }

        @media screen and (min-width: 1440px) {
            width: 240px;
        }
    }
}