/* Размеры шрифтов в пикселях */
:root {
   /* font-family-heading */
   --font-family-heading: "Raleway", sans-serif;

   /* font-family-text */
   --font-family-text: "Noto Sans", sans-serif;

   /* font-weight */
   --font-weight-regular: 400;
   --font-weight-madium: 500;
   --font-weight-bold: 600;
}

/* Сброс декораций текста */
@mixin reset-text-decoration() {
   text-decoration: none;
   text-transform: none;
   font-feature-settings: "pnum" on, "lnum" on;
}

/* Heading */
@mixin head-1() {
   font-size: vw(38px, 320px);
   font-family: var(--font-family-heading);
   font-weight: var(--font-weight-bold);
   font-style: normal;
   line-height: 120%;
   letter-spacing: 0.035em;
   @include reset-text-decoration();

   @media screen and (min-width: 526px) {
      font-size: vw(48px, 744px);
   }

   @media screen and (min-width: 921px) {
      font-size: vw(48px);
   }

   @media screen and (min-width: 1440px) {
      font-size: 48px;
   }
}

@mixin head-2() {
   font-size: vw(24px, 320px);
   font-family: var(--font-family-heading);
   font-weight: var(--font-weight-bold);
   font-style: normal;
   line-height: 130%;
   letter-spacing: 0.015em;
   @include reset-text-decoration();

   @media screen and (min-width: 526px) {
      font-size: vw(32px, 744px);
   }

   @media screen and (min-width: 921px) {
      font-size: vw(32px);
   }

   @media screen and (min-width: 1440px) {
      font-size: 32px;
   }
}

@mixin head-3() {
   font-size: vw(22px, 320px);
   font-family: var(--font-family-heading);
   font-weight: var(--font-weight-madium);
   font-style: normal;
   line-height: 130%;
   @include reset-text-decoration();

   @media screen and (min-width: 526px) {
      font-size: vw(24px, 744px);
   }

   @media screen and (min-width: 921px) {
      font-size: vw(24px);
   }

   @media screen and (min-width: 1440px) {
      font-size: 24px;
   }
}

@mixin head-4() {
   font-size: vw(18px, 320px);
   font-family: var(--font-family-heading);
   font-weight: var(--font-weight-madium);
   font-style: normal;
   line-height: 130%;
   @include reset-text-decoration();

   @media screen and (min-width: 526px) {
      font-size: vw(20px, 744px);
   }

   @media screen and (min-width: 921px) {
      font-size: vw(20px);
   }

   @media screen and (min-width: 1440px) {
      font-size: 20px;
   }
}

@mixin head-5() {
   font-size: vw(14px, 320px);
   font-family: var(--font-family-heading);
   font-weight: var(--font-weight-bold);
   font-style: normal;
   line-height: 130%;
   @include reset-text-decoration();

   @media screen and (min-width: 526px) {
      font-size: vw(16px, 744px);
   }

   @media screen and (min-width: 921px) {
      font-size: vw(16px);
   }

   @media screen and (min-width: 1440px) {
      font-size: 16px;
   }
}

/* Text */
@mixin text-regular() {
   font-size: vw(14px, 320px);
   font-family: var(--font-family-text);
   font-weight: var(--font-weight-regular);
   font-style: normal;
   line-height: 150%;
   @include reset-text-decoration();

   @media screen and (min-width: 526px) {
      font-size: vw(16px, 744px);
   }

   @media screen and (min-width: 921px) {
      font-size: vw(16px);
   }

   @media screen and (min-width: 1440px) {
      font-size: 16px;
   }
}

@mixin text-medium() {
   font-size: vw(14px, 320px);
   font-family: var(--font-family-text);
   font-weight: var(--font-weight-madium);
   font-style: normal;
   line-height: 150%;
   @include reset-text-decoration();

   @media screen and (min-width: 526px) {
      font-size: vw(16px, 744px);
   }

   @media screen and (min-width: 921px) {
      font-size: vw(16px);
   }

   @media screen and (min-width: 1440px) {
      font-size: 16px;
   }
}

@mixin sub-text() {
   font-size: vw(12px, 320px);
   font-family: var(--font-family-text);
   font-weight: var(--font-weight-regular);
   font-style: normal;
   line-height: 150%;
   @include reset-text-decoration();

   @media screen and (min-width: 526px) {
      font-size: vw(14px, 744px);
   }

   @media screen and (min-width: 921px) {
      font-size: vw(14px);
   }

   @media screen and (min-width: 1440px) {
      font-size: 14px;
   }
}

@mixin descriptions() {
   font-size: vw(14px, 320px);
   font-family: var(--font-family-text);
   font-weight: var(--font-weight-regular);
   font-style: normal;
   line-height: 150%;
   letter-spacing: 0.8px;
   @include reset-text-decoration();

   @media screen and (min-width: 526px) {
      font-size: vw(16px, 744px);
   }

   @media screen and (min-width: 921px) {
      font-size: vw(16px);
   }

   @media screen and (min-width: 1440px) {
      font-size: 16px;
   }
}

@mixin sup-text() {
   font-size: vw(12px, 320px);
   font-family: var(--font-family-text);
   font-weight: var(--font-weight-regular);
   font-style: normal;
   line-height: 100%;
   @include reset-text-decoration();

   @media screen and (min-width: 526px) {
      font-size: vw(12px, 744px);
   }

   @media screen and (min-width: 921px) {
      font-size: vw(12px);
   }

   @media screen and (min-width: 1440px) {
      font-size: 12px;
   }
}

/* Elements */
@mixin button() {
   font-size: vw(16px, 320px);
   font-family: var(--font-family-text);
   font-weight: var(--font-weight-madium);
   font-style: normal;
   line-height: 100%;
   @include reset-text-decoration();

   @media screen and (min-width: 526px) {
      font-size: vw(16px, 744px);
   }

   @media screen and (min-width: 921px) {
      font-size: vw(16px);
   }

   @media screen and (min-width: 1440px) {
      font-size: 16px;
   }
}

@mixin input() {
   font-size: vw(16px, 320px);
   font-family: var(--font-family-text);
   font-weight: var(--font-weight-regular);
   font-style: normal;
   line-height: 100%;
   @include reset-text-decoration();

   @media screen and (min-width: 526px) {
      font-size: vw(16px, 744px);
   }

   @media screen and (min-width: 921px) {
      font-size: vw(16px);
   }

   @media screen and (min-width: 1440px) {
      font-size: 16px;
   }
}

@mixin overview() {
   font-size: vw(14px, 320px);
   font-family: var(--font-family-text);
   font-weight: var(--font-weight-regular);
   font-style: normal;
   line-height: 130%;
   @include reset-text-decoration();

   @media screen and (min-width: 526px) {
      font-size: vw(14px, 744px);
   }

   @media screen and (min-width: 921px) {
      font-size: vw(14px);
   }

   @media screen and (min-width: 1440px) {
      font-size: 14px;
   }
}
