@import "../../../styles/helpers.scss";
@import "../../../styles/colors.scss";

.loader {
   width: 100%;
   height: vw(640px, 1920px);
   background: $white;
   border-radius: vw(8px, 1920px);

   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: center;

   @media screen and (min-width: 1600px) {
      height: 380px;
      border-radius: 8px;
   }
}
