@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";
@import "../../../styles/effects.scss";
@import "../../../styles/mixins.scss";

.inputRange {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    background: #C0E89A;

    height: vw(8px, 320px);
    border-radius: vw(4px, 320px);
    margin: vw(8px, 320px) 0px;
    box-shadow: 0px vw(4px, 320px) vw(6px, 320px) inset rgba($color: $black, $alpha: 0.1);

    @media screen and (min-width: 525px) {
        height: vw(8px, 744px);
        border-radius: vw(4px, 744px);
        margin: vw(8px, 744px) 0px;
        box-shadow: 0px vw(4px, 744px) vw(6px, 744px) inset rgba($color: $black, $alpha: 0.1);
    }

    @media screen and (min-width: 920px) {
        height: vw(8px);
        border-radius: vw(4px);
        margin: vw(8px) 0px;
        box-shadow: 0px vw(4px) vw(6px) inset rgba($color: $black, $alpha: 0.1);
    }

    @media screen and (min-width: 1440px) {
        height: 8px;
        border-radius: 4px;
        margin: 8px 0px;
        box-shadow: 0px 4px 6px inset rgba($color: $black, $alpha: 0.1);
    }

    &::before {
        content: ' ';
        display: block;
        background: $sec;
        
        width: vw(24px, 320px);
        min-height: vw(8px, 320px);
        max-height: vw(8px, 320px);
        border-radius: vw(8px, 320px);

        @media screen and (min-width: 525px) {
            width: vw(24px, 744px);
            min-height: vw(8px, 744px);
            max-height: vw(8px, 744px);
            border-radius: vw(8px, 744px);
        }

        @media screen and (min-width: 920px) {
            width: vw(24px);
            min-height: vw(8px);
            max-height: vw(8px);
            border-radius: vw(8px);
        }

        @media screen and (min-width: 1440px) {
            width: 24px;
            min-height: 8px;
            max-height: 8px;
            border-radius: 8px;
        }
    }
    
    input {
        display: none;
    }

    div:last-of-type {
        background: $sec;
    }

    div:first-of-type {
        display: none;
    }

    div:nth-of-type(2) {
        background: $sec;
        position: absolute;
        border: solid $white;

        width: vw(24px, 320px);
        height: vw(24px, 320px);
        border-radius: vw(12px, 320px);
        border-width: vw(6px, 320px);
        box-shadow: 0px vw(4px, 320px) vw(8px, 320px) rgba($color: $black, $alpha: 0.1);

        @media screen and (min-width: 525px) {
            width: vw(24px, 744px);
            height: vw(24px, 744px);
            border-radius: vw(12px, 744px);
            border-width: vw(6px, 744px);
            box-shadow: 0px vw(4px, 744px) vw(8px, 744px) rgba($color: $black, $alpha: 0.1);
        }

        @media screen and (min-width: 920px) {
            width: vw(24px);
            height: vw(24px);
            border-radius: vw(12px);
            border-width: vw(6px);
            box-shadow: 0px vw(4px) vw(8px) rgba($color: $black, $alpha: 0.1);
        }

        @media screen and (min-width: 1440px) {
            width: 24px;
            height: 24px;
            border-radius: 12px;
            border-width: 6px;
            box-shadow: 0px 4px 8px rgba($color: $black, $alpha: 0.1);
        }
    }

    &__wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    &__text {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        margin-bottom: vw(4px, 320px);

        @media screen and (min-width: 525px) {
            margin-bottom: vw(4px, 744px);
        }

        @media screen and (min-width: 920px) {
            margin-bottom: vw(4px);
        }

        @media screen and (min-width: 1440px) {
            margin-bottom: 4px;
        }
    }

    &__label, &__value {
        @include head-4();
    }

    &__lines {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        
        padding-top: vw(4px, 320px);

        @media screen and (min-width: 525px) {
            padding-top: vw(4px, 744px);
        }

        @media screen and (min-width: 920px) {
            padding-top: vw(4px);
        }

        @media screen and (min-width: 1440px) {
            padding-top: 4px;
        }
    }

    &__linesGroup {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        &:first-child > *:first-child {
            margin-left: 0px;
        }

        & > *:nth-child(2) {
            display: none;
        }

        &:last-child > *:last-child {
            margin-right: 0px;
        }

        @media screen and (min-width: 525px) {
            & > *:nth-child(2) {
                display: block;
            }
        }
    }

    &__linesItem {
        border: solid $gray-x;
        width: 0px;
        margin: 0px auto;

        border-width: vw(1px, 320px);
        height: vw(6px, 320px);
        border-radius: vw(2px, 320px);

        @media screen and (min-width: 525px) {
            border-width: vw(1px, 744px);
            height: vw(6px, 744px);
            border-radius: vw(2px, 744px);
        }

        @media screen and (min-width: 920px) {
            border-width: vw(1px);
            height: vw(6px);
            border-radius: vw(2px);
        }

        @media screen and (min-width: 1440px) {
            border-width: 1px;
            height: 6px;
            border-radius: 2px;
        }

        &_long {

            height: vw(10px, 320px);

            @media screen and (min-width: 525px) {
                height: vw(10px, 744px);
            }
            @media screen and (min-width: 920px) {
                height: vw(10px);
            }
            @media screen and (min-width: 1440px) {
                height: 10px;
            }
        }
    }
}