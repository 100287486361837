@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

@import "../../../styles/helpers.scss";

.wrapper {
   position: relative;

   display: grid;
   grid-template-columns: vw(340px, 1920px) 1fr;
   grid-template-rows: vw(100px, 1920px) 1fr;

   @media screen and (min-width: 1600px) {
      grid-template-columns: 340px 1fr;
      grid-template-rows: 100px 1fr;
   }

   &__aside {
      position: fixed;
      height: 100%;
      width: vw(340px, 1920px);
      grid-area: 1 / 1 / 3 / 2;

      @media screen and (min-width: 1600px) {
         width: 340px;
      }
   }

   &__header {
      position: fixed;
      height: vw(100px, 1920px);
      top: 0px;
      width: 100%;
      padding-left: vw(364px, 1920px);
      padding-right: vw(24px, 1920px);
      grid-area: 1 / 2 / 2 / 3;

      @media screen and (min-width: 1600px) {
         height: 100px;
         padding-left: 364px;
         padding-right: 24px;
      }
   }

   &__main {
      padding: vw(24px, 1920px) vw(64px, 1920px);
      grid-area: 2 / 2 / 3 / 3;

      @media screen and (min-width: 1600px) {
         padding: 24px 64px;
      }
   }

   &__settings {
      padding: vw(24px, 1920px) 0px;
      grid-area: 2 / 2 / 3 / 3;

      @media screen and (min-width: 1600px) {
         padding: 24px 0px;
      }
   }

   * > a {
      color: black;
   }
}

.link {
   position: absolute;
   bottom: 0px;
   padding: vw(20px, 1920px) vw(90px, 1920px);

   @media screen and (min-width: 1600px) {
      padding: 20px 90px;
   }

   &__logo {
      width: 100%;
      height: auto;
   }
}
