@import "../../styles/colors.scss";
@import "../../styles/helpers.scss";
@import "../../styles/mixins.scss";
@import "../../styles/effects.scss";

.PaymentUnauthorized {
   &__container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: auto;
      width: 100vw;

      padding: 0px vw(16px, 320px) 0px;
      gap: vw(32px, 320px);

      @media screen and (min-width: 526px) {
         padding: 0px vw(24px, 744px) 0px;
         gap: vw(32px, 744px);
      }

      @media screen and (min-width: 921px) {
         padding: 0px;
         gap: vw(32px);
      }

      @media screen and (min-width: 1440px) {
         gap: 32px;
      }
   }

   &__wrapper {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      margin: vw(64px, 320px) 0px vw(32px, 320px);

      @media screen and (max-width: 525px) {
         padding: vw(48px, 320px) 0px;
      }

      @media screen and (min-width: 526px) {
         width: vw(380px, 744px);
         margin: vw(128px, 744px) 0px vw(64px, 744px);
      }

      @media screen and (min-width: 921px) {
         width: vw(380px);
         margin: vw(128px) 0px vw(64px);
      }

      @media screen and (min-width: 1440px) {
         width: 380px;
         margin: 128px 0px 64px;
      }
   }

   &__iconWraper {
      position: absolute;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: $white;
      top: 0px;
      transform: translateY(-50%);

      @include shadow-bottom-low();

      width: vw(64px, 320px);
      height: vw(64px, 320px);
      border-radius: vw(32px, 320px);

      @media screen and (min-width: 526px) {
         width: vw(96px, 744px);
         height: vw(96px, 744px);
         border-radius: vw(48px, 744px);
      }

      @media screen and (min-width: 921px) {
         width: vw(96px);
         height: vw(96px);
         border-radius: vw(48px);
      }

      @media screen and (min-width: 1440px) {
         width: 96px;
         height: 96px;
         border-radius: 48px;
      }
   }
   
   &__icon {
      width: vw(48px, 320px);
      height: vw(48px, 320px);
      
      @media screen and (min-width: 526px) {
         width: vw(64px, 744px);
         height: vw(64px, 744px);
      }

      @media screen and (min-width: 921px) {
         width: vw(64px);
         height: vw(64px);
      }

      @media screen and (min-width: 1440px) {
         width: 64px;
         height: 64px;
      }
   }

   &__title {
      text-align: center;

      @include head-3();

      margin-bottom: vw(16px, 320px);

      @media screen and (min-width: 526px) {
         margin-bottom: vw(16px, 744px);
      }

      @media screen and (min-width: 921px) {
         margin-bottom: vw(16px);
      }

      @media screen and (min-width: 1440px) {
         margin-bottom: 16px;
      }
   }

   &__text {
      text-align: center;

      @include text-regular();

      margin-bottom: vw(16px, 320px);

      @media screen and (min-width: 526px) {
         margin-bottom: vw(16px, 744px);
      }

      @media screen and (min-width: 921px) {
         margin-bottom: vw(16px);
      }

      @media screen and (min-width: 1440px) {
         margin-bottom: 16px;
      }
   }

   &__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      gap: vw(12px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(32px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(32px);
      }

      @media screen and (min-width: 1440px) {
         gap: 32px;
      }
   }

   &__input {
      width: 100%;
   }

   &__button {
      @media screen and (max-width: 525px) {
         width: 100%;
      }
   }

   &__secondaryText {
      width: 100%;
      text-align: center;
      color: $gray;

      @include sub-text();

      a {
         color: $sec;
      }

      &:not(:first-of-type) {
         margin-top: vw(-26px, 320px);

         @media screen and (min-width: 526px) {
            margin-top: vw(-26px, 744px);
         }

         @media screen and (min-width: 921px) {
            margin-top: vw(-26px);
         }

         @media screen and (min-width: 1440px) {
            margin-top: -26px;
         }
      }
   }
}

.wrapper {
   display: flex;
   flex-direction: column;

   gap: vw(16px, 320px);

   @media screen and (min-width: 526px) {
      gap: vw(12px, 744px);
   }

   @media screen and (min-width: 921px) {
      gap: vw(12px);
   }

   @media screen and (min-width: 1440px) {
      gap: 12px;
   }
}

.container {
   display: flex;

   min-width: 100%;
   padding: 0px vw(16px, 320px);
   padding-top: vw(92px, 320px);

   @media screen and (min-width: 526px) {
      row-gap: vw(24px, 744px);
      padding: 0px vw(16px, 744px);
      padding-top: vw(164px, 744px);
   }

   @media screen and (min-width: 921px) {
      padding: 0px vw(16px);
      padding-top: vw(164px);
      min-width: vw(1600px);
      margin: 0px auto;
   }

   @media screen and (min-width: 1440px) {
      row-gap: 24px;
      min-width: 1600px;
      padding: 0px 16px;
      padding-top: 164px;
   }
}


