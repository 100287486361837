@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.container {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: repeat(3, auto);
   row-gap: vw(64px, 1920px);

   @media screen and (min-width: 1600px) {
      row-gap: 64px;
   }
}

.cards {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   grid-template-rows: auto;
   column-gap: vw(26px, 1920px);

   @media screen and (min-width: 1600px) {
      column-gap: 26px;
   }
}

.card {
   display: grid;
   grid-template-columns: vw(80px, 1920px) 1fr vw(52px, 1920px);
   grid-template-rows: vw(80px, 1920px) 1fr;
   column-gap: vw(24px, 1920px);
   row-gap: vw(8px, 1920px);

   @media screen and (min-width: 1600px) {
      grid-template-columns: 80px 1fr 52px;
      grid-template-rows: 80px 1fr;
      column-gap: 24px;
      row-gap: 8px;
   }

   &__title {
      grid-area: 1 / 2 / 2 / 3;
      font-weight: 500;
      letter-spacing: 0em;
      text-align: left;

      color: $main-dark;

      font-size: vw(32px, 1920px);
      line-height: vw(32px, 1920px);
      max-width: vw(500px, 1920px);

      @media screen and (min-widht: 1600px) {
         font-size: 32px;
         line-height: 32px;
         max-width: 500px;
      }
   }

   &__wrapper {
      grid-area: 2 / 2 / 3 / 3;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: space-between;

      gap: vw(12px, 1920px);

      @media screen and (min-widht: 1600px) {
         gap: 12px;
      }
   }

   &__actions {
      grid-area: 1 / 3 / 3 / 4;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
   }

   &__link {
      a {
         font-weight: 500;
         letter-spacing: 0em;
         text-align: left;

         font-size: vw(16px, 1920px);
         line-height: vw(16px, 1920px);

         @media screen and (min-widht: 1600px) {
            font-size: 16px;
            line-height: 16px;
         }
      }
      &_status {
         font-weight: 700;
         letter-spacing: 0em;
         text-align: left;

         color: $main;

         font-size: vw(16px, 1920px);
         line-height: vw(16px, 1920px);

         @media screen and (min-widht: 1600px) {
            font-size: 16px;
            line-height: 16px;
         }
      }
      &_tel {
         grid-area: 2 / 2 / 3 / 3;

         display: flex;
         flex-direction: row;
         flex-wrap: nowrap;
         align-items: center;
         justify-content: flex-start;

         gap: vw(12px, 1920px);

         @media screen and (min-widht: 1600px) {
            gap: 12px;
         }

         svg {
            cursor: pointer;
            width: vw(24px, 1920px);
            height: vw(24px, 1920px);

            @media screen and (min-widht: 1600px) {
               width: 24px;
               height: 24px;
            }
         }
      }

      &_email {
         grid-area: 3 / 2 / 4 / 3;

         display: flex;
         flex-direction: row;
         flex-wrap: nowrap;
         align-items: center;
         justify-content: flex-start;

         gap: vw(12px, 1920px);

         @media screen and (min-widht: 1600px) {
            gap: 12px;
         }

         svg {
            cursor: pointer;
            width: vw(24px, 1920px);
            height: vw(24px, 1920px);

            @media screen and (min-widht: 1600px) {
               width: 24px;
               height: 24px;
            }
         }
      }
   }
}

.avatar {
   grid-area: 1 / 1 / 3 / 2;
   border-radius: 100%;
   width: vw(80px, 1920px);
   height: vw(80px, 1920px);

   @media screen and (min-width: 1600px) {
      width: 80px;
      height: 80px;
   }
}

.link {
   grid-area: 1 / 2 / 2 / 3;

   display: grid;
   grid-template-columns: repeat(2, 1fr);
   grid-template-rows: auto 1fr;
   row-gap: vw(20px, 1920px);

   align-items: end;
   justify-items: stretch;

   @media screen and (min-widht: 1600px) {
      row-gap: 20px;
   }
}

.link__header {
   grid-area: 1 / 1 / 2 / 3;

   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: space-between;

   font-weight: 500;
   letter-spacing: -0.01em;
   text-align: left;

   font-size: vw(32px, 1920px);
   line-height: vw(32px, 1920px);

   @media screen and (min-widht: 1600px) {
      font-size: 32px;
      line-height: 32px;
   }

   svg {
      width: vw(24px, 1920px);
      height: vw(24px, 1920px);

      @media screen and (min-widht: 1600px) {
         width: 24px;
         height: 24px;
      }
   }
}

.link__block {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   align-items: flex-start;
   justify-content: flex-start;

   gap: vw(8px, 1920px);

   @media screen and (min-widht: 1600px) {
      gap: 8px;
   }
}

.link__title {
   font-weight: 500;
   letter-spacing: -0.01em;
   text-align: left;

   color: $secondary-dark;

   font-size: vw(20px, 1920px);
   line-height: vw(24px, 1920px);

   @media screen and (min-widht: 1600px) {
      font-size: 20px;
      line-height: 24px;
   }
}

.link__subtitle {
   font-weight: 600;
   letter-spacing: -0.01em;
   text-align: left;

   color: $main-dark;

   font-size: vw(32px, 1920px);
   line-height: vw(38px, 1920px);

   @media screen and (min-widht: 1600px) {
      font-size: 32px;
      line-height: 38px;
   }
}

.buttons {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: space-between;
}

.attantion {
   font-weight: 400;
   letter-spacing: -0.01em;
   text-align: left;

   font-size: vw(18px, 1920px);
   line-height: vw(22px, 1920px);

   @media screen and (min-width: 1600px) {
      font-size: 18px;
      line-height: 22px;
   }

   &_small {
      font-weight: 500;
      letter-spacing: -0.01em;
      text-align: left;

      font-size: vw(14px, 1920px);
      line-height: vw(17px, 1920px);

      @media screen and (min-width: 1600px) {
         font-size: 14px;
         line-height: 17px;
      }
   }
}

.wrapper {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   justify-content: space-between;
   height: 100%;

   &__accept {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: vw(24px, 1920px);
      @media screen and (min-width: 1600px) {
         gap: 24px;
      }
   }
}
