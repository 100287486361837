@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.pageEditor {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-start;
    position: relative;

    height: calc(100vh - #{vw(100px, 1920px)});
    margin: vw(-24px, 1920px) vw(-64px, 1920px);
    
    @media screen and (min-width: 1600px) {
        height: calc(100vh - 100px);
        margin: -24px -64px;
    }

    &__loading {
        position: absolute;
        width: 100%;
        background: $white;
        z-index: 1;
        height: 100%;
        overflow: hidden;

        transition: opacity 0.3s ease-in-out;

        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: stretch;

        &_fade {
            opacity: 0;
        }

        gap: vw(24px, 1920px);
        padding: vw(24px, 1920px);
        
        @media screen and (min-width: 1600px) {
            gap: 24px;
            padding: 24px;
        }
    }

    &__loadingBlocks {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;
        width: 100%;

        gap: vw(24px, 1920px);
        
        @media screen and (min-width: 1600px) {
            gap: 24px;
        }
    }

    &__loadingTools {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;

        gap: vw(24px, 1920px);
        min-width: vw(400px, 1920px);
        
        @media screen and (min-width: 1600px) {
            gap: 24px;
            min-width: 400px;
        }
    }

    &__loadingBlock {
        background: $light;
        width: 100%;

        transition: min-height 0.3s ease-in-out;

        animation: loading-animation 1.5s ease-in-out 0s infinite;

        @keyframes loading-animation {
            0% {
                opacity: 0.2;
            }
            50% {
                opacity: 0.8;
            }
            100% {
                opacity: 0.2;
            }
        }

        border-radius: vw(15px, 1920px);
        
        @media screen and (min-width: 1600px) {
            border-radius: 15px;
        }
    }

    &__view {
        overflow: scroll;
        position: relative;
        display: flex;
        justify-content: center;

        width: 100%;
        height: 100%;
        
        @media screen and (min-width: 1600px) {

        }
    }

    &__builder {
        position: absolute;
        transform: scale(0.8);
        transform-origin: top center;

        width: 120%;

        padding-top: vw(32px, 1920px);

        & > * {
            width: 100%;
        }

        
        @media screen and (min-width: 1600px) {
            padding-top: 32px;
        }
    }

    &__tools {
        border-left: solid $light;
        overflow-y: auto;

        border-left-width: vw(1px, 1920px);
        min-width: vw(400px, 1920px);
        height: 100%;
        padding: vw(24px, 1920px) vw(16px, 1920px);
        
        @media screen and (min-width: 1600px) {
            border-left-width: 1px;
            min-width: 400px;
            // max-width: 400px;
            padding: 24px 16px;
        }

        & > * {
            width: 100%;
        }
    }

    &__prop {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: flex-start;

        gap: 12px;

        &:not(:last-child) {
            margin-bottom: 24px;
            padding-bottom: 24px;
            border-bottom: 1px solid $light;
        }
    }

    &__propName {
        font-size: 14px;
    }
}

.modalText {
    font-size: vw(18px, 1920px);
    line-height: vw(22px, 1920px);
    min-height: vw(100px, 1920px);
    
    @media screen and (min-width: 1600px) {
        font-size: 18px;
        line-height: 22px;
        min-height: 100px;
    }
}

.modalButtons {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: stretch;

    gap: vw(24px, 1920px);
    margin-top: vw(21px, 1920px);
    
    @media screen and (min-width: 1600px) {
        gap: 24px;
        margin-top: 21px;
    }

    & > * {
        width: 100%;
    }
}