@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";

.clientHeaderBlock {
   display: none;

   @media screen and (min-width: 921px) {
      display: flex;
      flex-direction: row;
      align-items: center;

      gap: vw(16px);
   }

   @media screen and (min-width: 1440px) {
      gap: 16px;
   }

   &__infoBlock {
      display: flex;
      flex-direction: row;
      align-items: center;
      transition: background 0.3s ease-in-out;

      @media screen and (min-width: 921px) {
         gap: vw(8px);
         padding: 0px vw(16px);
         border-radius: vw(12px);
         height: vw(42px);
      }
   
      @media screen and (min-width: 1440px) {
         gap: 8px;
         padding: 0px 16px;
         border-radius: 12px;
         height: 42px;
      }

      &:hover {
         background: $gray-sx;
      }

      &:active {
         background: $gray-sx;
      }
   }

   &__avatar {
      @media screen and (min-width: 921px) {
         min-width: vw(32px);
         min-height: vw(32px);
         max-width: vw(32px);
         max-height: vw(32px);
      }
   
      @media screen and (min-width: 1440px) {
         min-width: 32px;
         min-height: 32px;
         max-width: 32px;
         max-height: 32px;
      }
   }

   &__phone {
      white-space: nowrap;
      color: $sec-dark;
      @include text-regular();
   }

   &__logOut {
      padding: 0px;

      @media screen and (min-width: 921px) {
         width: vw(42px);
      }
   
      @media screen and (min-width: 1440px) {
         width: 42px;
      }
   }

   &__logOutIcon {
      @media screen and (min-width: 921px) {
         min-width: vw(20px);
         min-height: vw(20px);
         max-width: vw(20px);
         max-height: vw(20px);
      }
   
      @media screen and (min-width: 1440px) {
         min-width: 20px;
         min-height: 20px;
         max-width: 20px;
         max-height: 20px;
      }
   }
}
