@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.table {
   position: relative;
   border-collapse: collapse;
   display: block;
   width: 100%;
   overflow-x: auto;

   & > div {
      margin-left: auto;
      margin-right: auto;
   }

   padding-top: 24px;

   @media screen and (min-width: 525px) {
   }

   @media screen and (min-width: 921px) {
   }

   @media screen and (min-width: 1600px) {
   }

   &__head {
      width: 100%;
   }

   &__headRow {
      height: vw(36px, 320px);

      @media screen and (min-width: 525px) {
         height: vw(48px, 744px);
      }

      @media screen and (min-width: 921px) {
         height: vw(48px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         height: 48px;
      }
   }

   &__headCell {
      font-weight: 500;
      position: relative;
      border: 0px solid $secondary-dark;
      color: $secondary-dark;
      white-space: nowrap;

      width: 100%;

      font-size: vw(14px, 320px);
      line-height: vw(17px, 320px);
      padding: 0px vw(6px, 320px) 0px;
      border-bottom-width: vw(2px, 320px);

      @media screen and (min-width: 525px) {
         font-size: vw(20px, 744px);
         line-height: vw(20px, 744px);
         padding: 0px vw(24px, 744px) vw(12px, 744px);
         border-bottom-width: vw(2px, 744px);
      }

      @media screen and (min-width: 921px) {
         font-size: vw(20px, 1920px);
         line-height: vw(20px, 1920px);
         padding: 0px vw(24px, 1920px) vw(12px, 1920px);
         border-bottom-width: vw(2px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         font-size: 20px;
         line-height: 20px;
         padding: 0px 24px 12px;
         border-bottom-width: 2px;
      }
   }

   &__sortButton {
      border: none;
      background: none;
      display: inline-flex;
      vertical-align: middle;
      cursor: pointer;
      color: $secondary-dark;

      margin-left: vw(12px, 320px);

      @media screen and (min-width: 525px) {
         margin-left: vw(12px, 744px);
      }

      @media screen and (min-width: 921px) {
         margin-left: vw(12px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         margin-left: 12px;
      }
   }

   &__sortIcon {
      width: vw(24px, 320px);
      height: vw(24px, 320px);

      @media screen and (min-width: 525px) {
         width: vw(24px, 744px);
         height: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         width: vw(24px, 1920px);
         height: vw(24px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         width: 24px;
         height: 24px;
      }

      path {
         fill: $secondary-dark;
         transition: fill 0.3s ease-in-out;
      }

      &_asc {
         path:first-child {
            fill: $main-dark;
         }
      }

      &_desc {
         path:last-child {
            fill: $main-dark;
         }
      }
   }

   &__body {
   }

   &__row {
   }

   &__cell {
      font-weight: 500;
      border: 0px solid $light;
      white-space: nowrap;

      font-size: vw(14px, 320px);
      line-height: vw(17px, 320px);
      height: vw(44px, 320px);
      padding: 0px vw(6px, 320px) 0px;
      border-bottom-width: vw(1px, 320px);

      @media screen and (min-width: 525px) {
         font-size: vw(16px, 744px);
         line-height: vw(20px, 744px);
         height: vw(80px, 744px);
         padding: vw(12px, 744px) vw(24px, 744px) 0px;
         border-bottom-width: vw(1px, 744px);
      }

      @media screen and (min-width: 921px) {
         font-size: vw(16px, 1920px);
         line-height: vw(20px, 1920px);
         height: vw(80px, 1920px);
         padding: vw(12px, 1920px) vw(24px, 1920px) 0px;
         border-bottom-width: vw(1px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         font-size: 16px;
         line-height: 20px;
         height: 80px;
         padding: 12px 24px 0px;
         border-bottom-width: 1px;
      }
   }

   &__cellSuccess {
      color: $main;
   }

   &__cellError {
      color: $error;
   }
}
