@import "../../styles/colors.scss";
@import "../../styles/effects.scss";
@import "../../styles/helpers.scss";
@import "../../styles/mixins.scss";

.modalContent {

    &__text {
        @include text-regular();
        text-align: center;

        margin-bottom: vw(16px, 320px);

        @media screen and (min-width: 526px) {
            margin-bottom: vw(16px, 744px);
        }
        @media screen and (min-width: 921px) {
            margin-bottom: vw(16px);
        }
        @media screen and (min-width: 1440px) {
            margin-bottom: 16px;
        }
    }
    
    &__qrcode {
        display: block;
        margin: 0px auto;
        background: $gray-sx;
        
        width: vw(240px, 320px);
        height: vw(240px, 320px);
        padding: vw(12px, 320px);
        border-radius: vw(12px, 320px);

        @media screen and (min-width: 526px) {
            width: vw(180px, 744px);
            height: vw(180px, 744px);
            padding: vw(12px, 744px);
            border-radius: vw(12px, 744px);
        }

        @media screen and (min-width: 921px) {
            width: vw(180px);
            height: vw(180px);
            padding: vw(12px);
            border-radius: vw(12px);
        }

        @media screen and (min-width: 1440px) {
            width: 180px;
            height: 180px;
            padding: 12px;
            border-radius: 12px;
        }
    }

    &__copiedFields {
        display: flex;
        flex-direction: column;
        width: 100%;

        gap: vw(8px, 320px);
        margin-bottom: vw(18px, 320px);

        @media screen and (min-width: 526px) {
            gap: vw(8px, 744px);
            margin-bottom: vw(18px, 744px);
        }

        @media screen and (min-width: 921px) {
            gap: vw(8px);
            margin-bottom: vw(18px);
        }

        @media screen and (min-width: 1440px) {
            gap: 8px;
            margin-bottom: 18px;
        }
    }
    
    &__button {
        width: 100%;
        
        margin-top: auto;

        @media screen and (min-width: 526px) {
            margin-top: vw(32px, 744px);
        }

        @media screen and (min-width: 921px) {
            margin-top: vw(32px);
        }

        @media screen and (min-width: 1440px) {
            margin-top: 32px;
        }
    }

    &__buttonRow {
        display: flex;
        flex-direction: column;
        width: 100%;

        gap: vw(12px, 320px);

        @media screen and (min-width: 526px) {
            flex-direction: row;
            
            gap: vw(12px, 744px);
        }

        @media screen and (min-width: 921px) {
            gap: vw(12px);
        }

        @media screen and (min-width: 1440px) {
            gap: 12px;
        }

        & > * {
            width: 100%;
        }
    }

    &__supports {
        display: flex;
        flex-direction: column;
        width: 100%;
        
        gap: vw(12px, 320px);

        @media screen and (min-width: 526px) {
            gap: vw(12px, 744px);
        }

        @media screen and (min-width: 921px) {
            gap: vw(12px);
        }

        @media screen and (min-width: 1440px) {
            gap: 12px;
        }
    }
}