@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.timer {
   display: grid;
   grid-template-columns: repeat(3, vw(64px, 320px));
   grid-template-rows: vw(48px, 320px);
   column-gap: vw(10px, 320px);

   @media screen and (min-width: 526px) {
      grid-template-columns: repeat(3, vw(80px, 744px));
      grid-template-rows: vw(60px, 744px);
      column-gap: vw(10px, 744px);
   }

   @media screen and (min-width: 921px) {
      grid-template-columns: repeat(3, vw(80px));
      grid-template-rows: vw(60px);
      column-gap: vw(10px);
   }

   @media screen and (min-width: 1440px) {
      grid-template-columns: repeat(3, 80px);
      grid-template-rows: 60px;
      column-gap: 10px;
   }

   &__block {
      display: flex;
      width: 100%;
      height: 100%;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: stretch;
   }

   &__number,
   &__label {
      color: $black;
      text-align: center;
   }

   &__number {
      @include head-2();
   }

   &__label {
      @include text-regular();
   }
}
