@import "../../../styles/colors.scss";
@import "../../../styles/effects.scss";
@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";

.paymentForm {
    display: flex;
    flex-direction: column;

    width: calc(100vw - #{vw(32px, 320px)});
    padding: vw(24px, 320px) 0px vw(32px, 320px);
    margin: 0px vw(16px, 320px);

    @media screen and (min-width: 526px) {
        width: calc(100vw - #{vw(48px, 744px)});
        padding: vw(64px, 744px) 0px;
        margin: 0px vw(24px, 744px);
    }

    @media screen and (min-width: 921px) {
        padding: vw(64px) 0px;
        width: vw(796px);
        margin: 0px auto;
    }

    @media screen and (min-width: 1440px) {
        padding: 64px 0px;
        width: 796px;
    }

    &__header {
        @include head-3();
        
        margin-bottom: vw(24px, 320px);

        @media screen and (min-width: 526px) {
            margin-bottom: vw(24px, 744px);
        }
    
        @media screen and (min-width: 921px) {
            margin-bottom: vw(24px);
        }
    
        @media screen and (min-width: 1440px) {
            margin-bottom: 24px;
        }
    }
    
    
    &__selects {
        width: 100%;
        display: flex;
        flex-direction: column;

        gap: vw(24px, 320px);
        margin-bottom: vw(32px, 320px);

        @media screen and (min-width: 526px) {
            flex-direction: row;

            gap: vw(24px, 744px);
            margin-bottom: vw(32px, 744px);
        }
    
        @media screen and (min-width: 921px) {
            gap: vw(24px);
            margin-bottom: vw(32px);
        }
    
        @media screen and (min-width: 1440px) {
            gap: 24px;
            margin-bottom: 32px;
        }
    }
    
    &__select {
        width: 100%;
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 526px) {

        }
    
        @media screen and (min-width: 921px) {
    
        }
    
        @media screen and (min-width: 1440px) {
    
        }
    }
    
    &__selectLabel {
        @include head-5();

        margin-bottom: vw(12px, 320px);

        @media screen and (min-width: 526px) {
            margin-bottom: vw(12px, 744px);
        }
    
        @media screen and (min-width: 921px) {
            margin-bottom: vw(12px);
        }
    
        @media screen and (min-width: 1440px) {
            margin-bottom: 12px;
        }
    }
    
    &__selectInput {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    

        @media screen and (min-width: 526px) {

        }
    
        @media screen and (min-width: 921px) {
    
        }
    
        @media screen and (min-width: 1440px) {
    
        }
    }

    &__selectWrapper {
        background: $white;
        @include shadow-bottom-element();
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;
        
        min-height: vw(78px, 320px);
        max-height: vw(78px, 320px);
        padding: vw(16px, 320px);
        border-radius: vw(12px, 320px);
        gap: vw(8px, 320px);

        @media screen and (min-width: 526px) {
            flex-direction: row;
            justify-content: space-between;
            
            min-height: vw(78px, 744px);
            max-height: vw(78px, 744px);
            padding: vw(16px, 744px);
            border-radius: vw(12px, 744px);
            gap: vw(8px, 744px);
        }
    
        @media screen and (min-width: 921px) {
            min-height: vw(78px);
            max-height: vw(78px);
            padding: vw(16px);
            border-radius: vw(12px);
            gap: vw(8px);
        }
    
        @media screen and (min-width: 1440px) {
            min-height: 78px;
            max-height: 78px;
            padding: 16px;
            border-radius: 12px;
            gap: 8px;
        }
    }
    
    
    &__target {
        display: flex;
        flex-direction: column;

        gap: vw(4px, 320px);

        @media screen and (min-width: 526px) {
            gap: vw(4px, 744px);
        }
    
        @media screen and (min-width: 921px) {
            gap: vw(4px);
        }
    
        @media screen and (min-width: 1440px) {
            gap: 4px;
        }
    }
    
    &__targetNumber {
        @include text-regular();
    }
    
    &__targetOwe {
        @include sub-text();
    }
    
    &__targetIcon {
        width: vw(44px, 320px);
        height: vw(44px, 320px);

        @media screen and (min-width: 526px) {
            width: vw(44px, 744px);
            height: vw(44px, 744px);
        }
    
        @media screen and (min-width: 921px) {
            width: vw(44px);
            height: vw(44px);
        }
    
        @media screen and (min-width: 1440px) {
            width: 44px;
            height: 44px;
        }
    }
    
    
    &__amount {
        display: flex;
        flex-direction: column;
        
        gap: vw(12px, 320px);

        @media screen and (min-width: 526px) {
            gap: vw(12px, 744px);
        }
    
        @media screen and (min-width: 921px) {
            gap: vw(12px);
        }
    
        @media screen and (min-width: 1440px) {
            gap: 12px;
        }
    }
    
    &__amountForm {
        display: flex;
        flex-direction: column;
        
        gap: vw(24px, 320px);

        @media screen and (min-width: 526px) {
            flex-direction: row;
            align-items: flex-end;

            gap: vw(12px, 744px);
        }
    
        @media screen and (min-width: 921px) {
            gap: vw(12px);
        }
    
        @media screen and (min-width: 1440px) {
            gap: 12px;
        }
    }

    &__amountFormInput {
        display: flex;
        flex-direction: column;
        width: 100%;
        
        gap: vw(4px, 320px);

        @media screen and (min-width: 526px) {
            gap: vw(4px, 744px);
        }
    
        @media screen and (min-width: 921px) {
            gap: vw(4px);
        }
    
        @media screen and (min-width: 1440px) {
            gap: 4px;
        }
    }
    
    &__amountInput {
        width: 100%;
    }
    
    &__submit {
        width: 100%;

        @media screen and (min-width: 526px) {
            min-width: vw(180px, 744px);
            max-width: vw(180px, 744px);
        }
    
        @media screen and (min-width: 921px) {
            min-width: vw(180px);
            max-width: vw(180px);
        }
    
        @media screen and (min-width: 1440px) {
            min-width: 180px;
            max-width: 180px;
        }
    }
    
    &__amountText {
        @include sub-text();
    }
}