@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.infoHtml,
.infoHtml__text {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

.infoHtml {
   margin: 0px auto;
   width: 100%;
   padding: 32px 16px;

   @media screen and (min-width: 526px) {
      padding: 32px 24px;
   }
   @media screen and (min-width: 921px) {
      width: vw(1200px);
      padding: 64px 0px;
   }
   @media screen and (min-width: 1440px) {
      width: 1200px;
      padding: 64px 0px;
   }

   &__text {
      text-align: center;
      width: 100%;
      max-width: none;

      & > *,
      & > div,
      & > div > * {
         display: inline;
         @include text-regular();
         color: $black;
      }

      & > * > a,
      & > a {
         text-decoration: none;
         transition: color ease-in-out 0.3s;
         color: $sec;

         &:hover,
         &:focus,
         &:active {
            color: $sec-dark;
         }
      }

      & > * > b,
      & > b,
      & > * > u,
      & > u,
      & > * > s,
      & > s {
         @include text-medium();
      }

      @media screen and (min-width: 526px) {
         max-width: vw(480px, 744px);
      }
      @media screen and (min-width: 921px) {
         max-width: vw(480px);
      }
      @media screen and (min-width: 1440px) {
         max-width: 480px;
      }
   }
}
