@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.footer {
   // display: block;
   // border-top: vw(1px, 320px) solid $gray-x;
   // margin-bottom: vw(52px, 320px);

   display: none;

   @media screen and (min-width: 526px) {
      display: block;
      border-top: vw(1px, 744px) solid $gray-x;
      margin-bottom: vw(64px, 744px);
   }

   @media screen and (min-width: 921px) {
      border-top: vw(1px) solid $gray-x;
      margin-bottom: 0px;
   }

   @media screen and (min-width: 1440px) {
      border-top: 1px solid $light;
   }

   &__wrapper {
      display: flex;
      flex-direction: column;
      gap: vw(12px, 320px);
      padding: vw(24px, 320px) vw(16px, 320px);
      width: 100%;
      max-width: none;
      margin: 0 auto;

      @media screen and (min-width: 526px) {
         gap: vw(12px, 744px);
         padding: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(12px);
         padding: vw(40px) 0px;
         max-width: vw(1200px);
      }

      @media screen and (min-width: 1440px) {
         gap: 12px;
         padding: 40px 0px;
         max-width: 1200px;
      }
   }

   &__text,
   &__link {
      @include text-regular();
      color: $black;
   }

   &__link {
      @include text-medium();
      transition: color ease-in-out 0.3s;
      color: $sec;

      &:hover,
      &:focus,
      &:active {
         color: $sec-dark;
      }
   }
}
