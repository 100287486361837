@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.archive {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: repeat(3, auto);
   row-gap: vw(64px, 1920px);

   @media screen and (min-width: 1600px) {
      row-gap: 64px;
   }

   &__list {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      gap: vw(12px, 1920px);

      @media screen and (min-width: 1600px) {
         gap: 12px;
      }
   }
}
