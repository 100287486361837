@import "../../../styles/helpers.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

.advantagesBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100vw - #{vw(32px, 320px)});
    
    margin: vw(32px, 320px) vw(16px, 320px);
    gap: vw(32px, 320px);

    @media screen and (min-width: 526px) {
        width: calc(100vw - #{vw(48px, 744px)});
        margin: vw(32px, 744px) vw(24px, 744px);
        gap: vw(32px, 744px);
    }

    @media screen and (min-width: 921px) {
        width: vw(1200px);
        margin: vw(64px) auto vw(48px);
        gap: vw(32px);
    }

    @media screen and (min-width: 1440px) {
        width: 1200px;
        margin: 64px auto 48px;
        gap: 32px;
    }

    &__header {
        @include head-2();

        @media screen and (min-width: 526px) {
            
        }

        @media screen and (min-width: 921px) {

        }

        @media screen and (min-width: 1440px) {

        }
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        
        gap: vw(24px, 320px);

        @media screen and (min-width: 526px) {
            gap: vw(24px, 744px);
        }

        @media screen and (min-width: 921px) {
            gap: vw(24px);
        }

        @media screen and (min-width: 1440px) {
            gap: 24px;
        }
    }

    &__card {
        display: flex;
        flex-direction: column;
        background: $gray-sx;
        
        width: vw(288px, 320px);
        height: vw(320px, 320px);
        padding: vw(16px, 320px);
        gap: vw(20px, 320px);
        border-radius: vw(24px, 320px);

        @media screen and (min-width: 526px) {
            width: vw(336px, 744px);
            height: vw(320px, 744px);
            padding: vw(16px, 744px);
            gap: vw(20px, 744px);
            border-radius: vw(24px, 744px);
        }

        @media screen and (min-width: 921px) {
            width: vw(282px);
            height: vw(320px);
            padding: vw(16px);
            gap: vw(20px);
            border-radius: vw(24px);
        }

        @media screen and (min-width: 1440px) {
            width: 282px;
            height: 320px;
            padding: 16px;
            gap: 20px;
            border-radius: 24px;
        }
    }

    &__cardIcon {
        width: vw(48px, 320px);
        height: vw(48px, 320px);

        @media screen and (min-width: 526px) {
            width: vw(64px, 744px);
            height: vw(64px, 744px);
        }

        @media screen and (min-width: 921px) {
            width: vw(64px);
            height: vw(64px);
        }

        @media screen and (min-width: 1440px) {
            width: 64px;
            height: 64px;
        }
    }

    &__cardTitle {
        @include head-3();

        @media screen and (min-width: 526px) {
            
        }

        @media screen and (min-width: 921px) {

        }

        @media screen and (min-width: 1440px) {

        }
    }

    &__cardText {
        @include text-regular();

        @media screen and (min-width: 526px) {
            
        }

        @media screen and (min-width: 921px) {

        }

        @media screen and (min-width: 1440px) {

        }
    }
}