@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/helpers.scss";

.inputHint {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    

    @media screen and (min-width: 526px) {
        position: relative;
        flex-direction: row;

        height: vw(42px, 744px);
    }

    @media screen and (min-width: 921px) {
        height: vw(42px);
    }

    @media screen and (min-width: 1440px) {
        height: 42px;
    }

    &__input {
        width: 100%;
        
        margin-bottom: vw(8px, 320px);

        @media screen and (min-width: 526px) {
            margin-bottom: 0px;
        }

        & > input, & > textarea {
            min-height: vw(40px, 320px);
            max-height: vw(40px, 320px);
    
            @media screen and (min-width: 526px) {
                min-height: vw(42px, 744px);
                max-height: vw(42px, 744px);
            }
        
            @media screen and (min-width: 921px) {
                min-height: vw(42px);
                max-height: vw(42px);
            }
        
            @media screen and (min-width: 1440px) {
                min-height: 42px;
                max-height: 42px;
            }
        }
    }

    &__optionsList {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        gap: vw(12px, 320px);

        @media screen and (min-width: 526px) {
            position: absolute;
            top: 0px;
            right: 0px;
            height: inherit;
            
            padding: 0px vw(6px, 744px);
            gap: vw(8px, 744px);
        }
        
        @media screen and (min-width: 921px) {
            padding: 0px vw(6px);
            gap: vw(8px);
        }
        
        @media screen and (min-width: 1440px) {
            padding: 0px 6px;
            gap: 8px;
        }
    }

    &__option {
        background: $gray-sx;

        @include sub-text();

        border-radius: vw(16px, 320px);
        min-height: vw(32px, 320px);
        max-height: vw(32px, 320px);
        padding: 0px vw(24px, 320px);

        @media screen and (min-width: 526px) {
            min-height: vw(32px, 744px);
            max-height: vw(32px, 744px);
            border-radius: vw(8px, 744px);
            padding: 0px vw(24px, 744px);
        }
        
        @media screen and (min-width: 921px) {
            min-height: vw(32px);
            max-height: vw(32px);
            border-radius: vw(8px);
            padding: 0px vw(24px);
        }
        
        @media screen and (min-width: 1440px) {
            min-height: 32px;
            max-height: 32px;
            border-radius: 8px;
            padding: 0px 24px;
        }
        
        &:hover {
            background: $gray-x;
        }
    }
}