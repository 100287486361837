@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.document {
   position: relative;

   display: flex;
   flex-direction: column;
   align-items: flex-end;
   justify-content: space-between;
   background-color: $gray-sx;
   padding: vw(18px, 320px) vw(16px, 320px);
   border-radius: vw(12px, 320px);
   gap: vw(8px, 320px);

   @media screen and (min-width: 526px) {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: vw(20px, 744px);
      border-radius: vw(12px, 744px);
      gap: vw(8px, 744px);
   }
   @media screen and (min-width: 921px) {
      padding: vw(20px);
      border-radius: vw(12px);
      gap: vw(8px);
   }
   @media screen and (min-width: 1440px) {
      padding: 20px;
      border-radius: 12px;
      gap: 8px;
   }

   &__check,
   &__heading {
      display: flex;
      flex-direction: row;
   }

   &__heading {
      align-items: flex-start;
      gap: vw(8px, 320px);

      @media screen and (min-width: 526px) {
         align-items: center;
         gap: vw(16px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(16px);
      }
      @media screen and (min-width: 1440px) {
         gap: 16px;
      }
   }
   &__icon {
      min-width: vw(24px, 320px);
      min-height: vw(24px, 320px);
      max-width: vw(24px, 320px);
      max-height: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         min-width: vw(42px, 744px);
         min-height: vw(42px, 744px);
         max-width: vw(42px, 744px);
         max-height: vw(42px, 744px);
      }
      @media screen and (min-width: 921px) {
         min-width: vw(42px);
         min-height: vw(42px);
         max-width: vw(42px);
         max-height: vw(42px);
      }
      @media screen and (min-width: 1440px) {
         min-width: 42px;
         min-height: 42px;
         max-width: 42px;
         max-height: 42px;
      }
   }
   &__checkTitle,
   &__name {
      @include text-regular();
      color: $black;
   }
   &__check {
      align-items: center;
      gap: vw(12px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(14px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(14px);
      }
      @media screen and (min-width: 1440px) {
         gap: 14px;
      }
   }
   &__link {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      width: 100%;
      height: 100%;
   }
}
