@import "../../../styles/helpers.scss";
@import "../../../styles/effects.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.mobileСarousel {
   display: flex;
   flex-direction: column;
   min-height: vw(280px, 320px);

   &__heading {
      width: 100%;
   }
}

.toggleCarousel {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   max-height: vw(60px, 320px);
   overflow-x: scroll;
   overflow-y: hidden;
   width: vw(320px, 320px);
   padding: 0px vw(16px, 320px);
   margin: 0px vw(-16px, 320px);
   margin-bottom: vw(15px, 320px);

   /* Скрываем скроллбар вебкит-браузеров */
   &::-webkit-scrollbar {
      width: 0px; /* Ширина скроллбара */
   }

   &::-webkit-scrollbar-track {
      background: transparent; /* Цвет фона трека (за скроллбаром) */
   }

   &::-webkit-scrollbar-thumb {
      background: transparent; /* Цвет ползунка скроллбара */
   }

   &::-webkit-scrollbar-thumb:hover {
      background: transparent; /* Цвет ползунка при наведении */
   }
}

.toggleButton {
   cursor: pointer;
   border: none;
   transition: background-color ease-in-out 0.3s;
   background-color: transparent;
   white-space: nowrap;
   padding: vw(4px, 320px) vw(12px, 320px);
   gap: vw(8px, 320px);
   border-radius: vw(16px, 320px);

   &__active {
      background-color: $gray-sx;
   }
}
