@import "../../../styles/colors.scss";
@import "../../../styles/effects.scss";
@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";

.modals {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100vw;
    height: 100vh;
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;

    &__background {
        display: none;

        @media screen and (min-width: 526px) {
            display: block;
            position: absolute;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
    
            background: rgba($color: $black, $alpha: 0.7);
        }
    }
}

.modal {
    position: absolute;
    background: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    padding: vw(32px, 320px) 0px 0px;

    @media screen and (min-width: 526px) {
        max-height: 80vw;
        height: auto;
        width: vw(380px, 744px);
        padding: vw(24px, 744px) 0px 0px;
        border-radius: vw(24px, 744px);

        @include shadow-bottom-low();
    }

    @media screen and (min-width: 921px) {
        width: vw(380px);
        padding: vw(24px) 0px 0px;
        border-radius: vw(24px);
    }

    @media screen and (min-width: 1440px) {
        width: 380px;
        padding: 24px 0px 0px;
        border-radius: 24px;
    }

    &_show {
        animation: showModal 0.3s ease-in-out 0s 1;
    
        @keyframes showModal {
            0% {
                opacity: 0;
                transform: scale(0);
            }
    
            100% {
                opacity: 1;
                transform: scale(1);
            }
        }
    }

    &_fade {
        opacity: 0;
        transform: scale(0);
        animation: closeModal 0.3s ease-in-out 0s 1;

        @media screen and (min-width: 526px) {
            transform: translateY(50px);
        }

        @keyframes closeModal {
            0% {
                opacity: 1;
                transform: scale(1);
            }
    
            100% {
                opacity: 0;
                transform: scale(0);
            }
        }
    }

    &__wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__header {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        margin-bottom: vw(28px, 320px);
        padding: 0px vw(16px, 320px);

        @media screen and (min-width: 526px) {
            margin-bottom: vw(16px, 744px);
            padding: 0px vw(24px, 744px);
        }

        @media screen and (min-width: 921px) {
            margin-bottom: vw(16px);
            padding: 0px vw(24px);
        }

        @media screen and (min-width: 1440px) {
            margin-bottom: 16px;
            padding: 0px 24px;
        }
    }
    
    &__clodeButton {
        padding: 0px;

        width: vw(32px, 320px);
        min-height: vw(32px, 320px);
        max-height: vw(32px, 320px);

        @media screen and (min-width: 526px) {
            width: vw(32px, 744px);
            min-height: vw(32px, 744px);
            max-height: vw(32px, 744px);
        }

        @media screen and (min-width: 921px) {
            width: vw(32px);
            min-height: vw(32px);
            max-height: vw(32px);
        }

        @media screen and (min-width: 1440px) {
            width: 32px;
            min-height: 32px;
            max-height: 32px;
        }

        &:hover, &:active, &:focus {
            background: none;
        }
    }
    
    &__closeIcon {
    

        width: vw(32px, 320px);
        height: vw(32px, 320px);

        @media screen and (min-width: 526px) {
            width: vw(32px, 744px);
            height: vw(32px, 744px);
        }

        @media screen and (min-width: 921px) {
            width: vw(32px);
            height: vw(32px);
        }

        @media screen and (min-width: 1440px) {
            width: 32px;
            height: 32px;
        }
    }
    
    &__iconWrapper {
        position: absolute;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: $white;

        @include shadow-bottom-low();

        top: vw(12px, 320px);
        width: vw(64px, 320px);
        height: vw(64px, 320px);
        border-radius: vw(48px, 320px);

        @media screen and (min-width: 526px) {
            top: 0px;
            transform: translateY(-50%);
            width: vw(96px, 744px);
            height: vw(96px, 744px);
            border-radius: vw(48px, 744px);
        }

        @media screen and (min-width: 921px) {
            width: vw(96px);
            height: vw(96px);
            border-radius: vw(48px);
        }

        @media screen and (min-width: 1440px) {
            width: 96px;
            height: 96px;
            border-radius: 48px;
        }

        & > svg {
            width: vw(48px, 320px);
            height: vw(48px, 320px);

            @media screen and (min-width: 526px) {
                width: vw(64px, 744px);
                height: vw(64px, 744px);
            }

            @media screen and (min-width: 921px) {
                width: vw(64px);
                height: vw(64px);
            }

            @media screen and (min-width: 1440px) {
                width: 64px;
                height: 64px;
            }
        }
    }
    
    &__title {
        width: 100%;
        text-align: center;

        @include head-3();
    
        margin-bottom: vw(12px, 320px);
        padding: 0px vw(16px, 320px);

        @media screen and (min-width: 526px) {
            margin-bottom: vw(12px, 744px);
            padding: 0px vw(24px, 744px);
        }

        @media screen and (min-width: 921px) {
            margin-bottom: vw(12px);
            padding: 0px vw(24px);
        }

        @media screen and (min-width: 1440px) {
            margin-bottom: 12px;
            padding: 0px 24px;
        }
    }
    
    &__content {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;

        overflow: hidden;
        overflow-y: auto;
    
        padding: 0px vw(16px, 320px) vw(16px, 320px);

        @media screen and (min-width: 526px) {
            max-height: 60vh;
            padding: 0px vw(24px, 744px) vw(24px, 744px);
        }

        @media screen and (min-width: 921px) {
            padding: 0px vw(24px) vw(24px);
        }

        @media screen and (min-width: 1440px) {
            padding: 0px 24px 24px;
        }
    }
}