@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.telephone {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: repeat(2, auto);
   row-gap: vw(16px, 320px);

   @media screen and (min-width: 526px) {
      row-gap: vw(48px, 744px);
   }

   @media screen and (min-width: 921px) {
      row-gap: vw(48px);
   }

   @media screen and (min-width: 1440px) {
      row-gap: 48px;
   }

   &__form {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(3, auto);
      row-gap: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         grid-template-columns: vw(240px, 744px) 1fr;
         grid-template-rows: repeat(2, auto);
         row-gap: vw(24px, 744px);
         column-gap: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         grid-template-columns: vw(240px) 1fr;
         row-gap: vw(24px);
         column-gap: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         grid-template-columns: 240px 1fr;
         row-gap: 24px;
         column-gap: 24px;
      }
   }

   &__description {
      grid-area: 1 / 1 / 2 / 2;

      @include sub-text();
      color: $gray;

      @media screen and (min-width: 526px) {
         grid-area: 1 / 1 / 2 / 3;
      }
   }

   &__button {
      grid-area: 3 / 1 / 4 / 2;
      @media screen and (min-width: 526px) {
         grid-area: 2 / 1 / 3 / 2;
      }
   }

   &__input {
      grid-area: 2 / 1 / 3 / 2;
      @media screen and (min-width: 526px) {
         grid-area: 2 / 2 / 3 / 3;
      }
   }

   &__subtext {
      @include sub-text();
      color: $gray;
   }
}
