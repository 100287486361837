@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";
@import "../../../styles/effects.scss";
@import "../../../styles/mixins.scss";

.fileLoader {
   position: relative;
   width: 100%;
   cursor: pointer;

   height: vw(148px, 320px);

   @media screen and (min-width: 526px) {
      height: vw(148px, 744px);
   }

   @media screen and (min-width: 921px) {
      height: vw(148px);
   }

   @media screen and (min-width: 1440px) {
      height: 148px;
   }

   &__input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1;
      cursor: pointer;
   }
   &__wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      height: 100%;
      border: dashed $light;

      padding: vw(16px, 320px);
      border-radius: vw(12px, 320px);
      border-width: vw(1px, 320px);
      gap: vw(8px, 320px);

      @media screen and (min-width: 526px) {
         padding: vw(16px, 744px);
         border-radius: vw(12px, 744px);
         border-width: vw(1px, 744px);
         gap: vw(8px, 744px);
      }

      @media screen and (min-width: 921px) {
         padding: vw(16px);
         border-radius: vw(12px);
         border-width: vw(1px);
         gap: vw(8px);
      }

      @media screen and (min-width: 1440px) {
         padding: 16px;
         border-radius: 12px;
         border-width: 1px;
         gap: 8px;
      }
   }
   &__icon {
      margin-top: vw(8px, 320px);
      width: vw(48px, 320px);
      height: vw(48px, 320px);

      @media screen and (min-width: 526px) {
         margin-top: vw(8px, 744px);
         width: vw(48px, 744px);
         height: vw(48px, 744px);
      }

      @media screen and (min-width: 921px) {
         margin-top: vw(8px);
         width: vw(48px);
         height: vw(48px);
      }

      @media screen and (min-width: 1440px) {
         margin-top: 8px;
         width: 48px;
         height: 48px;
      }
   }
   &__preview {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: cover;

      border-radius: vw(4px, 320px);

      @media screen and (min-width: 526px) {
         border-radius: vw(4px, 744px);
      }

      @media screen and (min-width: 921px) {
         border-radius: vw(4px);
      }

      @media screen and (min-width: 1440px) {
         border-radius: 4px;
      }
   }
   &__text {
      color: $gray;
      text-align: center;

      @include sub-text();
   }

   &__remove {
      position: absolute;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $gray-sx;
      cursor: pointer;
      z-index: 1;

      width: vw(48px, 320px);
      height: vw(48px, 320px);
      border-radius: vw(24px, 320px);
      top: vw(-24px, 320px);
      right: vw(0px, 320px);

      @media screen and (min-width: 526px) {
         width: vw(48px, 744px);
         height: vw(48px, 744px);
         border-radius: vw(24px, 744px);
         top: vw(-24px, 744px);
         right: vw(0px, 744px);
      }

      @media screen and (min-width: 921px) {
         width: vw(48px);
         height: vw(48px);
         border-radius: vw(24px);
         top: vw(-24px);
         right: vw(0px);
      }

      @media screen and (min-width: 1440px) {
         width: 48px;
         height: 48px;
         border-radius: 24px;
         top: -24px;
         right: 0px;
      }

      &::after {
         content: " ";
         background: $error;

         width: vw(19px, 320px);
         height: vw(3px, 320px);
         border-radius: vw(1.5px, 320px);

         @media screen and (min-width: 526px) {
            width: vw(19px, 744px);
            height: vw(3px, 744px);
            border-radius: vw(1.5px, 744px);
         }

         @media screen and (min-width: 921px) {
            width: vw(19px);
            height: vw(3px);
            border-radius: vw(1.5px);
         }

         @media screen and (min-width: 1440px) {
            width: 19px;
            height: 3px;
            border-radius: 1.5px;
         }
      }
   }
}
