@import "../../../styles/helpers.scss";
@import "../../../styles/effects.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.operationsHistory {
   display: flex;
   flex-direction: column;
   gap: vw(12px, 320px);

   @media screen and (min-width: 526px) {
      gap: vw(12px, 744px);
   }
   @media screen and (min-width: 921px) {
      gap: vw(12px);
   }
   @media screen and (min-width: 1440px) {
      gap: 12px;
   }

   &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: vw(84px, 320px);

      @media screen and (min-width: 526px) {
         min-height: vw(84px, 744px);
      }
      @media screen and (min-width: 921px) {
         min-height: vw(84px);
      }
      @media screen and (min-width: 1440px) {
         min-height: 84px;
      }
   }

   &__text {
      @include text-medium();
      color: $gray;
   }
}

.operationsHistoryItem {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-end;
   padding: vw(16px, 320px) vw(8px, 320px);

   @media screen and (min-width: 526px) {
      flex-direction: row;
      justify-content: space-between;
      padding: 0px;
   }
}
