@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/effects.scss";
@import "../../../styles/helpers.scss";

.paymentsList {
    width: 100%;

    @media screen and (min-width: 526px) {
        position: relative;
    }

    &__select {
        display: flex;
        flex-direction: row;
        align-items: center;
        background: $white;
        @include shadow-bottom-element();
        cursor: pointer;
        
        min-height: vw(78px, 320px);
        max-height: vw(78px, 320px);
        gap: vw(8px, 320px);
        padding: vw(16px, 320px);
        border-radius: vw(12px, 320px);

        @media screen and (min-width: 526px) {
            min-height: vw(78px, 744px);
            max-height: vw(78px, 744px);
            gap: vw(12px, 744px);
            padding: vw(16px, 744px);
            border-radius: vw(12px, 744px);
        }

        @media screen and (min-width: 921px) {
            min-height: vw(78px);
            max-height: vw(78px);
            gap: vw(12px);
            padding: vw(16px);
            border-radius: vw(12px);
        }

        @media screen and (min-width: 1440px) {
            min-height: 78px;
            max-height: 78px;
            gap: 12px;
            padding: 16px;
            border-radius: 12px;
        }
    }

    &__selectImg {
        
        min-width: vw(44px, 320px);
        min-height: vw(44px, 320px);
        max-width: vw(44px, 320px);
        max-height: vw(44px, 320px);

        @media screen and (min-width: 526px) {
            min-width: vw(44px, 744px);
            min-height: vw(44px, 744px);
            max-width: vw(44px, 744px);
            max-height: vw(44px, 744px);
        }

        @media screen and (min-width: 921px) {
            min-width: vw(44px);
            min-height: vw(44px);
            max-width: vw(44px);
            max-height: vw(44px);
        }

        @media screen and (min-width: 1440px) {
            min-width: 44px;
            min-height: 44px;
            max-width: 44px;
            max-height: 44px;
        }
    }

    &__selectInfo {
        margin-right: auto;
        display: flex;
        flex-direction: column;
        
        gap: vw(4px, 320px);
        
        @media screen and (min-width: 526px) {
            gap: vw(4px, 744px);
        }

        @media screen and (min-width: 921px) {
            gap: vw(4px);
        }

        @media screen and (min-width: 1440px) {
            gap: 4px;
        }
    }

    &__selectInfoName {
        @include text-regular();
    }

    &__selectInfoDuration {
        @include sub-text();
    }

    &__selectArrow {
        transform: rotate(90deg);
        transition: transform 0.3s ease-in-out;

        min-width: vw(24px, 320px);
        min-height: vw(24px, 320px);
        max-width: vw(24px, 320px);
        max-height: vw(24px, 320px);
        
        @media screen and (min-width: 526px) {
            min-width: vw(24px, 744px);
            min-height: vw(24px, 744px);
            max-width: vw(24px, 744px);
            max-height: vw(24px, 744px);
        }

        @media screen and (min-width: 921px) {
            min-width: vw(24px);
            min-height: vw(24px);
            max-width: vw(24px);
            max-height: vw(24px);
        }

        @media screen and (min-width: 1440px) {
            min-width: 24px;
            min-height: 24px;
            max-width: 24px;
            max-height: 24px;
        }
    }

    &__select_active &__selectArrow {
        transform: rotate(-90deg);
    }


    &__wrapper {
        position: absolute;
        z-index: 11;
        width: 100%;
        display: none;
        
        @media screen and (min-width: 526px) {
            z-index: 1;
            top: calc(100% + #{vw(4px, 744px)});
        }

        @media screen and (min-width: 921px) {
            top: calc(100% + #{vw(4px)});
        }

        @media screen and (min-width: 1440px) {
            top: calc(100% + 4px);
        }
        
        &_active {
            display: block;
        }
    }

    &__background {
        position: fixed;
        left: 0px;
        top: 0px;
        width: 100vw;
        height: 100vh;
        transition: opacity 0.3s ease-in-out;

        opacity: 0;
        background: rgba($color: $black, $alpha: 0.7);

        @media screen and (min-width: 526px) {
            display: none;
        }

        @media screen and (min-width: 921px) {

        }

        @media screen and (min-width: 1440px) {

        }
    }
    &__select_active + div &__background {
        opacity: 1;
    }

    &__list {
        background: $white;
        @include shadow-bottom-element();
        transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
        position: fixed;
        overflow: hidden;
        
        bottom: 0px;
        width: 100vw;
        border-radius: vw(12px, 320px) vw(12px, 320px) 0px 0px;
        left: 0px;
        transform: translateY(100%);

        @media screen and (min-width: 526px) {
            width: 100%;
            position: static;
            border-radius: vw(12px, 744px);
            transform: none;
            opacity: 0;
            transform: translateY(vw(10px, 744px));
        }

        @media screen and (min-width: 921px) {
            border-radius: vw(12px);
            transform: translateY(vw(10px));
        }

        @media screen and (min-width: 1440px) {
            transform: translateY(10px);
            border-radius: 12px;
        }
    }
    &__select_active + div &__list {
        transform: translateY(0px);
        opacity: 1;
    }

    &__listLine {
        background: $gray-x;

        width: vw(64px, 320px);
        height: vw(4px, 320px);
        margin: 0px auto vw(12px, 320px);
        transform: translateY(vw(12px, 320px));
        border-radius: vw(2px, 320px);

        @media screen and (min-width: 526px) {
            display: none;
        }
    }

    &__item {
        cursor: pointer;
        display: flex;
        align-items: center;
        border-bottom: solid $gray-sx;

        height: vw(78px, 320px);
        gap: vw(12px, 320px);
        padding: vw(16px, 320px);
        border-bottom-width: vw(1px, 320px);

        @media screen and (min-width: 526px) {
            height: vw(78px, 744px);
            gap: vw(12px, 744px);
            padding: vw(16px, 744px);
            border-bottom-width: vw(1px, 744px);
        }

        @media screen and (min-width: 921px) {
            height: vw(78px);
            gap: vw(12px);
            padding: vw(16px);
            border-bottom-width: vw(1px);
        }

        @media screen and (min-width: 1440px) {
            height: 78px;
            gap: 12px;
            padding: 16px;
            border-bottom-width: 1px;
        }
        
        &:last-of-type {
            border: none;
        }
    }

    &__itemInfo {
        display: flex;
        flex-direction: column;

        gap: vw(4px, 320px);

        @media screen and (min-width: 526px) {
            gap: vw(4px, 744px);
        }

        @media screen and (min-width: 921px) {
            gap: vw(4px);
        }

        @media screen and (min-width: 1440px) {
            gap: 4px;
        }
    }

    &__itemName {
        @include text-regular();
    }

    &__itemDuration {
        @include sub-text();
    }

    &__itemImg {
        min-width: vw(44px, 320px);
        min-height: vw(44px, 320px);
        max-width: vw(44px, 320px);
        max-height: vw(44px, 320px);

        @media screen and (min-width: 526px) {
            min-width: vw(44px, 744px);
            min-height: vw(44px, 744px);
            max-width: vw(44px, 744px);
            max-height: vw(44px, 744px);
        }

        @media screen and (min-width: 921px) {
            min-width: vw(44px);
            min-height: vw(44px);
            max-width: vw(44px);
            max-height: vw(44px);
        }

        @media screen and (min-width: 1440px) {
            min-width: 44px;
            min-height: 44px;
            max-width: 44px;
            max-height: 44px;
        }
    }
    
    &__cancel {
        width: vw(288px, 320px);
        margin: vw(12px, 320px) auto;

        @media screen and (min-width: 526px) {
            display: none;
        }
    }
}