@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.container {
   position: relative;

   display: grid;
   grid-template-columns: vw(340px, 1920px) 1fr;
   grid-template-rows: auto;

   @media screen and (min-width: 1600px) {
      grid-template-columns: 340px 1fr;
   }
}

.aside {
   position: fixed;
   top: vw(100px, 1920px);
   left: vw(340px, 1920px);
   width: vw(340px, 1920px);
   height: 100%;
   z-index: 2;
   @media screen and (min-width: 1600px) {
      top: 100px;
      left: 340px;
      width: 340px;
   }
}

.outlet {
   grid-area: 1 / 2 / 2 / 3;
   padding: vw(24px, 1920px) vw(64px, 1920px);

   @media screen and (min-width: 1600px) {
      padding: 24px 64px;
   }
}
