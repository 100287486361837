@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.item {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   align-items: flex-start;
   justify-content: flex-start;

   border-bottom: vw(2px, 1920px) solid $light;
   width: 100%;

   &:last-child {
      border: none;
   }

   @media screen and (min-width: 1920px) {
      border-bottom: 2px solid $light;
   }

   &__header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;

      cursor: pointer;
      width: 100%;
      height: vw(120px, 1920px);
      background: $white;
      border: none;

      @media screen and (min-width: 1920px) {
         height: 120px;
      }
   }

   &__title {
      font-weight: 600;
      letter-spacing: -0.01em;
      text-align: left;

      color: $main-dark;

      font-size: vw(22px, 1920px);
      line-height: vw(26px, 1920px);

      @media screen and (min-width: 1920px) {
         font-size: 22px;
         line-height: 26px;
      }
   }

   &__button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      background: $white;

      border: vw(1px, 1920px) solid $light;
      border-radius: vw(20px, 1920px);
      gap: vw(10px, 1920px);
      width: vw(40px, 1920px);
      height: vw(40px, 1920px);
      transform: rotate(0deg);
      transition: transform ease-in-out 0.3s;

      @media screen and (min-width: 1920px) {
         border: 1px solid $light;
         border-radius: 20px;
         gap: 10px;
         width: 40px;
         height: 40px;
      }

      &_active {
         transform: rotate(-180deg);
      }

      &Icon {
         width: vw(14px, 1920px);
         height: vw(7px, 1920px);

         @media screen and (min-width: 1920px) {
            width: 14px;
            height: 7px;
         }
      }
   }

   &__content {
      display: none;
      width: 100%;
      height: 0px;
      opacity: 0;
      padding-bottom: 0px;

      &_active {
         display: block;
         opacity: 100;
         height: auto;
         padding-bottom: vw(20px, 1920px);

         @media screen and (min-width: 1920px) {
            padding-bottom: 20px;
         }
      }
   }
}

.wait {
   width: 100%;
   height: vw(120px, 1920px);
   background: $light;
   border-radius: vw(8px, 1920px);

   @media screen and (min-width: 1600px) {
      height: 120px;
      border-radius: 8px;
   }

   
}
