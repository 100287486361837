@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.link {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 80%;
   background-color: $ultra-light;
   font-weight: 600;
   letter-spacing: 0em;
   text-align: left;

   font-size: vw(16px, 1920px);
   line-height: vw(20px, 1920px);
   border-radius: vw(10px, 1920px);

   @media screen and (min-width: 1600px) {
      font-size: 16px;
      line-height: 20px;
      border-radius: 10px;
   }
}

.buttons {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: space-between;
}

.attantion {
   font-weight: 400;
   letter-spacing: -0.01em;
   text-align: left;

   font-size: vw(18px, 1920px);
   line-height: vw(22px, 1920px);

   @media screen and (min-width: 1600px) {
      font-size: 18px;
      line-height: 22px;
   }

   &_small {
      font-weight: 500;
      letter-spacing: -0.01em;
      text-align: left;

      font-size: vw(14px, 1920px);
      line-height: vw(17px, 1920px);

      @media screen and (min-width: 1600px) {
         font-size: 14px;
         line-height: 17px;
      }
   }
}

.wrapper {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   justify-content: space-between;
   height: 100%;

   &__accept {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      gap: vw(24px, 1920px);
      @media screen and (min-width: 1600px) {
         gap: 24px;
      }
   }
}
