@import "../../styles/helpers.scss";
@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.approved {
   padding: vw(32px, 320px) vw(16px, 320px);

   @media screen and (min-width: 526px) {
      padding: vw(32px, 744px) vw(24px, 744px);
   }
   @media screen and (min-width: 921px) {
      padding: vw(32px) 0px;
   }
   @media screen and (min-width: 1440px) {
      padding: 32px 0px;
   }

   &__subscription,
   &__heading {
      display: flex;
      flex-direction: column;
   }

   &__subscription {
      gap: vw(32px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(32px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(32px);
      }
      @media screen and (min-width: 1440px) {
         gap: 32px;
      }
   }

   &__heading {
      gap: vw(4px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(4px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(4px);
      }
      @media screen and (min-width: 1440px) {
         gap: 4px;
      }
   }

   &__title,
   &__suptitle {
      color: $black;
   }

   &__suptitle {
      @include sub-text();
      color: $gray;
   }

   &__title {
      @include head-3();
   }

   &__information {
      display: block;
      margin-top: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         margin-top: vw(24px, 744px);
      }
      @media screen and (min-width: 921px) {
         margin-top: vw(24px);
      }
      @media screen and (min-width: 1440px) {
         margin-top: 24px;
      }
   }

   &__confirm {
      width: 100%;
      max-width: none;

      @media screen and (min-width: 526px) {
         max-width: vw(690px, 744px);
      }
      @media screen and (min-width: 921px) {
         max-width: vw(690px);
      }
      @media screen and (min-width: 1440px) {
         max-width: 690px;
      }
   }
}

.tranaction {
   display: flex;
   flex-direction: column;

   gap: vw(32px, 320px);

   @media screen and (min-width: 526px) {
      gap: vw(32px, 744px);
   }
   @media screen and (min-width: 921px) {
      gap: vw(32px);
   }
   @media screen and (min-width: 1440px) {
      gap: 32px;
   }

   &__heading {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      gap: vw(8px, 320px);

      @media screen and (min-width: 526px) {
         flex-direction: row;
         align-items: flex-start;
         gap: vw(8px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(8px);
      }
      @media screen and (min-width: 1440px) {
         gap: 8px;
      }
   }
   &__headingWrapper {
      display: flex;
      flex-direction: column;
      gap: vw(4px, 320px);
      max-width: none;

      @media screen and (min-width: 526px) {
         gap: vw(4px, 744px);
         max-width: vw(512px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(4px);
         max-width: vw(512px);
      }
      @media screen and (min-width: 1440px) {
         gap: 4px;
         max-width: 512px;
      }
   }
   &__title,
   &__subtitle {
      color: $black;
   }
   &__title {
      @include head-3();
   }
   &__subtitle {
      @include text-regular();
   }
   &__icon {
      width: vw(119.5px, 320px);
      height: vw(60px, 320px);

      @media screen and (min-width: 526px) {
         width: vw(119.5px, 744px);
         height: vw(60px, 744px);
      }
      @media screen and (min-width: 921px) {
         width: vw(119.5px);
         height: vw(60px);
      }
      @media screen and (min-width: 1440px) {
         width: 119.5px;
         height: 60px;
      }
   }
   &__form {
      display: flex;
      flex-direction: column;
      gap: vw(24px, 320px);
      max-width: none;

      @media screen and (min-width: 526px) {
         gap: vw(24px, 744px);
         max-width: vw(870px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(24px);
         max-width: vw(870px);
      }
      @media screen and (min-width: 1440px) {
         gap: 24px;
         max-width: 870px;
      }
   }
}

.container {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: flex-start;

   gap: vw(24px, 320px);

   &__button {
      width: min-content;
   }

   @media screen and (min-width: 526px) {
      gap: vw(32px, 744px);
   }

   @media screen and (min-width: 921px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      gap: vw(20px);
   }

   @media screen and (min-width: 1440px) {
      gap: 20px;
   }
}
