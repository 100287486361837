@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.list {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   align-items: flex-start;
   justify-content: flex-start;

   gap: vw(14px, 1920px);

   @media screen and (min-width: 1600px) {
      gap: 14px;
   }

   &__element {
      width: 100%;
   }

   &__item {
      &_active {
         border-left: vw(4px, 1920px) solid $main;
         border-radius: 0px vw(10px, 1920px) vw(10px, 1920px) 0px;

         a > span {
            color: $main;
         }

         a > svg > path {
            fill: $main;
         }

         @media screen and (min-width: 1600px) {
            border-left: 4px solid $main;
            border-radius: 0px 10px 10px 0px;
         }
      }

      &_disabled {
         background-color: $light;
         cursor: not-allowed;
      }
   }
}
