@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.banner {
   &__content,
   &__heading {
      display: flex;
      flex-direction: column;
   }

   &__content {
      z-index: 2;
      justify-content: space-between;
      max-width: vw(190px, 320px);
      min-height: 190px;
      gap: vw(12px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(20px, 744px);
         max-width: vw(386px, 744px);
         min-height: vw(178px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(20px);
         max-width: vw(386px);
         min-height: vw(178px);
      }
      @media screen and (min-width: 1440px) {
         gap: 20px;
         max-width: 386px;
         min-height: 178px;
      }
   }

   &__heading {
      gap: vw(8px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(8px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(8px);
      }
      @media screen and (min-width: 1440px) {
         gap: 8px;
      }
   }

   &__title,
   &__text {
      color: $black;
   }

   &__title {
      @include head-3();
   }

   &__text {
      @include text-regular();
   }

   &__button {
      width: fit-content;
      color: $white !important;
   }

   &__grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(4, 1fr);
      column-gap: vw(32px, 320px);
      row-gap: vw(16px, 320px);
      max-width: vw(165px, 320px);

      @media screen and (min-width: 526px) {
         grid-template-columns: repeat(2, auto);
         grid-template-rows: repeat(2, auto);
         column-gap: vw(32px, 744px);
         row-gap: vw(32px, 744px);
         max-width: vw(370px, 744px);
      }
      @media screen and (min-width: 921px) {
         column-gap: vw(32px);
         row-gap: vw(32px);
         max-width: vw(370px);
      }
      @media screen and (min-width: 1440px) {
         column-gap: 32px;
         row-gap: 32px;
         max-width: 370px;
      }

      &__item {
         display: flex;
         flex-direction: column;
         gap: vw(10px, 320px);

         @media screen and (min-width: 526px) {
            gap: vw(10px, 744px);
         }
         @media screen and (min-width: 921px) {
            gap: vw(10px);
         }
         @media screen and (min-width: 1440px) {
            gap: 10px;
         }
      }

      &__title {
         @include text-medium();
         line-height: 100%;
         color: $black;
      }

      &__subtitle {
         @include text-regular();
         line-height: 100%;
         color: $black;
      }
   }
}
