@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.card {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: repeat(2, auto);
   background: $white;

   row-gap: vw(8px, 1920px);
   border: vw(1px, 1920px) solid $light;
   border-radius: vw(30px, 1920px);
   padding: vw(20px, 1920px);
   min-width: vw(301px, 1920px);

   @media screen and (min-width: 1600px) {
      row-gap: 8px;
      border: 1px solid $light;
      border-radius: 30px;
      padding: 20px;
      min-width: 301px;
   }

   &__wrapper {
      border: vw(1px, 1920px) solid $light;
      border-radius: vw(30px, 1920px);
      padding: vw(32px, 1920px);

      @media screen and (min-width: 1600px) {
         border: 1px solid $light;
         border-radius: 30px;
         padding: 32px;
      }
   }

   &_document {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
      background: $ultra-light;
      align-items: start;
      justify-items: start;
      align-content: stretch;

      max-width: vw(150px, 1920px);
      height: 100%;

      row-gap: vw(12px, 1920px);
      border: none;
      border-radius: vw(20px, 1920px);

      svg {
         width: vw(48px, 1920px);
         height: vw(48px, 1920px);

         @media screen and (min-width: 1600px) {
            width: 48px;
            height: 48px;
         }
      }

      @media screen and (min-width: 1600px) {
         max-width: 150px;
         row-gap: 12px;
         border-radius: 20px;
      }
   }

   &__title {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;

      color: $secondary-dark;

      font-weight: 500;
      letter-spacing: -0.01em;
      text-align: left;

      font-size: vw(20px, 1920px);
      line-height: vw(24px, 1920px);

      &_doc {
         font-weight: 400;
         letter-spacing: -0.01em;
         text-align: left;

         color: $main-dark;

         font-size: vw(14px, 1920px);
         line-height: vw(17px, 1920px);

         @media screen and (min-width: 1600px) {
            font-size: 14px;
            line-height: 17px;
         }
      }

      svg {
         width: vw(24px, 1920px);
         height: vw(24px, 1920px);

         @media screen and (min-width: 1600px) {
            width: 24px;
            height: 24px;
         }
      }

      @media screen and (min-width: 1600px) {
         font-size: 20px;
         line-height: 24px;
      }
   }

   &__text {
      font-weight: 600;
      letter-spacing: -0.01em;
      text-align: left;

      font-size: vw(32px, 1920px);
      line-height: vw(38px, 1920px);

      @media screen and (min-width: 1600px) {
         font-size: 32px;
         line-height: 38px;
      }
   }
}

.wait {
   background: $light;
   min-width: vw(301px, 1920px);
   height: vw(110px, 1920px);

   border-radius: vw(30px, 1920px);

   @media screen and (min-width: 1600px) {
      height: 110px;
      min-width: 301px;
      border-radius: 30px;
   }

   
}
