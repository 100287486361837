@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.footer,
.footer__aboutCompany,
.footer__cel,
.footer__info,
.footer__links,
.footer__contacts {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   align-items: stretch;
   justify-content: flex-start;
}

.footer {
   width: auto;
   padding: vw(84px, 320px) vw(16px, 320px);
   gap: vw(32px, 320px);

   @media screen and (min-width: 526px) {
      padding: vw(96px, 744px) vw(24px, 744px);
      gap: vw(32px, 744px);
   }

   @media screen and (min-width: 921px) {
      padding: vw(32px) 0px;
      margin: 0px auto;
      width: vw(1200px);
      gap: vw(60px);
   }

   @media screen and (min-width: 1440px) {
      padding: 32px 0px;
      width: 1200px;
   }

   p,
   a,
   address {
      @include sub-text();
      color: $black;
   }

   p,
   address {
      color: $black;
   }

   a {
      transition: color ease-in-out 0.3s;
      color: $black;

      &:focus,
      &:hover,
      &:active {
         color: $sec-dark;
      }
   }

   &__aboutCompany,
   &__cel,
   &__contacts {
      gap: vw(20px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(20px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(20px);
      }

      @media screen and (min-width: 1440px) {
         gap: 20px;
      }
   }

   &__cel > h3 {
      @include text-medium();
   }

   &__grid {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(7, 1fr);
      column-gap: 0px;
      row-gap: vw(32px, 320px);

      @media screen and (min-width: 526px) {
         grid-template-columns: repeat(2, 1fr);
         grid-template-rows: repeat(4, 1fr);
         column-gap: vw(32px, 744px);
         row-gap: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         grid-template-columns: repeat(4, 1fr);
         grid-template-rows: repeat(2, 1fr);
         column-gap: vw(32px);
         row-gap: vw(32px);
      }

      @media screen and (min-width: 1440px) {
         column-gap: 32px;
         row-gap: 32px;
      }
   }

   &__info {
      gap: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         flex-direction: row;
         flex-wrap: nowrap;
         justify-content: space-between;
         align-items: flex-start;
         gap: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         gap: 24px;
      }
   }

   &__links {
      min-width: 100%;
      max-width: 100%;
      padding: vw(16px, 320px);
      gap: vw(12px, 320px);
      border-radius: vw(24px, 320px);
      height: max-content;
      background: $gray-sx;

      @media screen and (min-width: 526px) {
         min-width: vw(320px, 744px);
         max-width: vw(320px, 744px);
         padding: vw(24px, 744px);
         gap: vw(12px, 744px);
         border-radius: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         min-width: vw(320px);
         max-width: vw(320px);
         padding: vw(16px);
         gap: vw(12px);
         border-radius: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         min-width: 320px;
         max-width: 320px;
         padding: 16px;
         gap: 12px;
         border-radius: 24px;
      }

      & > a {
         @include text-medium();
      }
   }

   &__pictures {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      gap: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         flex-direction: row;
         justify-content: space-between;
      }
   }

   &__logo {
      width: vw(257.68px, 320px);
      height: vw(32px, 320px);

      @media screen and (min-width: 526px) {
         width: vw(257.68px, 744px);
         height: vw(32px, 744px);
      }
      @media screen and (min-width: 921px) {
         width: vw(257.68px);
         height: vw(32px);
      }
      @media screen and (min-width: 1440px) {
         width: 257.68px;
         height: 32px;
      }
   }

   &__age {
      width: vw(227px, 320px);
      height: vw(53.5px, 320px);

      @media screen and (min-width: 526px) {
         width: vw(227px, 744px);
         height: vw(53.5px, 744px);
      }
      @media screen and (min-width: 921px) {
         width: vw(227px);
         height: vw(53.5px);
      }
      @media screen and (min-width: 1440px) {
         width: 227px;
         height: 53.5px;
      }
   }

   &__pays {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;

      gap: vw(8px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(8px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(8px);
      }

      @media screen and (min-width: 1440px) {
         gap: 8px;
      }
   }

   &__pay {
      width: vw(64px, 320px);
      height: vw(32px, 320px);

      @media screen and (min-width: 526px) {
         width: vw(64px, 744px);
         height: vw(32px, 744px);
      }
      @media screen and (min-width: 921px) {
         width: vw(64px);
         height: vw(32px);
      }
      @media screen and (min-width: 1440px) {
         width: 64px;
         height: 32px;
      }
   }
}
