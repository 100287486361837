@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.tooltip {
    position: relative;
    display: flex;
    flex-direction: column;

    &__wrapper {
        position: absolute;
        opacity: 0;
        transform: translateY(-1000000px);
        top: 100%;
        border: solid $light;
        background: $white;


        max-width: vw(500px, 1920px);
        padding: vw(20px, 1920px);
        border-radius: vw(8px, 1920px);
        border-width: vw(1px, 1920px);
        
        @media screen and (min-width: 1600px) {
            max-width: 500px;
            padding: 20px;
            border-radius: 8px;
            border-width: 1px;
        }
        
        animation: tooltipHide 0.3s ease-in-out 0s;

        &::before {
            position: absolute;
            top: -20px;
            left: 0px;
            width: 100%;
            content: ' ';
            display: block;
            
            height: 20px;
        }

        @keyframes tooltipHide {
            0% {
                transform: translateY(20px);
                opacity: 1;
            }

            99% {
                transform: translateY(0px);
                opacity: 0;
            }

            100% {
                transform: translateY(-1000000px);
                opacity: 0;
            }
        }
    }

    &:hover > &__wrapper {
        opacity: 1;

        transform: translateY(vw(20px, 1920px));

        @media screen and (min-width: 1600px) {
            transform: translateY(20px);
        }

        animation: tooltipShow 0.3s ease-in-out 0s;

        @keyframes tooltipShow {
            0% {
                transform: translateY(-1000000px);
                opacity: 0;
            }

            1% {
                transform: translateY(0px);
                opacity: 0;
            }

            100% {
                transform: translateY(20px);
                opacity: 1;
            }
        }
    }
    
    &_hide > &__wrapper {
        display: none;
    }
}

.tooltipInput {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 12px;

    & > svg {
        min-width: 24px;
        max-width: 24px;
        min-height: 24px;
        max-height: 24px;

        & > path {
            fill: $secondary-dark;
        }
    }

    &__input {
        width: 100%;
        border: none;
        background: none;
        border-bottom: $light solid;
        outline: none;

        min-width: 200px;
        border-bottom-width: 1px;
        padding: 4px;
        font-size: 16px;
        line-height: 16px;

        transition: border 0.3s ease-in-out, ;

        &:focus {
            border-bottom-color: $main;

            border-bottom-width: 2px;
        }
    }

    &__button {
        border: none;
        background: none;
        cursor: pointer;

        & > svg {
            min-width: 24px;
            max-width: 24px;
            min-height: 24px;
            max-height: 24px;
        }
    }
}