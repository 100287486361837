@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.servicesTwoColumns,
.servicesTwoColumns__column,
.list,
.list__item {
   display: flex;
   flex-direction: column;
}

.servicesTwoColumns {
   justify-content: space-between;
   align-items: flex-start;
   margin: 0px auto;

   gap: vw(24px, 320px);
   padding: vw(32px, 320px) vw(16px, 320px);

   @media screen and (min-width: 526px) {
      flex-direction: row;
      flex-wrap: nowrap;
      gap: vw(24px, 744px);
      padding: vw(32px, 744px) vw(24px, 744px);
   }

   @media screen and (min-width: 921px) {
      padding: vw(64px) 0px;
      width: vw(1200px);
      gap: vw(24px);
   }

   @media screen and (min-width: 1440px) {
      gap: 24px;
      padding: 64px 0px;
      width: 1200px;
   }

   &__column {
      width: 100%;
   }

   &__title {
      @include head-3;
   }
}

.list {
   &__item {
      position: relative;

      flex-direction: row;
      gap: vw(6px, 320px);
      padding: vw(16px, 320px) vw(8px, 320px);
      width: 100%;

      @media screen and (min-width: 526px) {
         gap: vw(10px, 744px);
         padding: vw(16px, 744px) vw(8px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(10px);
         padding: vw(16px) vw(8px);
         max-width: vw(588px);
      }

      @media screen and (min-width: 1440px) {
         gap: 10px;
         padding: 16px 8px;
         max-width: 588px;
      }
   }

   &__icon {
      min-width: vw(24px, 320px);
      max-width: vw(24px, 320px);
      min-height: vw(24px, 320px);
      max-height: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         min-width: vw(32px, 744px);
         max-width: vw(32px, 744px);
         min-height: vw(32px, 744px);
         max-height: vw(32px, 744px);
      }

      @media screen and (min-width: 921px) {
         min-width: vw(32px);
         max-width: vw(32px);
         min-height: vw(32px);
         max-height: vw(32px);
      }

      @media screen and (min-width: 1440px) {
         min-width: 32px;
         max-width: 32px;
         min-height: 32px;
         max-height: 32px;
      }
   }

   &__text {
      @include text-regular();
      color: $black;
   }

   &__tools {
      transform: translateY(vw(-10px, 744px));

      @media screen and (min-width: 1600px) {
         transform: translateY(-10px);
      }
   }
}
