@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.list__item {
   width: 100%;
   height: vw(48px, 1920px);
   border-radius: vw(10px, 1920px);
   border-left: vw(4px, 1920px) solid transparent;

   background-color: $ultra-light;

   cursor: pointer;

   a {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: flex-start;

      gap: vw(10px, 1920px);
      padding: 0px vw(16px, 1920px);

      width: 100%;
      height: 100%;

      @media screen and (min-width: 1600px) {
         gap: 10px;
         padding: 0px 16px;
      }
   }

   @media screen and (min-width: 1600px) {
      border-radius: 10px;
      border-left: 4px solid transparent;
      height: 48px;
   }
}

.list__icon {
   width: vw(24px, 1902px);
   height: vw(24px, 1902px);

   @media screen and (min-width: 1600px) {
      width: 24px;
      height: 24px;
   }
}

.list__text {
   font-weight: 500;
   letter-spacing: 0em;
   text-align: left;

   font-size: vw(20px, 1920px);
   line-height: vw(24px, 1920px);

   @media screen and (min-width: 1600px) {
      font-size: 20px;
      line-height: 24px;
   }
}
