@import "../../../styles/helpers.scss";
@import "../../../styles/colors.scss";

.head {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: auto;
   align-items: center;
   justify-items: stretch;

   row-gap: vw(32px, 320px);

   button {
      width: 100%;

      @media screen and (min-width: 526px) {
         width: vw(200px, 744px);
      }

      @media screen and (min-width: 921px) {
         width: vw(200px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         width: 200px;
      }
   }

   @media screen and (min-width: 526px) {
      grid-template-columns: 1fr auto;
      row-gap: 0px;
   }

   &__container {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, auto);
      row-gap: vw(12px, 320px);

      @media screen and (min-width: 526px) {
         row-gap: vw(12px, 744px);
      }

      @media screen and (min-width: 921px) {
         row-gap: vw(12px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         row-gap: 12px;
      }
   }

   &__subtitle {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      font-weight: 400;
      letter-spacing: -0.01em;
      text-align: left;

      font-size: vw(14px, 320px);
      line-height: vw(17px, 320px);
      gap: vw(6px, 320px);

      @media screen and (min-width: 526px) {
         font-size: vw(20px, 744px);
         line-height: vw(24px, 744px);
         gap: vw(6px, 744px);
      }

      @media screen and (min-width: 921px) {
         font-size: vw(20px, 1920px);
         line-height: vw(24px, 1920px);
         gap: vw(6px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         font-size: 20px;
         line-height: 24px;
         gap: 6px;
      }
   }

   &__icon {
      width: vw(24px, 320px);
      height: vw(24px, 320px);
      cursor: pointer;

      @media screen and (min-width: 526px) {
         width: vw(32px, 744px);
         height: vw(32px, 744px);
      }

      @media screen and (min-width: 921px) {
         width: vw(32px, 1920px);
         height: vw(32px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         width: 32px;
         height: 32px;
      }
   }

   &__title {
      font-weight: 600;
      letter-spacing: -0.01em;
      text-align: left;

      font-size: vw(20px, 320px);
      line-height: vw(20px, 320px);

      @media screen and (min-width: 526px) {
         font-size: vw(32px, 744px);
         line-height: vw(32px, 744px);
      }

      @media screen and (min-width: 921px) {
         font-size: vw(32px, 1920px);
         line-height: vw(32px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         font-size: 32px;
         line-height: 32px;
      }
   }
}
