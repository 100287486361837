@import "../../../styles/helpers.scss";

.continer__page__profile {
   display: block;
   margin: 0px auto;
   width: 100%;
   max-width: none;

   @media screen and (min-width: 921px) {
      max-width: vw(1200px);
   }

   @media screen and (min-width: 1440px) {
      max-width: 1200px;
   }
}
