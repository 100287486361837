@import "../../../styles/helpers.scss";
@import "../../../styles/colors.scss";

.banner {
   position: relative;

   min-height: vw(222px, 320px);
   border-radius: vw(12px, 320px);
   background-color: $gray-sx;
   padding: vw(20px, 320px) vw(12px, 320px);

   @media screen and (min-width: 526px) {
      min-height: vw(174px, 744px);
      border-radius: vw(12px, 744px);
      padding: vw(28px, 744px) vw(24px, 744px);
   }
   @media screen and (min-width: 921px) {
      min-height: vw(174px);
      border-radius: vw(12px);
      padding: vw(28px) vw(24px);
   }
   @media screen and (min-width: 1440px) {
      min-height: 174px;
      border-radius: 12px;
      padding: 28px 24px;
   }

   &__container {
      display: flex;
      flex-direction: column;
      gap: vw(20px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(24px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(24px);
      }
      @media screen and (min-width: 1440px) {
         gap: 24px;
      }
   }

   &__body {
      display: block;
      z-index: 3;
   }

   &__img {
      z-index: 0;

      position: absolute;
      top: 0px;
      right: 0px;
      height: 100%;

      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='211' height='253' viewBox='0 0 211 253' fill='none'%3E%3Cpath d='M208.098 76.053c-3.597-15.575-9.935-28.955-19.016-40.14-9.081-11.169-20.823-19.948-35.226-26.337C139.453 3.203 123.356 0 105.549 0 87.743 0 70.484 3.186 53.807 9.576c-12.371 4.73-23.694 10.795-34 18.16l26.645 32.677c15.049-10.632 32.145-16.03 51.339-16.03 22.952 0 40.355 7.674 52.178 23.037 9.532 12.388 13.871 27.622 12.952 45.521H51.742C48 134.336 64.34 153.959 85.888 153.959h67.823C116.985 250.383 0 236.499 0 236.499 17.226 247.505 37.662 253 61.29 253c23.63 0 45.985-5.511 67.066-16.501 21.081-11.007 38.71-26.191 52.903-45.554 14.194-19.363 23.388-40.953 27.549-64.77 3.145-17.835 2.903-34.547-.71-50.122Z' fill='%238FB966'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-size: cover;
      background-position: right vw(-85px, 320px) top vw(-12px, 320px);
      width: vw(221px, 320px);
      border-radius: vw(12px, 320px);

      @media screen and (min-width: 526px) {
         background-position: right vw(11px, 744px) top vw(-26px, 744px);
         width: vw(221px, 744px);
         border-radius: 0px;
      }
      @media screen and (min-width: 921px) {
         background-position: right vw(11px) top vw(-26px);
         width: vw(221px);
      }
      @media screen and (min-width: 1440px) {
         background-position: right 11px top -26px;
         width: 221px;
      }
   }
}
