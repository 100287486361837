@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.section {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: auto;
   justify-items: start;
   align-items: center;

   row-gap: vw(24px, 320px);
   width: 100%;
   padding: 0px vw(16px, 320px);

   @media screen and (min-width: 526px) {
      row-gap: vw(32px, 744px);
      padding: vw(32px, 744px) vw(24px, 744px);
   }

   @media screen and (min-width: 921px) {
      row-gap: vw(32px);
      padding: 0px;
      margin: 0px auto;
      width: vw(1200px);
   }

   @media screen and (min-width: 1440px) {
      row-gap: 32px;
      width: 1200px;
      padding: 0px;
   }
}

.title,
.subtitle,
.text,
.list {
   color: $black;
   width: 100%;
   max-width: none;

   @media screen and (min-width: 921px) {
      max-width: vw(780px);
   }
   @media screen and (min-width: 1440px) {
      width: 780px;
   }
}

.title {
   @include head-2();
   margin: vw(32px, 320px) 0px vw(24px, 320px) 0px;

   @media screen and (min-width: 526px) {
      margin: vw(32px, 744px) 0px vw(24px, 744px) 0px;
   }
   @media screen and (min-width: 921px) {
      margin: vw(64px) 0px vw(24px) 0px;
   }
   @media screen and (min-width: 1440px) {
      margin: 64px 0px 24px 0px;
   }
}

.subtitle {
   @include head-3();

   margin: vw(24px, 320px) 0px;

   @media screen and (min-width: 526px) {
      margin: vw(24px, 744px) 0px;
   }
   @media screen and (min-width: 921px) {
      margin: vw(24px) 0px;
   }
   @media screen and (min-width: 1440px) {
      margin: 24px 0px;
   }
}

.text {
   @include text-regular();

   a,
   & > * > a {
      text-decoration: none;
      transition: color ease-in-out 0.3s;
      color: $sec;

      &:hover,
      &:focus,
      &:active {
         color: $sec-dark;
      }
   }
}

.list {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-items: stretch;

   gap: vw(24px, 320px);

   @media screen and (min-width: 526px) {
      gap: vw(24px, 744px);
   }

   @media screen and (min-width: 921px) {
      gap: vw(24px, 1920px);
   }

   @media screen and (min-width: 1600px) {
      gap: 24px;
   }

   &_item {
      list-style: none;
      @include text-regular();
      color: $black;

      a,
      & > * > a {
         text-decoration: none;
         transition: color ease-in-out 0.3s;
         color: $sec;

         &:hover,
         &:focus,
         &:active {
            color: $sec-dark;
         }
      }
   }
}
