@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.list {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: flex-start;
   gap: vw(12px, 1920px);
   padding: vw(33px, 1920px) 0px;

   font-weight: 500;
   letter-spacing: 0em;
   text-align: center;

   font-size: vw(24px, 1920px);
   line-height: vw(29px, 1920px);

   @media screen and (min-width: 1600px) {
      gap: 12px;
      font-size: 24px;
      line-height: 29px;
      padding: 33px 0px;
   }

   &__button {
      user-select: none;

      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      background-color: $ultra-light;
      cursor: pointer;

      min-width: vw(49px, 1920px);
      height: vw(49px, 1920px);
      border: vw(1px, 1920px) solid $light;
      border-radius: vw(10px, 1920px);
      padding: vw(8px, 1920px);

      &:hover {
         background-color: $light;
      }

      &_active {
         background-color: $main;
         color: $white;
         border: none;

         &:hover {
            background-color: $main;
            color: $white;
         }
      }

      &_disabled {
         cursor: not-allowed;
         background-color: $light;
      }

      @media screen and (min-width: 1600px) {
         padding: 8px;
         min-width: 49px;
         height: 49px;
         border: 1px solid $light;
         border-radius: 10px;
      }
   }

   &__icon {
      width: vw(33px, 1920px);
      height: vw(33px, 1920px);

      @media screen and (min-width: 1600px) {
         width: 33px;
         height: 33px;
      }
   }
}
