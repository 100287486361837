@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.formBuilder {
    
    &__block {

    }

    &__blockName {
        font-weight: 600;

        font-size: vw(32px, 1920px);
        line-height: vw(32px, 1920px);
        margin-bottom: vw(48px, 1920px);
        margin-top: vw(24px, 1920px);
        
        @media screen and (min-width: 1600px) {
            font-size: 32px;
            line-height: 32px;
            margin-bottom: 48px;
            margin-top: 24px;
        }
    }

    &__blockFields {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__input {
        width: 49%;

        margin-bottom: vw(24px, 1920px);

        @media screen and (min-width: 1600px) {
            margin-bottom: 24px;
        }

        &_long {
            width: 100%;
        }
    }
}