@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.card {
   display: grid;
   grid-template-rows: auto;
   justify-items: start;
   align-items: center;

   grid-template-columns: auto 1fr;
   column-gap: vw(10px, 1920px);

   a {
      cursor: pointer;
   }

   a > img {
      border-radius: vw(18px, 1920px);
      width: vw(64px, 1920px);
      height: vw(64px, 1920px);

      @media screen and (min-width: 1600px) {
         border-radius: 18px;
         width: 64px;
         height: 64px;
      }
   }

   @media screen and (min-width: 1600px) {
      grid-template-columns: auto 1fr;
      column-gap: 10px;
   }

   &__block {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, auto);

      width: 100%;

      row-gap: vw(2px, 1920px);

      @media screen and (min-width: 1600px) {
         row-gap: 2px;
      }
   }

   &__title {
      font-weight: 500;
      letter-spacing: 0em;
      text-align: left;

      color: $main-dark;

      cursor: pointer;

      font-size: vw(20px, 1920px);
      line-height: vw(24px, 1920px);

      @media screen and (min-width: 1600px) {
         font-size: 20px;
         line-height: 24px;
      }
   }

   &__action {
      font-weight: 500;
      letter-spacing: 0em;
      text-align: left;

      color: $main;

      cursor: pointer;

      font-size: vw(16px, 1920px);
      line-height: vw(20px, 1920px);

      @media screen and (min-width: 1600px) {
         font-size: 16px;
         line-height: 20px;
      }
   }
}
