@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.main {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: repeat(2, auto);
   row-gap: vw(20px, 320px);
   column-gap: 0px;

   width: 100%;
   max-width: none;
   margin: 0px auto;
   margin-bottom: auto;
   padding: vw(24px, 320px) vw(16px, 320px) vw(76px, 320px);

   @media screen and (min-width: 526px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(4, auto);
      column-gap: vw(48px, 744px);
      row-gap: vw(24px, 744px);
      padding: vw(24px, 744px) vw(24px, 744px);
   }

   @media screen and (min-width: 921px) {
      grid-template-columns: vw(380px) 1fr;
      grid-template-rows: repeat(3, auto);
      column-gap: vw(48px);
      row-gap: vw(24px);
      max-width: vw(1200px);
      padding: vw(32px) 0px;
   }

   @media screen and (min-width: 1440px) {
      grid-template-columns: 380px 1fr;
      column-gap: 48px;
      row-gap: 24px;
      max-width: 1200px;
      padding: 32px 0px;
   }
}

.otherPageContainer {
   display: block;
   margin: 0px auto;
   width: 100%;
   max-width: none;
   margin-bottom: auto;

   @media screen and (max-width: 525px) {
      padding-bottom: vw(76px, 320px);
   }

   @media screen and (min-width: 921px) {
      max-width: vw(1200px);
   }

   @media screen and (min-width: 1440px) {
      max-width: 1200px;
   }
}
