@import "../../../styles/helpers.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";

.illustration_block {
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 100%;

   padding: vw(32px, 320px) vw(16px, 320px);
   margin: 0px auto;
   gap: vw(24px, 320px);

   @media screen and (min-width: 526px) {
      padding: vw(32px, 744px) vw(24px, 744px);
      gap: vw(32px, 744px);
   }

   @media screen and (min-width: 921px) {
      flex-direction: row;
      width: vw(1200px);
      padding: vw(337px) 0px vw(48px) 0px;
      gap: vw(32px);
   }

   @media screen and (min-width: 1440px) {
      width: 1200px;
      padding: 337px 0px 48px 0px;
      gap: 32px;
   }

   &__card {
      position: relative;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      max-width: none;
      background: $gray-sx;
      height: auto;
      border-radius: vw(24px, 320px);
      gap: vw(20px, 320px);
      padding: vw(16px, 320px);
      margin-top: 0px;

      @media screen and (min-width: 526px) {
         height: vw(263px, 744px);
         max-width: vw(588px, 744px);
         border-radius: vw(24px, 744px);
         gap: vw(20px, 744px);
         padding: vw(76px, 744px) vw(16px, 744px) vw(16px, 744px);
         margin-top: vw(280px, 744px);
      }
      @media screen and (min-width: 921px) {
         height: vw(263px);
         border-radius: vw(24px);
         gap: vw(20px);
         padding: vw(76px) vw(16px) vw(16px);
         margin-top: 0px;
      }
      @media screen and (min-width: 1440px) {
         height: 263px;
         border-radius: 24px;
         gap: 20px;
         padding: 76px 16px 16px;
      }
   }

   picture {
      display: none;
      @media screen and (min-width: 526px) {
         display: block;
      }
   }

   &__img {
      display: none;
      @media screen and (min-width: 526px) {
         position: absolute;
         display: block;
         top: -104%;
         left: vw(20px, 744px);
         width: vw(435px, 744px);
         height: vw(335px, 744px);
      }
      @media screen and (min-width: 921px) {
         width: vw(435px);
         height: vw(335px);
         left: vw(20px);
      }
      @media screen and (min-width: 1440px) {
         width: 435px;
         height: 335px;
         left: 20px;
      }
   }

   &__title,
   &__text {
      color: $black;
      margin: 0px;

      @media screen and (min-width: 526px) {
         margin-top: vw(-20px, 744px);
         margin-bottom: vw(20px, 744px);
      }
      @media screen and (min-width: 921px) {
         margin-top: vw(-20px);
         margin-bottom: vw(20px);
      }
      @media screen and (min-width: 1440px) {
         margin-top: -20px;
         margin-bottom: 20px;
      }
   }

   &__title {
      @include head-3();
   }

   &__text {
      @include text-regular();
   }
}
