@import "../../styles/colors.scss";
@import "../../styles/helpers.scss";

.errorPage {
   height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

   padding-top: vw(60px, 320px);

   @media screen and (min-width: 526px) {
      padding-top: vw(100px, 744px);
   }

   @media screen and (min-width: 921px) {
      padding-top: vw(100px, 1600px);
   }

   @media screen and (min-width: 1600px) {
      padding-top: 100px;
   }

   &__img {
      width: vw(280px, 320px);
      height: vw(95px, 320px);
      margin-bottom: vw(32px, 320px);

      @media screen and (min-width: 526px) {
         width: vw(414px, 744px);
         height: vw(140px, 744px);
         margin-bottom: vw(48px, 744px);
      }

      @media screen and (min-width: 921px) {
         width: vw(592px, 1920px);
         height: vw(200px, 1920px);
         margin-bottom: vw(48px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         width: 592px;
         height: 200px;
         margin-bottom: 48px;
      }
   }

   &__text {
      font-weight: 400;
      text-align: center;

      font-size: vw(18px, 320px);
      line-height: vw(24px, 320px);
      margin-bottom: vw(64px, 320px);

      @media screen and (min-width: 526px) {
         font-size: vw(36px, 744px);
         line-height: vw(43px, 744px);
         margin-bottom: vw(100px, 744px);
      }

      @media screen and (min-width: 921px) {
         font-size: vw(36px, 1920px);
         line-height: vw(43px, 1920px);
         margin-bottom: vw(100px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         font-size: 36px;
         line-height: 43px;
         margin-bottom: 100px;
      }
   }
}
