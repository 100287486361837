@import "./colors.scss";

/* Для всех элементов по умолчанию */
*,
*::after,
*::before {
   box-sizing: border-box;
   -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

* {
   /* width */
   &::-webkit-scrollbar {
      width: 16px;
   }

   /* Track */
   &::-webkit-scrollbar-track {
      background: transparent;
   }

   /* Handle */
   &::-webkit-scrollbar-thumb {
      background: $gray-x;
      border-radius: 8px;
      background-clip: padding-box;

      border: 4px solid transparent;
   }

   /* Handle on hover */
   &::-webkit-scrollbar-thumb:hover {
      background: $gray;
      border-radius: 8px;
      background-clip: padding-box;

      border: 4px solid transparent;
   }
}

/* Убираем внутренние отступы */
ul[class],
ol[class] {
   padding: 0px;
}

/* Убираем внешние отступы */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
   margin: 0px;
}

/* Удаляем стандартную стилизацию для всех ul и il, у которых есть атрибут class*/
ul[class],
ol[class] {
   list-style: none;
}

/* Наследуем шрифты для инпутов и кнопок */
input,
button,
textarea,
select {
   font: inherit;
   &:focus {
      outline: none;
   }
}

/* Убираем декорации для ссылок */
a,
a[class] {
   text-decoration: none;
   outline: none;
}

/* Настраеваем body */
body {
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   overflow-x: hidden;
   min-height: 100vh;
   scroll-behavior: smooth;
   text-rendering: optimizeSpeed;
   font-family: "Noto Sans", sans-serif;
}
