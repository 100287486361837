@import "../../../styles/helpers.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/effects.scss";

.header {
   position: sticky;
   top: 0px;
   display: flex;
   flex-direction: row;
   align-items: center;
   width: 100vw;
   background: $white;
   z-index: 10;

   justify-content: center;
   gap: 0px;
   padding: 0px vw(16px, 320px);
   height: vw(50px, 320px);

   @include shadow-bottom-low();

   @media screen and (min-width: 526px) {
      padding: 0px vw(24px, 744px);
      height: vw(65px, 744px);
   }

   @media screen and (min-width: 921px) {
      justify-content: flex-start;
      padding: 0px calc(50vw - #{vw(600px)});
      height: vw(75px);
      gap: vw(32px);
   }

   @media screen and (min-width: 1440px) {
      padding: 0px calc(50vw - 600px);
      height: 75px;
      gap: 32px;
   }
   
   &__logoImg {
      vertical-align: bottom;
      width: vw(193.3px, 320px);
      height: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         width: vw(257.7px, 744px);
         height: vw(32px, 744px);
      }

      @media screen and (min-width: 921px) {
         width: vw(257.7px);
         height: vw(32px);
      }

      @media screen and (min-width: 1440px) {
         width: 257.7px;
         height: 32px;
      }
   }

   &__nav {
      display: none;

      @media screen and (min-width: 921px) {
         width: 100%;
         display: flex;
         flex-direction: row;
         align-items: center;
         justify-content: flex-end;
      }
   }

   &__navList {

      @media screen and (min-width: 921px) {
         list-style: none;
         display: flex;
         flex-direction: row;
         align-items: center;

         gap: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         gap: 24px;
      }
   }

   &__navListItemLink {
      @include text-medium();
      color: $black;
   }

   &__button {
      display: none;
      color: $sec-dark;

      @include text-regular();

      @media screen and (min-width: 921px) {
         display: flex;
         padding: 0px vw(16px);
         gap: vw(8px);
      }

      @media screen and (min-width: 1440px) {
         padding: 0px 16px;
         gap: 8px;
      }
   }


   &__mobileMenuWrapper {
      position: fixed;
      display: flex;
      align-items: center;
      justify-content: center;
      top: 0px;
      left: 0px;
      width: 100vw;
      height: 100vh;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      z-index: 10;

      @media screen and (min-width: 921px) {
         display: none !important;
      }

      &_shown {
         opacity: 1;
      }
   }

   &__mobileMenuBackground {
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba($color: $black, $alpha: 0.7);
      z-index: 0;
      cursor: pointer;
   }

   &__mobileMenu {
      z-index: 1;
      position: fixed;
      bottom: 0px;
      width: 100vw;
      min-height: 40vh;
      max-height: 80vh;
      overflow-y: auto;
      transform: translateY(80vh);
      transition: transform 0.3s ease-in-out;
      background: $white;
      display: flex;
      flex-direction: column;

      padding: vw(12px, 320px) vw(16px, 320px);
      gap: vw(12px, 320px);
      border-radius: vw(12px, 320px) vw(12px, 320px) 0px 0px;

      @media screen and (min-width: 526px) {
         display: none;
      }

      &_shown {
         transform: translateY(0px);
      }
   }
   
   &__mobileMenuLine {
      background: $gray-x;
      width: vw(64px, 320px);
      height: vw(4px, 320px);
      border-radius: vw(2px, 320px);
      margin: 0px auto;
   }
   
   &__mobileMenuGrid {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;

      gap: vw(16px, 320px);
   }
   
   &__mobileMenuGridItem {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      background: $gray-sx;

      width: vw(136px, 320px);
      min-height: vw(112px, 320px);
      max-height: vw(112px, 320px);
      gap: vw(12px, 320px);
      padding: vw(12px, 320px);

      svg {
         min-width: vw(48px, 320px);
         min-height: vw(48px, 320px);
         max-width: vw(48px, 320px);
         max-height: vw(48px, 320px);
      }
   }
   
   &__mobileMenuGridItemText {
      @include head-5();
   }

   &__mobileMenuGridLogOut {
      width: 100%;
      background: $gray-sx;
   }
}


.mobileMenuBar {
   position: fixed;
   width: 100vw;
   left: 0px;
   bottom: 0px;
   z-index: 10;
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: space-between;
   background: $white;
   border-top: $gray-sx solid;

   height: vw(52px, 320px);
   padding: 0px vw(16px, 320px);
   border-top-width: vw(1px, 320px);

   @media screen and (min-width: 526px) {
      justify-content: center;
      height: vw(64px, 744px);
      gap: vw(8px, 744px);
      padding: 0px vw(24px, 744px);
      border-top-width: vw(1px, 744px);
   }

   @media screen and (min-width: 921px) {
      display: none;
   }

   &__item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0px;
      gap: 0px;

      width: 100%;

      height: vw(42px, 744px);
      padding: vw(4px, 320px) 0px;

      @media screen and (min-width: 526px) {
         width: vw(78px, 744px);
         height: vw(46px, 744px);
         padding: vw(4px, 744px) 0px;
      }

      &_hideOnMobile {
         display: none;

         @media screen and (min-width: 526px) {
            display: flex;
         }
      }

      &_hideOnTablet {
         

         @media screen and (min-width: 526px) {
            display: none;
         }
      }
   }

   &__itemIcon {
      min-width: vw(24px, 320px);
      max-width: vw(24px, 320px);
      min-height: vw(24px, 320px);
      max-height: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         min-width: vw(28px, 744px);
         max-width: vw(28px, 744px);
         min-height: vw(28px, 744px);
         max-height: vw(28px, 744px);
      }
   }

   &__itemText {
      color: $gray;
      line-height: 100%;
      
      font-size: vw(10px, 320px);

      @media screen and (min-width: 526px) {
         font-size: vw(10px, 744px);
      }
   }
}