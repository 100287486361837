@import "../../../../styles/colors.scss";
@import "../../../../styles/helpers.scss";
@import "../../../../styles/mixins.scss";

.servicesTab {


    &__head {
        @include head-3();
        
        margin-bottom: vw(8px, 320px);

        @media screen and (min-width: 526px) {
            margin-bottom: vw(8px, 744px);
        }

        @media screen and (min-width: 921px) {
            margin-bottom: vw(8px);
        }

        @media screen and (min-width: 1440px) {
            margin-bottom: 8px;
        }
    }

    &__list {
        display: flex;
        flex-direction: column;
        
        gap: vw(8px, 320px);
        margin-bottom: vw(24px, 320px);
        
        @media screen and (min-width: 526px) {
            gap: vw(8px, 744px);
            margin-bottom: vw(24px, 744px);
        }

        @media screen and (min-width: 921px) {
            gap: vw(8px);
            margin-bottom: vw(24px);
        }

        @media screen and (min-width: 1440px) {
            gap: 8px;
            margin-bottom: 24px;
        }
    }

    &__service {
        display: flex;
        flex-direction: column;
        border: solid $light;
        
        border-width: vw(1px, 320px);
        border-radius: vw(12px, 320px);
        padding: vw(12px, 320px);
        gap: vw(16px, 320px);

        @media screen and (min-width: 526px) {
            border-width: vw(1px, 744px);
            border-radius: vw(12px, 744px);
            padding: vw(12px, 744px);
            gap: vw(16px, 744px);
        }

        @media screen and (min-width: 921px) {
            border-width: vw(1px);
            border-radius: vw(12px);
            padding: vw(12px);
            gap: vw(16px);
        }

        @media screen and (min-width: 1440px) {
            border-width: 1px;
            border-radius: 12px;
            padding: 12px;
            gap: 16px;
        }
    }

    &__serviceHead {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        @media screen and (min-width: 526px) {

        }

        @media screen and (min-width: 921px) {

        }

        @media screen and (min-width: 1440px) {
            
        }
    }

    &__serviceName {
        @include head-4();
    }

    &__serviceStatus {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        @include text-regular();

        gap: vw(8px, 320px);
        width: vw(200px, 320px);

        @media screen and (min-width: 526px) {
            gap: vw(8px, 744px);
            width: vw(200px, 744px);
        }

        @media screen and (min-width: 921px) {
            gap: vw(8px);
            width: vw(200px);
        }

        @media screen and (min-width: 1440px) {
            gap: 8px;
            width: 200px;
        }
    }
}




// @media screen and (min-width: 526px) {

// }

// @media screen and (min-width: 921px) {

// }

// @media screen and (min-width: 1440px) {
    
// }