@import "../../styles/colors.scss";
@import "../../styles/helpers.scss";

.resetPassword {
   height: 100vh;
   display: flex;
   align-items: center;
   justify-content: center;

   padding: 0px vw(16px, 320px);
   padding-top: vw(60px, 320px);

   @media screen and (min-width: 526px) {
      padding: 0px;
      padding-top: vw(100px, 744px);
   }

   @media screen and (min-width: 921px) {
      padding-top: vw(100px, 1920px);
   }

   @media screen and (min-width: 1600px) {
      padding-top: 100px;
   }

   &__wrapper {
      align-items: center;
      justify-content: center;
      flex-direction: column;
      display: flex;

      max-width: none;

      @media screen and (min-width: 526px) {
         max-width: vw(500px, 744px);
      }

      @media screen and (min-width: 921px) {
         max-width: vw(500px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         max-width: 500px;
      }
   }

   &__title {
      font-weight: 400;
      text-align: center;

      font-size: vw(18px, 320px);
      line-height: vw(24px, 320px);
      margin-bottom: vw(18px, 320px);

      @media screen and (min-width: 526px) {
         font-size: vw(36px, 744px);
         line-height: vw(43px, 744px);
         margin-bottom: vw(48px, 744px);
      }

      @media screen and (min-width: 921px) {
         font-size: vw(36px, 1920px);
         line-height: vw(43px, 1920px);
         margin-bottom: vw(48px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         font-size: 36px;
         line-height: 43px;
         margin-bottom: 48px;
      }
   }

   &__text {
      font-weight: 400;
      text-align: center;

      font-size: vw(12px, 320px);
      line-height: vw(17px, 320px);
      margin-bottom: vw(32px, 320px);

      @media screen and (min-width: 526px) {
         font-size: vw(20px, 744px);
         line-height: vw(24px, 744px);
         margin-bottom: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         font-size: vw(20px, 1920px);
         line-height: vw(24px, 1920px);
         margin-bottom: vw(24px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         font-size: 20px;
         line-height: 24px;
         margin-bottom: 24px;
      }
   }

   &__form {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      gap: vw(18px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(20px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(20px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         gap: 20px;
      }
   }
}
