@import "../../../styles/helpers.scss";
@import "../../../styles/effects.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.actionButton {
   cursor: pointer;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   align-items: center;
   border: none;
   width: 100%;
   background-color: $white;
   padding: vw(16px, 320px);
   gap: vw(14px, 320px);
   border-radius: vw(12px, 320px);
   max-height: none;

   @include shadow-bottom-element();

   &:hover {
      background-color: $white;
   }

   &:hover > &__text {
      color: $sec;
   }

   &:focus-visible,
   &:focus,
   &:active {
      @include focus-secondary();
      outline: none;
   }

   &:focus > &__text,
   &:active > &__text {
      color: $sec;
   }

   @media screen and (min-width: 526px) {
      padding: vw(16px, 744px);
      gap: vw(14px, 744px);
      border-radius: vw(12px, 744px);
   }
   @media screen and (min-width: 921px) {
      padding: vw(16px);
      gap: vw(14px);
      border-radius: vw(12px);
   }
   @media screen and (min-width: 1440px) {
      padding: 16px;
      gap: 14px;
      border-radius: 12px;
   }

   &__text {
      transition: color ease-in-out 0.3s;
      @include text-regular();
      white-space: nowrap;
      color: $black;
   }

   &__icon {
      min-width: vw(32px, 320px);
      min-height: vw(32px, 320px);
      max-width: vw(32px, 320px);
      max-height: vw(32px, 320px);

      @media screen and (min-width: 526px) {
         min-width: vw(34px, 744px);
         min-height: vw(34px, 744px);
         max-width: vw(34px, 744px);
         max-height: vw(34px, 744px);
      }
      @media screen and (min-width: 921px) {
         min-width: vw(34px);
         min-height: vw(34px);
         max-width: vw(34px);
         max-height: vw(34px);
      }
      @media screen and (min-width: 1440px) {
         min-width: 34px;
         min-height: 34px;
         max-width: 34px;
         max-height: 34px;
      }
   }
}
