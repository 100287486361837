@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";

.mainDigitLine {
    width: 100vw;
    background: $sec;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    justify-content: center;
    padding: vw(16px, 320px) vw(24px, 320px);
    gap: vw(24px, 320px);

    @media screen and (min-width: 526px) {
        justify-content: space-between;
        padding: vw(24px, 744px) vw(24px, 744px);
        gap: vw(24px, 744px);
    }

    @media screen and (min-width: 921px) {
        padding: vw(24px) calc(50% - #{vw(600px)});
        gap: vw(24px);
        margin: vw(32px) 0px;
    }

    @media screen and (min-width: 1440px) {
        padding: 24px calc(50% - 600px);
        gap: 24px;
        margin: 32px 0px;
    }

    &__card {
        display: flex;
        flex-direction: column;

        align-items: center;

        @media screen and (min-width: 526px) {
            align-items: flex-start;
            width: vw(336px, 744px);
        }

        @media screen and (min-width: 921px) {
            width: auto;
        }

        @media screen and (min-width: 1440px) {
        }
    }

    &__cardValue {
        color: $white;
        @include head-2();


        @media screen and (min-width: 526px) {

        }

        @media screen and (min-width: 921px) {

        }

        @media screen and (min-width: 1440px) {
            
        }
    }

    &__cardSubText {
        color: $white;
        @include head-3();


        @media screen and (min-width: 526px) {

        }

        @media screen and (min-width: 921px) {

        }

        @media screen and (min-width: 1440px) {
            
        }
    }
}