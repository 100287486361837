@import "../../../styles/helpers.scss";
@import "../../../styles/effects.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.listServices {
   display: flex;
   flex-direction: column;
   gap: vw(1px, 320px);

   @media screen and (min-width: 526px) {
      gap: vw(1px, 744px);
   }
   @media screen and (min-width: 921px) {
      gap: vw(1px);
   }
   @media screen and (min-width: 1440px) {
      gap: 1px;
   }

   &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      width: 100%;
      min-height: vw(84px, 320px);

      @media screen and (min-width: 526px) {
         min-height: vw(84px, 744px);
      }
      @media screen and (min-width: 921px) {
         min-height: vw(84px);
      }
      @media screen and (min-width: 1440px) {
         min-height: 84px;
      }
   }

   &__text {
      @include text-medium();
      color: $gray;
   }

   &__button {
      cursor: pointer;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background-color: $gray-sx;
      width: 100%;
      border: none;

      border-radius: vw(12px, 320px);
      padding: vw(8px, 320px) vw(16px, 320px);
      min-height: vw(84px, 320px);

      @media screen and (min-width: 526px) {
         min-height: vw(84px, 744px);
         border-radius: vw(12px, 744px);
         padding: vw(8px, 744px) vw(16px, 744px);
      }
      @media screen and (min-width: 921px) {
         min-height: vw(84px);
         border-radius: vw(12px);
         padding: vw(8px) vw(16px);
      }
      @media screen and (min-width: 1440px) {
         min-height: 84px;
         border-radius: 12px;
         padding: 8px 16px;
      }

      & > span {
         @include text-regular();
         color: $black;
      }
   }

   &__icon {
      min-width: vw(32px, 320px);
      min-height: vw(32px, 320px);
      max-width: vw(32px, 320px);
      max-height: vw(32px, 320px);

      @media screen and (min-width: 526px) {
         min-width: vw(32px, 744px);
         min-height: vw(32px, 744px);
         max-width: vw(32px, 744px);
         max-height: vw(32px, 744px);
      }
      @media screen and (min-width: 921px) {
         min-width: vw(32px);
         min-height: vw(32px);
         max-width: vw(32px);
         max-height: vw(32px);
      }
      @media screen and (min-width: 1440px) {
         min-width: 32px;
         min-height: 32px;
         max-width: 32px;
         max-height: 32px;
      }
   }
}
