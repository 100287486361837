@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";


.checkbox {

    &__input {
        display: none;
    }
    &__block {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: $gray-sx;
        transition: background 0.3s ease-in-out;

        width: vw(20px, 320px);
        height: vw(20px, 320px);
        border-radius: vw(6px, 320px);

        @media screen and (min-width: 526px) {
            width: vw(24px, 744px);
            height: vw(24px, 744px);
            border-radius: vw(6px, 744px);
        }

        @media screen and (min-width: 921px) {
            width: vw(24px);
            height: vw(24px);
            border-radius: vw(6px);
        }

        @media screen and (min-width: 1440px) {
            width: 24px;
            height: 24px;
            border-radius: 6px;
        }
    }
    &__input:checked + &__block {
        background: $sec;
    }

    &__icon {
        opacity: 0;
        transition: transform 0.3s ease-in-out,
                    opacity   0.3s ease-in-out;

        transform: translateY(vw(10px, 320px));
        width: vw(11.75px, 320px);
        height: vw(8.5px, 320px);

        @media screen and (min-width: 526px) {
            transform: translateY(vw(10px, 744px));
            width: vw(11.75px, 744px);
            height: vw(8.5px, 744px);
        }

        @media screen and (min-width: 921px) {
            transform: translateY(vw(10px));
            width: vw(11.75px);
            height: vw(8.5px);
        }

        @media screen and (min-width: 1440px) {
            transform: translateY(10px);
            width: 11.75px;
            height: 8.5px;
        }
    }
    &__input:checked + &__block > &__icon {
        transform: translateY(0px);
        opacity: 1;
    }
}