@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.button {
   cursor: pointer;
   border: none;
   background: transparent;

   width: 100%;
   height: vw(52px, 1920px);

   @media screen and (min-width: 1600px) {
      width: 100%;
      height: 52px;
   }

   svg {
      path {
         width: vw(24px, 1920px);
         height: vw(24px, 1920px);

         @media screen and (min-width: 1600px) {
            width: 52px;
            height: 52px;
         }
      }
   }
}
