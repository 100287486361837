@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/effects.scss";

.input {
    width: 100%;
    position: relative;
    
    display: flex;
    flex-direction: column;

    &__field {
        outline: none;
        background: $white;
        border: solid $sec-light;

        @include text-regular();

        transition: border 0.3s ease-in-out, color 0.3s ease-in-out;
        
        height: vw(40px, 320px);
        padding: 0px vw(18px, 320px);
        border-radius: vw(12px, 320px);
        border-width: vw(1px, 320px);
        margin-bottom: vw(16px, 320px);

        @media screen and (min-width: 526px) {
            height: vw(42px, 744px);
            padding: 0px vw(18px, 744px);
            border-radius: vw(12px, 744px);
            border-width: vw(1px, 744px);
            margin-bottom: vw(16px, 744px);
        }

        @media screen and (min-width: 921px) {
            height: vw(42px);
            padding: 0px vw(18px);
            border-radius: vw(12px);
            border-width: vw(1px);
            margin-bottom: vw(16px);
        }

        @media screen and (min-width: 1440px) {
            height: 42px;
            padding: 0px 18px;
            border-radius: 12px;
            border-width: 1px;
            margin-bottom: 16px;
        }

        &:focus, &:not(:placeholder-shown) {
            border-color: $sec;
            color: $black;

            &::placeholder {
                opacity: 0;
            }
        }

        &:disabled {
            background: $gray-sx;
            border-color: $gray-x;
            color: $gray;
        }
    }
    
    & textarea {
        @include text-regular();
        
        padding-top: vw(12px, 320px);
        padding-bottom: vw(12px, 320px);
        min-height: vw(120px, 320px);

        @media screen and (min-width: 526px) {
            padding-top: vw(14px, 744px);
            padding-bottom: vw(14px, 744px);
            min-height: vw(120px, 744px);
        }

        @media screen and (min-width: 921px) {
            padding-top: vw(14px);
            padding-bottom: vw(14px);
            min-height: vw(120px);
        }

        @media screen and (min-width: 1440px) {
            padding-top: 14px;
            padding-bottom: 14px;
            min-height: 120px;
        }
    }
    
    &_error > &__field {
        background: $white;
        border-color: $reg;
    }

    &__errorText {
        position: absolute;
        color: $reg;
        top: vw(40px, 320px);

        @include sub-text();

        transform: translate3d(vw(12px, 320px), vw(4px, 320px), 0px);

        @media screen and (min-width: 526px) {
            transform: translate3d(vw(12px, 744px), vw(4px, 744px), 0px);
            top: vw(42px, 744px);
        }

        @media screen and (min-width: 921px) {
            transform: translate3d(vw(12px), vw(4px), 0px);
            top: vw(42px);
        }

        @media screen and (min-width: 1440px) {
            transform: translate3d(12px, 4px, 0px);
            top: 42px;
        }
    }

    &__label {
        @include sub-text();

        margin-bottom: vw(4px, 320px);

        @media screen and (min-width: 526px) {
            margin-bottom: vw(4px, 744px);
        }

        @media screen and (min-width: 921px) {
            margin-bottom: vw(4px);
        }

        @media screen and (min-width: 1440px) {
            margin-bottom: 4px;
        }
    }

    &__placeholder {
        position: absolute;
        color: $gray;
        top: 0px;

        transition: transform 0.2s ease-in-out, background 0.2s ease-in-out, font-size 0.2s ease-in-out;

        @include text-regular();

        max-width: calc(100% - #{vw(32px, 320px)});
        padding: 0px vw(2px, 320px);
        left: vw(16px, 320px);
        transform: translateY(vw(9px, 320px));

        @media screen and (min-width: 526px) {
            max-width: calc(100% - #{vw(32px, 744px)});
            padding: 0px vw(2px, 744px);
            left: vw(16px, 744px);
            transform: translateY(vw(9px, 744px));
        }

        @media screen and (min-width: 921px) {
            max-width: calc(100% - #{vw(32px)});
            padding: 0px vw(2px);
            left: vw(16px);
            transform: translateY(vw(9px));
        }

        @media screen and (min-width: 1440px) {
            max-width: calc(100% - 32px);
            padding: 0px 2px;
            left: 16px;
            transform: translateY(9px);
        }
    }
    &__field:focus + &__placeholder, &__field:not(:placeholder-shown) + &__placeholder {
        background: $white;
        @include sup-text();
        transform: translateY(-50%);
    }
}