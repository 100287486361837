@import "../../../styles/helpers.scss";
@import "../../../styles/effects.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.servicesItem {
   display: flex;
   flex-direction: row;
   justify-content: flex-start;
   align-items: center;
   transition: background-color ease-in-out 0.3s;
   background-color: $white;
   width: 100%;
   padding: vw(16px, 320px) vw(8px, 320px);
   gap: vw(14px, 320px);
   border-radius: vw(12px, 320px);
   min-height: vw(64px, 320px);

   &:focus-visible,
   &:active,
   &:hover {
      outline: none;
      box-shadow: none;
      background-color: $gray-sx;
   }

   @media screen and (min-width: 526px) {
      padding: vw(16px, 744px) vw(8px, 744px);
      gap: vw(14px, 744px);
      border-radius: vw(12px, 744px);
      min-height: vw(84px, 744px);
   }
   @media screen and (min-width: 921px) {
      padding: vw(16px) vw(8px);
      gap: vw(14px);
      border-radius: vw(12px);
      min-height: vw(84px);
   }
   @media screen and (min-width: 1440px) {
      padding: 16px 8px;
      gap: 14px;
      border-radius: 12px;
      min-height: 84px;
   }

   &__body {
      display: flex;
      flex-direction: column;
      gap: vw(6px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(6px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(6px);
      }
      @media screen and (min-width: 1440px) {
         gap: 6px;
      }
   }

   &__title,
   &__text {
      line-height: 100%;
      color: $black;
   }

   &__title {
      @include text-medium();
   }
   &__text {
      @include sub-text();
   }

   &__icon {
      min-width: vw(28px, 320px);
      min-height: vw(28px, 320px);
      max-width: vw(28px, 320px);
      max-height: vw(28px, 320px);

      @media screen and (min-width: 526px) {
         min-width: vw(34px, 744px);
         min-height: vw(34px, 744px);
         max-width: vw(34px, 744px);
         max-height: vw(34px, 744px);
      }
      @media screen and (min-width: 921px) {
         min-width: vw(34px);
         min-height: vw(34px);
         max-width: vw(34px);
         max-height: vw(34px);
      }
      @media screen and (min-width: 1440px) {
         min-width: 34px;
         min-height: 34px;
         max-width: 34px;
         max-height: 34px;
      }
   }
}
