@import "../../../styles/colors.scss";
@import "../../../styles/effects.scss";
@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";

.copiedField {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    
    gap: vw(8px, 320px);

    @media screen and (min-width: 526px) {
        gap: vw(8px, 744px);
    }

    @media screen and (min-width: 921px) {
        gap: vw(8px);
    }

    @media screen and (min-width: 1440px) {
        gap: 8px;
    }

    &__text {
        display: flex;
        flex-direction: column;
        margin-right: auto;

        gap: vw(4px, 320px);

        @media screen and (min-width: 526px) {
            gap: vw(4px, 744px);
        }

        @media screen and (min-width: 921px) {
            gap: vw(4px);
        }

        @media screen and (min-width: 1440px) {
            gap: 4px;
        }
    }

    &__label {
        @include sup-text();
        color: $gray;
    }

    &__value {
        @include sub-text();
    }

    &__button {
        background: $gray-sx;
        padding: 0px;

        min-width: vw(32px, 320px);
        max-width: vw(32px, 320px);
        min-height: vw(32px, 320px);
        max-height: vw(32px, 320px);
        border-radius: vw(8px, 320px);

        @media screen and (min-width: 526px) {
            min-width: vw(32px, 744px);
            max-width: vw(32px, 744px);
            min-height: vw(32px, 744px);
            max-height: vw(32px, 744px);
            border-radius: vw(8px, 744px);
        }

        @media screen and (min-width: 921px) {
            min-width: vw(32px);
            max-width: vw(32px);
            min-height: vw(32px);
            max-height: vw(32px);
            border-radius: vw(8px);
        }

        @media screen and (min-width: 1440px) {
            min-width: 32px;
            max-width: 32px;
            min-height: 32px;
            max-height: 32px;
            border-radius: 8px;
        }
    }

    &__icon {
        min-width: vw(24px, 320px);
        max-width: vw(24px, 320px);
        min-height: vw(24px, 320px);
        max-height: vw(24px, 320px);

        @media screen and (min-width: 526px) {
            min-width: vw(24px, 744px);
            max-width: vw(24px, 744px);
            min-height: vw(24px, 744px);
            max-height: vw(24px, 744px);
        }

        @media screen and (min-width: 921px) {
            min-width: vw(24px);
            max-width: vw(24px);
            min-height: vw(24px);
            max-height: vw(24px);
        }

        @media screen and (min-width: 1440px) {
            min-width: 24px;
            max-width: 24px;
            min-height: 24px;
            max-height: 24px;
        }
    }
}