@import '../../../styles/colors.scss';
@import '../../../styles/mixins.scss';
@import '../../../styles/helpers.scss';

.tabView {
    display: flex;
    flex-direction: column;

    &__controls {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow-x: auto;

        gap: vw(4px, 320px);

        @media screen and (min-width: 526px) {
            gap: vw(4px, 744px);
        }

        @media screen and (min-width: 921px) {
            gap: vw(4px);
        }

        @media screen and (min-width: 1440px) {
            gap: 4px;
        }

        
        /* width */
        &::-webkit-scrollbar {
            height: 4px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $gray-x;
            border-radius: 0px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $gray;
        }
    }

    &__tab {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: $gray-sx;
        cursor: pointer;
        border: none;
        min-width: max-content;

        transition: background 0.3s ease-in-out;

        padding: vw(8px, 320px) vw(16px, 320px);
        border-radius: vw(8px, 320px) vw(8px, 320px) 0px 0px;
        gap: vw(8px, 320px);

        @media screen and (min-width: 526px) {
            padding: vw(8px, 744px) vw(16px, 744px);
            border-radius: vw(8px, 744px) vw(8px, 744px) 0px 0px;
            gap: vw(8px, 744px);
        }

        @media screen and (min-width: 921px) {
            padding: vw(8px) vw(16px);
            border-radius: vw(8px) vw(8px) 0px 0px;
            gap: vw(8px);
        }

        @media screen and (min-width: 1440px) {
            padding: 8px 16px;
            border-radius: 8px 8px 0px 0px;
            gap: 8px;
        }

        &_active {
            background: $sec;
            cursor: default;
        }

        svg {
            width: vw(24px, 320px);
            height: vw(24px, 320px);

            @media screen and (min-width: 526px) {
                width: vw(24px, 744px);
                height: vw(24px, 744px);
            }

            @media screen and (min-width: 921px) {
                width: vw(24px);
                height: vw(24px);
            }

            @media screen and (min-width: 1440px) {
                width: 24px;
                height: 24px;
            }

            path {
                transition: fill 0.3s ease-in-out;
            }
        }
    }

    &__tabName {
        @include text-medium();
    }

    &__tab_active &__tabName {
        color: $white;
    }

    &__tab_active svg {
        path {
            fill: $white;
        }
    }

    &__container {
        border: solid $light;

        // border-top: none;

        padding: vw(20px, 320px);
        border-width: vw(1px, 320px);
        border-radius: 0px 0px vw(20px, 320px) vw(20px, 320px);

        @media screen and (min-width: 526px) {
            padding: vw(20px, 744px);
            border-width: vw(1px, 744px);
            border-radius: 0px 0px vw(20px, 744px) vw(20px, 744px);
        }

        @media screen and (min-width: 921px) {
            padding: vw(20px);
            border-width: vw(1px);
            border-radius: 0px 0px vw(20px) vw(20px);
        }

        @media screen and (min-width: 1440px) {
            padding: 20px;
            border-width: 1px;
            border-radius: 0px 0px 20px 20px;
        }
    }
}

.tab {


    @media screen and (min-width: 526px) {

    }

    @media screen and (min-width: 921px) {

    }

    @media screen and (min-width: 1440px) {
        
    }
}