@import "../../styles/helpers.scss";
@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.lists {
   display: none;
   @media screen and (min-width: 526px) {
      display: flex;
      flex-direction: row;
      gap: vw(32px, 744px);
      grid-area: 3 / 1 / 4 / 3;
   }

   @media screen and (min-width: 921px) {
      flex-direction: column;
      grid-area: 1 / 1 / 4 / 2;
      gap: vw(24px);
   }

   @media screen and (min-width: 1440px) {
      grid-area: 1 / 1 / 4 / 2;
      gap: 24px;
   }
}

.alert {
   @media screen and (min-width: 526px) {
      grid-area: 1 / 1 / 2 / 3;
   }

   @media screen and (min-width: 921px) {
      grid-area: 1 / 2 / 2 / 3;
   }

   @media screen and (min-width: 1440px) {
      grid-area: 1 / 2 / 2 / 3;
   }

   &__desktop {
      display: none;
      @media screen and (min-width: 526px) {
         display: block;
      }
   }

   &__desktop_show {
      display: block;
   }

   &__mobile {
      display: block;
      @media screen and (min-width: 526px) {
         display: none;
      }
   }
}

.services {
   display: none;
   @media screen and (min-width: 526px) {
      display: block;
      width: 100%;
   }
}

.cards {
   display: none;
   @media screen and (min-width: 526px) {
      display: block;
      width: 100%;
   }
}

.actions {
   @media screen and (min-width: 526px) {
      grid-area: 2 / 1 / 3 / 3;
   }

   @media screen and (min-width: 921px) {
      grid-area: 2 / 2 / 3 / 3;
   }

   @media screen and (min-width: 1440px) {
      grid-area: 2 / 2 / 3 / 3;
   }

   &__buttons {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: vw(16px, 320px);

      @media screen and (min-width: 526px) {
         flex-direction: row;
         gap: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         gap: 24px;
      }
   }
}

.story {
   display: none;
   @media screen and (min-width: 526px) {
      display: block;
      grid-area: 4 / 1 / 5 / 3;
   }

   @media screen and (min-width: 921px) {
      grid-area: 3 / 2 / 4 / 3;
   }

   @media screen and (min-width: 1440px) {
      grid-area: 3 / 2 / 4 / 3;
   }
}

.slider {
   display: block;
   @media screen and (min-width: 526px) {
      display: none;
   }
}

.profile__title {
   @include head-3();
   color: $black;
   margin-bottom: vw(12px, 320px);

   @media screen and (min-width: 526px) {
      margin-bottom: vw(12px, 744px);
   }
   @media screen and (min-width: 921px) {
      margin-bottom: vw(12px);
   }
   @media screen and (min-width: 1440px) {
      margin-bottom: 12px;
   }
}
