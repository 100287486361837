@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";
@import "../../../styles/effects.scss";
@import "../../../styles/mixins.scss";

.inputRadio {
    display: flex;
    flex-direction: column;
    width: 100%;
    
    gap: vw(12px, 320px);

    @media screen and (min-width: 526px) {
        gap: vw(12px, 744px);
    }

    @media screen and (min-width: 921px) {
        gap: vw(12px);
    }

    @media screen and (min-width: 1440px) {
        gap: 12px;
    }

    &__label {
        @include text-regular();
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;

        gap: vw(24px, 320px);

        @media screen and (min-width: 526px) {
            gap: vw(24px, 744px);
        }

        @media screen and (min-width: 921px) {
            gap: vw(24px);
        }

        @media screen and (min-width: 1440px) {
            gap: 24px;
        }
    }

    &__option {
        cursor: pointer;
    }
    
    &__input {
        display: none;
    }

    &__optionText {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        
        @include text-regular();

        gap: vw(14px, 320px);

        @media screen and (min-width: 526px) {
            gap: vw(14px, 744px);
        }

        @media screen and (min-width: 921px) {
            gap: vw(14px);
        }

        @media screen and (min-width: 1440px) {
            gap: 14px;
        }

        &::before {
            content: " ";
            background: $light;
            border: solid $light;
            transition: background 0.3s ease-in-out,
                        border-color 0.3s ease-in-out;

            min-width: vw(18px, 320px);
            max-width: vw(18px, 320px);
            min-height: vw(18px, 320px);
            max-height: vw(18px, 320px);
            border-width: vw(4px, 320px);
            border-radius: vw(9px, 320px);

            @media screen and (min-width: 526px) {
                min-width: vw(18px, 744px);
                max-width: vw(18px, 744px);
                min-height: vw(18px, 744px);
                max-height: vw(18px, 744px);
                border-width: vw(4px, 744px);
                border-radius: vw(9px, 744px);
            }

            @media screen and (min-width: 921px) {
                min-width: vw(18px);
                max-width: vw(18px);
                min-height: vw(18px);
                max-height: vw(18px);
                border-width: vw(4px);
                border-radius: vw(9px);
            }

            @media screen and (min-width: 1440px) {
                min-width: 18px;
                max-width: 18px;
                min-height: 18px;
                max-height: 18px;
                border-width: 4px;
                border-radius: 9px;
            }
        }
    }

    &__input:checked + &__optionText::before {
        background: $white;
        border-color: $main;
    }
}