@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";

.pincode {
   &__area {
      display: flex;
      flex-direction: row;

      gap: vw(6px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(10px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(10px);
      }

      @media screen and (min-width: 1440px) {
         gap: 10px;
      }

      &_invalid {
         animation: invalid 0.2s ease-in-out 3;

         @keyframes invalid {
            0% {
               transform: translateX(0px);
            }

            25% {
               transform: translateX(10px);
            }

            50% {
               transform: translateX(0px);
            }

            75% {
               transform: translateX(-10px);
            }

            100% {
               transform: translateX(0px);
            }
         }
      }
   }

   &__input {
      opacity: 0;
      transform: scale(0);
      position: absolute;
   }

   &__box {
      background: $white;
      color: $gray;
      border: solid $gray-x;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 500;
      cursor: text;

      @include head-4();

      transition: border-color 0.3s ease-in-out;

      width: vw(40px, 320px);
      height: vw(40px, 320px);
      border-radius: vw(8px, 320px);
      border-width: vw(1px, 320px);

      @media screen and (min-width: 526px) {
         width: vw(42px, 744px);
         height: vw(42px, 744px);
         border-radius: vw(8px, 744px);
         border-width: vw(1px, 744px);
      }

      @media screen and (min-width: 921px) {
         width: vw(42px);
         height: vw(42px);
         border-radius: vw(8px);
         border-width: vw(1px);
      }

      @media screen and (min-width: 1440px) {
         width: 42px;
         height: 42px;
         border-radius: 8px;
         border-width: 1px;
      }
   }

   &__area_invalid > &__box {
      border-color: $error;
   }

   &__input:focus ~ &__box_current {
      border-color: $main;

      &::after {
         content: "|";
         position: absolute;
         font-weight: 100;
         animation: blink 1s infinite;

         @keyframes blink {
            0% {
               opacity: 0;
            }
            49% {
               opacity: 0;
            }
            50% {
               opacity: 1;
            }
            100% {
               opacity: 1;
            }
         }
      }

      &:not(:empty)::after {
         display: none;
      }
   }
}
