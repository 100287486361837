@import "../../styles/colors.scss";
@import "../../styles/helpers.scss";
@import "../../styles/effects.scss";
@import "../../styles/mixins.scss";

.application {
   margin: 0px auto;
   width: 100%;

   margin-top: vw(32px, 320px);
   padding: 0px vw(16px, 320px);

   @media screen and (min-width: 526px) {
      margin-top: vw(32px, 744px);
      padding: 0px vw(24px, 744px);
   }

   @media screen and (min-width: 921px) {
      padding: 0px;
      width: vw(992px);
      margin: 0px auto;
      // margin-right: calc(100% - #{vw(992px)});
      margin-top: vw(64px);
   }

   @media screen and (min-width: 1440px) {
      width: 992px;
      // margin-right: calc(100% - 992px);
      margin-top: 64px;
   }

   &__error {
      border: solid $reg;

      @include text-regular();

      border-width: vw(2px, 320px);
      padding: vw(6px, 320px) vw(16px, 320px);
      border-radius: vw(16px, 320px);
      margin-bottom: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         border-width: vw(2px, 744px);
         padding: vw(12px, 744px) vw(32px, 744px);
         border-radius: vw(16px, 744px);
         margin-bottom: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         border-width: vw(2px);
         padding: vw(12px, 1920px) vw(32px);
         border-radius: vw(16px);
         margin-bottom: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         border-width: 2px;
         padding: 12px 32px;
         border-radius: 16px;
         margin-bottom: 24px;
      }

   }

   &__title {
      @include head-2();

      margin-bottom: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         margin-bottom: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         margin-bottom: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         margin-bottom: 24px;
      }
   }

   &__header {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      gap: vw(16px, 320px);
      margin-bottom: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(16px, 744px);
         margin-bottom: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(16px);
         margin-bottom: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         gap: 16px;
         margin-bottom: 24px;
      }
   }

   &__tab {
      cursor: pointer;
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      height: vw(64px, 320px);

      @media screen and (min-width: 526px) {
         height: vw(64px, 744px);
      }

      @media screen and (min-width: 921px) {
         height: vw(64px);
      }

      @media screen and (min-width: 1440px) {
         height: 64px;
      }

      &::after {
         content: " ";
         width: 100%;
         position: absolute;
         left: 0px;
         bottom: 0px;
         background: $gray-sx;
         transition: background 0.3s ease-in-out;

         height: vw(4px, 320px);
         border-radius: vw(2px, 320px);

         @media screen and (min-width: 526px) {
            height: vw(4px, 744px);
            border-radius: vw(2px, 744px);
         }

         @media screen and (min-width: 921px) {
            height: vw(4px);
            border-radius: vw(2px);
         }

         @media screen and (min-width: 1440px) {
            height: 4px;
            border-radius: 2px;
         }
      }

      &_disabled {
         cursor: not-allowed;
      }

      &_active {
         &::after {
            background: $sec;
         }
      }
   }

   &__tabStep {
      color: $gray;
      transition: color 0.3s ease-in-out;

      @include head-4();
   }

   &__tabText {
      display: none;
      color: $gray;
      transition: color 0.3s ease-in-out;
      
      @include head-4();

      @media screen and (min-width: 921px) {
         display: block;
      }
   }

   &__tab_active &__tabStep {
      color: $sec;
   }
   &__tab_active &__tabText {
      color: $black;
   }

   &__moileTitle {
      
      @include head-3();

      &::first-letter {
         text-transform: uppercase;
      }

      margin-bottom: vw(32px, 320px);

      @media screen and (min-width: 526px) {
         margin-bottom: vw(32px, 744px);
      }

      @media screen and (min-width: 921px) {
         display: none;
      }
   }

   &__footer {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;

      gap: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(24px, 744px);
         margin-bottom: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(24px);
         margin-bottom: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         gap: 24px;
         margin-bottom: 24px;
      }
   }

   &__footerButton {
      width: 100%;

      @media screen and (min-width: 526px) {
         width: auto;
      }
   }

   &__step {
      &Block {
         width: 100%;
         display: flex;
         flex-direction: column;
         
         margin-bottom: vw(24px, 320px);
         gap: vw(24px, 320px);

         @media screen and (min-width: 526px) {
            margin-bottom: vw(24px, 744px);
            gap: vw(24px, 744px);
         }

         @media screen and (min-width: 921px) {
            flex-direction: row;
            margin-bottom: vw(24px);
            gap: vw(24px);
         }

         @media screen and (min-width: 1440px) {
            margin-bottom: 24px;
            gap: 24px;
         }
      }
   }

   &__form {
      &Block {
         width: 100%;

         &_text {

            @include text-regular();
            align-self: flex-start;

            transform: translateY(vw(-20px, 320px));
            padding-top: 0px;

            @media screen and (min-width: 526px) {
               transform: translateY(vw(-22px, 744px));
            }

            @media screen and (min-width: 921px) {
               transform: none;
            }

            @media screen and (min-width: 1440px) {
            }
         }
      }
   }

   &__sign {
      margin-top: vw(32px, 320px);
      margin-bottom: vw(64px, 744px);

      @media screen and (min-width: 526px) {
         margin-top: vw(64px, 744px);
         margin-bottom: vw(64px, 744px);
      }

      @media screen and (min-width: 921px) {
         margin-top: vw(64px);
         margin-bottom: vw(64px);
      }

      @media screen and (min-width: 1440px) {
         margin-top: 64px;
         margin-bottom: 64px;
      }
   }

   &__signHeader {
      @include head-3();

      margin-bottom: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         margin-bottom: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         margin-bottom: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         margin-bottom: 24px;
      }
   }

   &__signBlock {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: flex-start;
      cursor: pointer;

      gap: vw(14px, 320px);
      margin-bottom: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(14px, 744px);
         margin-bottom: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(14px);
         margin-bottom: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         gap: 14px;
         margin-bottom: 24px;
      }
   }

   &__signBlockText {
      @include text-regular();

      a {
         font-size: inherit;
         line-height: inherit;
         color: $sec;
      }
   }

   &__signNote {
      color: $gray;

      @include text-regular();

      margin-top: vw(8px, 320px);

      @media screen and (min-width: 526px) {
         margin-top: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         margin-top: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         margin-top: 24px;
      }
   }


   &__ruleHiddenText {
      display: inline-flex;
      flex-direction: row;
      align-items: center;
      text-decoration: none;

      &:hover {
         text-decoration: underline;
      }
    
      gap: vw(4px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(4px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(4px);
      }

      @media screen and (min-width: 1440px) {
         gap: 4px;
      }
   }
   
   &__ruleHiddenIcon {
      transform: rotate(90deg);
      transition: transform 0.3s ease-in-out;
    
      width: vw(24px, 320px);
      height: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         width: vw(24px, 744px);
         height: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         width: vw(24px);
         height: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         width: 24px;
         height: 24px;
      }

      &_open {
         transform: rotate(-90deg);
      }
   }

   &__ruleHiddenContainer {
      overflow: hidden;
      
      padding-left: vw(16px, 320px);
    
      @media screen and (min-width: 526px) {
         padding-left: vw(32px, 744px);
      }

      @media screen and (min-width: 921px) {
         padding-left: vw(32px);
      }

      @media screen and (min-width: 1440px) {
         padding-left: 32px;
      }
   }
}
