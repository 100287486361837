@import "../../styles/helpers.scss";
@import "../../styles/effects.scss";
@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.aboutLoan {
   position: relative;
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: repeat(3, auto);

   max-width: 100%;
   padding: vw(32px, 320px) vw(16px, 320px);
   row-gap: vw(24px, 320px);

   @media screen and (min-width: 526px) {
      row-gap: vw(32px, 744px);
      padding: vw(32px, 744px) vw(24px, 744px);
   }

   @media screen and (min-width: 921px) {
      row-gap: vw(32px);
      padding: vw(32px) 0px;
      max-width: vw(1200px);
      margin: 0px auto;
   }

   @media screen and (min-width: 1440px) {
      row-gap: 32px;
      max-width: 1200px;
      padding: 32px 0px;
   }

   &__header {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, auto);
      column-gap: 0px;

      row-gap: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         row-gap: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         row-gap: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         row-gap: 24px;
      }
   }

   &__menu {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      align-items: center;
      justify-items: stretch;
      row-gap: vw(16px, 320px);

      button {
         width: 100%;

         @media screen and (min-width: 526px) {
            width: vw(200px, 744px);
         }

         @media screen and (min-width: 921px) {
            width: vw(242px);
         }

         @media screen and (min-width: 1440px) {
            width: 242px;
         }
      }

      @media screen and (min-width: 526px) {
         grid-template-columns: 1fr auto;
         row-gap: 0px;
      }
   }

   &__text {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, auto);
      row-gap: vw(4px, 320px);

      @media screen and (min-width: 526px) {
         row-gap: vw(4px, 744px);
      }

      @media screen and (min-width: 921px) {
         row-gap: vw(4px);
      }

      @media screen and (min-width: 1440px) {
         row-gap: 4px;
      }
   }

   &__subtext {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;

      @include sub-text();
      color: $gray;

      gap: vw(4px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(4px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(4px);
      }

      @media screen and (min-width: 1440px) {
         gap: 4px;
      }
   }

   &__icon {
      cursor: pointer;
      display: none;

      @media screen and (min-width: 526px) {
         width: vw(16px, 744px);
         height: vw(16px, 744px);
      }

      @media screen and (min-width: 921px) {
         width: vw(16px);
         height: vw(16px);
         display: block;
      }

      @media screen and (min-width: 1440px) {
         width: 16px;
         height: 16px;
         display: block;
      }
   }

   &__title {
      @include head-3();
      color: $black;
   }

   &__cards,
   &__dashboard {
      display: flex;
      flex-direction: row;
      width: 100%;
   }

   &__cards {
      flex-direction: column;
      gap: vw(16px, 320px);

      @media screen and (min-width: 526px) {
         flex-direction: row;
         gap: vw(16px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(16px);
      }

      @media screen and (min-width: 1440px) {
         gap: 16px;
      }
   }

   &__card {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: flex-start;
      background: $white;

      @include shadow-bottom-element();

      border-radius: vw(12px, 320px);
      gap: vw(4px, 320px);
      padding: vw(16px, 320px);
      width: 100%;
      min-width: none;

      @media screen and (min-width: 526px) {
         gap: vw(4px, 744px);
         padding: vw(16px, 744px);
         border-radius: vw(12px, 744px);
         min-width: vw(164px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(4px);
         padding: vw(16px);
         border-radius: vw(12px);
         min-width: vw(164px);
      }

      @media screen and (min-width: 1440px) {
         gap: 4px;
         padding: 16px;
         border-radius: 12px;
         min-width: 164px;
      }
   }

   &__cardTitle {
      @include sub-text();
      color: $gray;
   }

   &__cardData {
      @include head-3();
      color: $black;
   }

   &__document {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, auto);
      column-gap: 0px;

      row-gap: vw(32px, 320px);

      @media screen and (min-width: 526px) {
         row-gap: vw(32px, 744px);
      }

      @media screen and (min-width: 921px) {
         row-gap: vw(32px);
      }

      @media screen and (min-width: 1440px) {
         row-gap: 32px;
      }

      &_gap {
         row-gap: 0px;
      }
   }
}
