@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.container {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   grid-template-rows: repeat(2, auto);
   column-gap: vw(26px, 1920px);
   row-gap: vw(64px, 1920px);

   &__section_1 {
      grid-area: 1 / 1 / 2 / 2;
   }
   &__section_2 {
      grid-area: 1 / 2 / 2 / 3;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto 1fr;
      row-gap: vw(20px, 1920px);

      align-items: end;
      justify-items: stretch;

      @media screen and (min-widht: 1600px) {
         row-gap: 20px;
      }
   }

   &__section_3 {
      grid-area: 2 / 1 / 3 / 3;
   }

   @media screen and (min-widht: 1600px) {
      column-gap: 26px;
      row-gap: 64px;
   }
}

.card__wrapper {
   display: grid;

   grid-template-rows: vw(80px, 1920px) repeat(2, auto);
   grid-template-columns: vw(80px, 1920px) 1fr;
   column-gap: vw(24px, 1920px);
   row-gap: vw(8px, 1920px);

   @media screen and (min-widht: 1600px) {
      grid-template-rows: 80px repeat(2, auto);
      grid-template-columns: 80px 1fr;
      column-gap: 24px;
      row-gap: 8px;
   }

   img {
      grid-area: 1 / 1 / 2 / 2;

      border-radius: 100%;
      width: vw(80px, 1920px);
      height: vw(80px, 1920px);

      @media screen and (min-widht: 1600px) {
         width: 80px;
         height: 80px;
      }
   }
}

.card__title {
   grid-area: 1 / 2 / 2 / 3;

   font-weight: 500;
   letter-spacing: 0em;
   text-align: left;

   color: $main-dark;

   font-size: vw(32px, 1920px);
   line-height: vw(32px, 1920px);
   max-width: vw(500px, 1920px);

   @media screen and (min-widht: 1600px) {
      font-size: 32px;
      line-height: 32px;
      max-width: 500px;
   }
}

.card__link {
   a {
      font-weight: 500;
      letter-spacing: 0em;
      text-align: left;

      font-size: vw(16px, 1920px);
      line-height: vw(16px, 1920px);

      @media screen and (min-widht: 1600px) {
         font-size: 16px;
         line-height: 16px;
      }
   }
   &_tel {
      grid-area: 2 / 2 / 3 / 3;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      gap: vw(12px, 1920px);

      @media screen and (min-widht: 1600px) {
         gap: 12px;
      }

      svg {
         cursor: pointer;
         width: vw(24px, 1920px);
         height: vw(24px, 1920px);

         @media screen and (min-widht: 1600px) {
            width: 24px;
            height: 24px;
         }
      }
   }

   &_email {
      grid-area: 3 / 2 / 4 / 3;

      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      gap: vw(12px, 1920px);

      @media screen and (min-widht: 1600px) {
         gap: 12px;
      }

      svg {
         cursor: pointer;
         width: vw(24px, 1920px);
         height: vw(24px, 1920px);

         @media screen and (min-widht: 1600px) {
            width: 24px;
            height: 24px;
         }
      }
   }
}

.link__header {
   grid-area: 1 / 1 / 2 / 3;

   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: space-between;

   font-weight: 400;
   letter-spacing: -0.01em;
   text-align: left;

   font-size: vw(20px, 1920px);
   line-height: vw(24px, 1920px);

   @media screen and (min-widht: 1600px) {
      font-size: 20px;
      line-height: 24px;
   }

   svg {
      width: vw(24px, 1920px);
      height: vw(24px, 1920px);

      @media screen and (min-widht: 1600px) {
         width: 24px;
         height: 24px;
      }
   }
}

.link__block {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   align-items: flex-start;
   justify-content: flex-start;

   gap: vw(8px, 1920px);

   @media screen and (min-widht: 1600px) {
      gap: 8px;
   }
}

.link__title {
   font-weight: 500;
   letter-spacing: -0.01em;
   text-align: left;

   color: $secondary-dark;

   font-size: vw(20px, 1920px);
   line-height: vw(24px, 1920px);

   @media screen and (min-widht: 1600px) {
      font-size: 20px;
      line-height: 24px;
   }
}

.link__subtitle {
   font-weight: 600;
   letter-spacing: -0.01em;
   text-align: left;

   color: $main-dark;

   font-size: vw(32px, 1920px);
   line-height: vw(38px, 1920px);

   @media screen and (min-widht: 1600px) {
      font-size: 32px;
      line-height: 38px;
   }
}

.field {
   font-weight: 600 !important;
   letter-spacing: 0em;
   text-align: left;

   font-size: vw(20px, 1920px);
   line-height: vw(20px, 1920px);

   cursor: pointer;

   @media screen and (min-width: 1600px) {
      font-size: 20px;
      line-height: 20px;
   }
}

.passport {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: auto;
   column-gap: vw(24px, 1920px);
   row-gap: vw(24px, 1920px);

   &_row {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr;
      column-gap: vw(24px, 1920px);
      row-gap: vw(24px, 1920px);
   }

   @media screen and (min-width: 1600px) {
      column-gap: 24px;
      row-gap: 24px;
   }

   &__block {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      gap: vw(12px, 1920px);

      &_col {
         display: flex;
         flex-direction: column;
         flex-wrap: nowrap;
         align-items: flex-start;
         justify-content: flex-start;
      }

      img {
         object-fit: cover;
         background-size: cover;
         width: vw(256px, 1920px);
         height: vw(128px, 1920px);
         border-radius: vw(10px, 1920px);
         @media screen and (min-widht: 1600px) {
            border-radius: 10px;
            width: 256px;
            height: 128px;
         }
      }

      span,
      a {
         font-weight: 500;
         letter-spacing: 0em;
         text-align: left;
         width: auto;

         font-size: vw(16px, 1920px);
         line-height: vw(16px, 1920px);

         @media screen and (min-widht: 1600px) {
            font-size: 16px;
            line-height: 16px;
         }
      }

      @media screen and (min-width: 1600px) {
         gap: 12px;
      }
   }
}

.form {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   align-items: stretch;
   justify-content: space-between;
   height: 100%;
   gap: vw(24px, 1920px);

   @media screen and (min-widht: 1600px) {
      gap: 24px;
   }

   &__text {
      font-weight: 500;
      letter-spacing: 0em;
      text-align: left;
      width: auto;

      font-size: vw(20px, 1920px);
      line-height: vw(20px, 1920px);

      @media screen and (min-widht: 1600px) {
         font-size: 20px;
         line-height: 20px;
      }
   }
   &__buttons {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;

      &_min {
         max-width: 30%;
      }
   }
   &__fields {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;
      gap: vw(12px, 1920px);
      margin: vw(12px, 1920px) 0px;

      @media screen and (min-widht: 1600px) {
         gap: 12px;
         margin: 12px 0px;
      }
   }
}

.acord {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   align-items: flex-start;
   justify-content: space-between;
   gap: vw(24px, 1920px);
   @media screen and (min-widht: 1600px) {
      gap: 24px;
   }
}

.textaria {
   textarea {
      resize: none !important;
      width: 100%;
   }
}

.notice {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: space-between;
   width: 100%;

   min-height: vw(52px, 1920px);
   padding: vw(15px, 1920px);
   gap: vw(15px, 1920px);
   border: vw(1px, 1920px) solid $light;
   background-color: $ultra-light;
   border-radius: vw(15px, 1920px);

   @media screen and (min-width: 1600px) {
      min-height: 52px;
      padding: 15px;
      gap: 15px;
      border: 1px solid $light;
      border-radius: 15px;
   }

   &__text {
      font-weight: 400;
      letter-spacing: -0.01em;
      text-align: left;

      font-size: vw(20px, 1920px);
      line-height: vw(24px, 1920px);

      @media screen and (min-width: 1600px) {
         font-size: 20px;
         line-height: 24px;
      }
   }

   &__actions {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-end;
      gap: vw(24px, 1920px);

      @media screen and (min-width: 1600px) {
         gap: 24px;
      }
   }

   &__button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: $light;
      color: $main-dark;
      border: none;
      cursor: pointer;
      font-weight: 500;
      letter-spacing: 0em;
      text-align: center;

      padding: 0px vw(30px, 1920px);
      min-height: vw(48px, 1920px);
      border-radius: vw(8px, 1920px);
      font-size: vw(18px, 1920px);
      line-height: vw(18px, 1920px);

      margin-left: auto;

      &_red {
         background: $error;
         color: $white;
         font-weight: 600;
      }

      @media screen and (min-width: 1600px) {
         padding: 0px 30px;
         min-height: 48px;
         border-radius: 8px;
         font-size: 18px;
         line-height: 18px;
      }
   }
}

.about {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   align-items: flex-start;
   justify-content: flex-start;
   gap: vw(32px, 1920px);

   @media screen and (min-width: 1600px) {
      gap: 32px;
   }

   &_item {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      gap: vw(24px, 1920px);

      @media screen and (min-width: 1600px) {
         gap: 24px;
      }
   }

   &_text {
      font-weight: 400;
      letter-spacing: -0.01em;
      text-align: left;

      font-size: vw(20px, 1920px);
      line-height: vw(20px, 1920px);

      &_nums {
         font-family: Consolas;
         font-weight: 600;
         letter-spacing: 0.02em;
      }

      &_link {
         font-weight: 600;
         color: $main;
         text-decoration: underline;
      }

      @media screen and (min-width: 1600px) {
         font-size: 20px;
         line-height: 20px;
      }
   }

   &_subtext {
      font-weight: 500;
      letter-spacing: 0.01em;
      text-align: left;
      color: $secondary-dark;

      font-size: vw(16px, 1920px);
      line-height: vw(19px, 1920px);

      a {
         color: $main;
         text-decoration: underline;
      }

      @media screen and (min-width: 1600px) {
         font-size: 16px;
         line-height: 19px;
      }
   }
}
