@import "../../../styles/helpers.scss";
@import "../../../styles/colors.scss";

.head {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: space-between;

   &__action {
      width: 200px;

      @media screen and (min-width: 1600px) {
         width: 200px;
      }
   }

   &__container {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: flex-start;
      justify-content: flex-start;

      gap: vw(12px, 1920px);

      @media screen and (min-width: 1600px) {
         gap: 12px;
      }
   }
}
