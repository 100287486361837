/* Primary */
$prim: #db7309;
$prim-light: #f99632;
$prim-dark: #c66807;

/* Secondary */
$sec: #5d9922;
$sec-light: #8fb966;
$sec-dark: #3c7405;

/* Extra */
$reg: #c93c1d;

/* Mono */
$black: #0e0f12;
$gray: #898b87;
$gray-x: #c3c3c3;
$gray-sx: #f8f8f8;
$white: #ffffff;




// Monochrome
$main-dark: #010101;
$secondary-dark: #808080;
$light: #e6e6e6;
$ultra-light: #fafafa;
$white: #ffffff;

// Colored
$main: #308a19;
$main-light: #bae860;
$main-gradient: linear-gradient(45deg, #2d9614 0%, #bae860 100%);
$secondary: #05705e;
$secondary-light: #35c4af;
$error: #ea3939;
$yellow: #ffe248;
