@import "../../styles/colors.scss";
@import "../../styles/helpers.scss";
@import "../../styles/mixins.scss";
@import "../../styles/effects.scss";

.signIn {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;

   padding-top: vw(64px, 320px);

   @media screen and (min-width: 526px) {
      padding: 0px vw(24px, 744px);
      height: calc(100vh - #{vw(75px, 744px)});
   }

   @media screen and (min-width: 921px) {
      gap: vw(32px);
      height: calc(100vh - #{vw(75px)});
   }

   @media screen and (min-width: 1440px) {
      gap: 32px;
      height: calc(100vh - 75px);
   }

   &__wrapper {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media screen and (min-width: 526px) {
         width: vw(380px, 744px);
      }

      @media screen and (min-width: 921px) {
         width: vw(380px);
      }

      @media screen and (min-width: 1440px) {
         width: 380px;
      }
   }

   &__iconWrapper {
      background: $white;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      @include shadow-bottom-low();
      
      width: vw(64px, 320px);
      height: vw(64px, 320px);
      border-radius: vw(32px, 320px);
      margin-bottom: vw(16px, 320px);

      @media screen and (min-width: 526px) {
         position: absolute;
         top: 0px;
         transform: translateY(-50%);
         margin: 0px;

         width: vw(96px, 744px);
         height: vw(96px, 744px);
         border-radius: vw(48px, 744px);
      }

      @media screen and (min-width: 921px) {
         width: vw(96px);
         height: vw(96px);
         border-radius: vw(48px);
      }

      @media screen and (min-width: 1440px) {
         width: 96px;
         height: 96px;
         border-radius: 48px;
      }
   }

   &__icon {
      width: vw(48px, 320px);
      height: vw(48px, 320px);

      @media screen and (min-width: 526px) {
         width: vw(64px, 744px);
         height: vw(64px, 744px);
      }

      @media screen and (min-width: 921px) {
         width: vw(64px);
         height: vw(64px);
      }

      @media screen and (min-width: 1440px) {
         width: 64px;
         height: 64px;
      }
   }

   &__title {
      @include head-3();
      
      margin-bottom: vw(16px, 320px);

      @media screen and (min-width: 526px) {
         margin-bottom: vw(16px, 744px);
      }

      @media screen and (min-width: 921px) {
         margin-bottom: vw(16px);
      }

      @media screen and (min-width: 1440px) {
         margin-bottom: 16px;
      }
   }

   &__text {
      @include text-regular();
      text-align: center;
      
      margin-bottom: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         margin-bottom: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         margin-bottom: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         margin-bottom: 24px;
      }
   }

   &__form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      gap: vw(12px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(32px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(32px);
      }

      @media screen and (min-width: 1440px) {
         gap: 32px;
      }

      &_pin {
         flex-direction: column;
      }
   }

   &__input {
      width: 100%;
   }

   &__button {
      width: 100%;

      @media screen and (min-width: 526px) {
         width: vw(240px, 744px);
      }

      @media screen and (min-width: 921px) {
         width: vw(240px);
      }

      @media screen and (min-width: 1440px) {
         width: 240px;
      }
   }

   &__link {
      @include sub-text();
      display: inline-block;
      margin: 0px auto;
      color: $sec;
      cursor: pointer;
      
      margin-top: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         margin-top: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         margin-top: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         margin-top: 24px;
      }
   }

   &__secondaryText {
      @include sub-text();
      color: $gray;
      width: 100%;
      text-align: center;
      
      margin-top: vw(16px, 320px);

      @media screen and (min-width: 526px) {
         margin-top: vw(12px, 744px);
      }

      @media screen and (min-width: 921px) {
         margin-top: vw(12px);
      }

      @media screen and (min-width: 1440px) {
         margin-top: 12px;
      }

      a {
         color: $sec;
      }
   }
}
