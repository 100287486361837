@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.dashboard {

    &__release {
        min-height: calc(100vh - 250px);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}