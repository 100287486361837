/* Focus */
@mixin focus-primary() {
   transition: box-shadow ease-in-out 0.3s;
   box-shadow: 0px 0px 0px vw(3px, 320px) rgba($color: #db7309, $alpha: 0.2);

   @media screen and (min-width: 526px) {
      box-shadow: 0px 0px 0px vw(3px, 744px) rgba($color: #db7309, $alpha: 0.2);
   }

   @media screen and (min-width: 921px) {
      box-shadow: 0px 0px 0px vw(3px) rgba($color: #db7309, $alpha: 0.2);
   }

   @media screen and (min-width: 1440px) {
      box-shadow: 0px 0px 0px 3px rgba($color: #db7309, $alpha: 0.2);
   }
}

@mixin focus-secondary() {
   transition: box-shadow ease-in-out 0.3s;
   box-shadow: 0px 0px 0px 3px rgba($color: #5d9922, $alpha: 0.2);

   @media screen and (min-width: 526px) {
      box-shadow: 0px 0px 0px vw(3px, 744px) rgba($color: #5d9922, $alpha: 0.2);
   }

   @media screen and (min-width: 921px) {
      box-shadow: 0px 0px 0px vw(3px) rgba($color: #5d9922, $alpha: 0.2);
   }

   @media screen and (min-width: 1440px) {
      box-shadow: 0px 0px 0px 3px rgba($color: #5d9922, $alpha: 0.2);
   }
}

/* Shadow */
@mixin shadow-bottom-element() {
   box-shadow: 0px 0px vw(4px, 320px) rgba($color: #000000, $alpha: 0.04),
      0px vw(4px, 320px) vw(8px, 320px) rgba($color: #000000, $alpha: 0.04);

   @media screen and (min-width: 526px) {
      box-shadow: 0px 0px vw(4px, 744px) rgba($color: #000000, $alpha: 0.04),
         0px vw(4px, 744px) vw(8px, 744px) rgba($color: #000000, $alpha: 0.04);
   }

   @media screen and (min-width: 921px) {
      box-shadow: 0px 0px vw(4px) rgba($color: #000000, $alpha: 0.04),
         0px vw(4px) vw(8px) rgba($color: #000000, $alpha: 0.04);
   }

   @media screen and (min-width: 1440px) {
      box-shadow: 0px 0px 4px rgba($color: #000000, $alpha: 0.04),
         0px 4px 8px rgba($color: #000000, $alpha: 0.04);
   }
}

@mixin shadow-bottom-window() {
   box-shadow: 0px 0px vw(4px, 320px) rgba($color: #000000, $alpha: 0.04),
      0px vw(8px, 320px) vw(16px, 320px) rgba($color: #000000, $alpha: 0.18);

   @media screen and (min-width: 526px) {
      box-shadow: 0px 0px vw(4px, 744px) rgba($color: #000000, $alpha: 0.04),
         0px vw(8px, 744px) vw(16px, 744px) rgba($color: #000000, $alpha: 0.18);
   }
   @media screen and (min-width: 921px) {
      box-shadow: 0px 0px vw(4px) rgba($color: #000000, $alpha: 0.04),
         0px vw(8px) vw(16px) rgba($color: #000000, $alpha: 0.18);
   }
   @media screen and (min-width: 1440px) {
      box-shadow: 0px 0px 4px rgba($color: #000000, $alpha: 0.04),
         0px 8px 16px rgba($color: #000000, $alpha: 0.18);
   }
}

@mixin shadow-bottom-low() {
   box-shadow: 0px 0px vw(6px, 320px) rgba($color: #000000, $alpha: 0.02),
      0px vw(2px, 320px) vw(4px, 320px) rgba($color: #000000, $alpha: 0.08);

   @media screen and (min-width: 526px) {
      box-shadow: 0px 0px vw(6px, 744px) rgba($color: #000000, $alpha: 0.02),
         0px vw(2px, 744px) vw(4px, 744px) rgba($color: #000000, $alpha: 0.08);
   }
   @media screen and (min-width: 921px) {
      box-shadow: 0px 0px vw(6px) rgba($color: #000000, $alpha: 0.02),
         0px vw(2px) vw(4px) rgba($color: #000000, $alpha: 0.08);
   }
   @media screen and (min-width: 1440px) {
      box-shadow: 0px 0px 6px rgba($color: #000000, $alpha: 0.02),
         0px 2px 4px rgba($color: #000000, $alpha: 0.08);
   }
}
