@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.header {
   display: flex;
   align-items: center;
   flex-wrap: nowrap;
   flex-direction: row;
   justify-content: space-between;

   background: $white;
   border-bottom: vw(1px, 1920px) solid $light;

   z-index: 1;

   @media screen and (min-width: 1600px) {
      border-bottom: 1px solid $light;
   }
}
