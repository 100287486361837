@import "../../../styles/colors.scss";
@import "../../../styles/effects.scss";
@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";

.threeDots {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: calc(100vw - #{vw(32px, 320px)});
    margin: 0px vw(16px, 320px);
    gap: vw(32px, 320px);
    padding: vw(32px, 320px) 0px;

    @media screen and (min-width: 526px) {
        width: calc(100vw - #{vw(48px, 744px)});
        margin: 0px vw(24px, 744px);
        gap: vw(32px, 744px);
        padding: vw(32px, 744px) 0px;
    }

    @media screen and (min-width: 921px) {
        width: vw(1200px);
        margin: 0px auto;
        gap: vw(32px);
        padding: vw(32px) 0px vw(48px);
    }

    @media screen and (min-width: 1440px) {
        width: 1200px;
        margin: 0px auto;
        gap: 32px;
        padding: 32px 0px 48px;
    }

    &__header {
        text-align: center;
        @include head-2();
    }

    &__wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;

        width: 100%;
    }

    &__card {
        display: flex;
        flex-direction: column;
        align-items: center;

        min-width: vw(288px, 320px);
        max-width: vw(288px, 320px);

        padding: vw(16px, 320px);
        height: vw(260px, 320px);
        gap: vw(12px, 320px);

        @media screen and (min-width: 526px) {
            min-width: vw(324px, 744px);
            max-width: vw(324px, 744px);

            padding: vw(20px, 744px);
            height: vw(260px, 744px);
            gap: vw(12px, 744px);

            &:last-child {
                margin: 0px auto;
            }
        }
    
        @media screen and (min-width: 921px) {
            min-width: vw(368px);
            max-width: vw(368px);

            padding: vw(20px);
            height: vw(260px);
            gap: vw(12px);

            &:last-child {
                margin: 0px;
            }
        }
    
        @media screen and (min-width: 1440px) {
            min-width: 368px;
            max-width: 368px;

            padding: 20px;
            height: 260px;
            gap: 12px;
        }
    }

    &__cardDot {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        @include head-4();
        @include focus-primary();
        background: $prim;
        color: $white;
        
        min-width: vw(42px, 320px);
        max-width: vw(42px, 320px);
        min-height: vw(42px, 320px);
        max-height: vw(42px, 320px);
        border-radius: vw(21px, 320px);
        margin-bottom: vw(8px, 320px);

        @media screen and (min-width: 526px) {
            min-width: vw(42px, 744px);
            max-width: vw(42px, 744px);
            min-height: vw(42px, 744px);
            max-height: vw(42px, 744px);
            border-radius: vw(21px, 744px);
            margin-bottom: vw(8px, 744px);
        }
    
        @media screen and (min-width: 921px) {
            min-width: vw(42px);
            max-width: vw(42px);
            min-height: vw(42px);
            max-height: vw(42px);
            border-radius: vw(21px);
            margin-bottom: vw(8px);
        }
    
        @media screen and (min-width: 1440px) {
            min-width: 42px;
            max-width: 42px;
            min-height: 42px;
            max-height: 42px;
            border-radius: 21px;
            margin-bottom: 8px;
        }

        &_secondary {
            @include focus-secondary();
            background: $sec;
        }
    }

    &__cardTitle {
        text-align: center;

        @include head-3();
    }

    &__cardText {
        text-align: center;

        @include text-regular();
    }
}