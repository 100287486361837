@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.container {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: space-between;

   width: 100%;
   height: auto;
}

.header {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: repeat(2, auto);
   row-gap: vw(8px, 1920px);

   @media screen and (min-width: 1600px) {
      row-gap: 8px;
   }

   &__title {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;
      gap: vw(6px, 1920px);

      font-weight: 400;
      letter-spacing: -0.01em;
      text-align: left;

      color: $main-dark;

      font-size: vw(20px, 1920px);
      line-height: vw(24px, 1920px);

      svg {
         width: vw(24px, 1920px);
         height: vw(24px, 1920px);

         cursor: pointer;

         @media screen and (min-width: 1600px) {
            width: 24px;
            height: 24px;
         }
      }

      @media screen and (min-width: 1600px) {
         font-size: 20px;
         line-height: 24px;
         gap: 6px;
      }
   }

   &__subtitle {
      font-weight: 600;
      letter-spacing: -0.01em;
      text-align: left;

      color: $main-dark;

      font-size: vw(32px, 1920px);
      line-height: vw(32px, 1920px);

      @media screen and (min-width: 1600px) {
         font-size: 32px;
         line-height: 32px;
      }
   }
}

.wait {
   background: $light;
   width: 40%;

   height: vw(24px, 1920px);
   border-radius: vw(4px, 1920px);

   @media screen and (min-width: 1600px) {
      height: 24px;
      border-radius: 4px;
   }

   
}

.cards {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: flex-end;
   gap: vw(22px, 1920px);

   @media screen and (min-width: 1600px) {
      gap: 22px;
   }
}
