@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.loan {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: repeat(3, auto);
   row-gap: vw(64px, 1920px);

   @media screen and (min-width: 1600px) {
      row-gap: 64px;
   }

   &__list {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      gap: vw(12px, 1920px);

      @media screen and (min-width: 1600px) {
         gap: 12px;
      }
   }

   &__container {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: center;
      width: 100%;
   }
}

.confirm {
   display: inline-block;
   font-weight: 400;
   letter-spacing: -0.01em;
   text-align: left;

   font-size: vw(20px, 1920px);
   line-height: vw(24px, 1920px);

   @media screen and (min-width: 1600px) {
      font-size: 20px;
      line-height: 24px;
   }

   &__bank {
      display: inline-block;
      background-color: $yellow;
      border-radius: vw(4px, 1920px);
      padding: 0px vw(6px, 1920px);

      @media screen and (min-width: 1600px) {
         border-radius: 4px;
         padding: 0px 6px;
      }
   }

   &__tel {
      font-weight: 500;
   }

   &__button {
      width: min-content;
   }
}

.table__action {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: space-between;

   gap: vw(8px, 1920px);

   width: 100%;
   height: 100%;

   @media screen and (min-width: 1600px) {
      gap: 8px;
   }
}
.table__button {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: center;

   background-color: $ultra-light;
   border: none;
   border-radius: vw(10px, 1920px);
   width: vw(50px, 1920px);
   height: vw(50px, 1920px);
   cursor: pointer;

   &_disabled {
      cursor: not-allowed;
      svg {
         path {
            fill: $light;
         }
      }
   }

   svg {
      width: vw(24px, 1920px);
      height: vw(24px, 1920px);

      @media screen and (min-width: 1600px) {
         width: 24px;
         height: 24px;
      }
   }

   @media screen and (min-width: 1600px) {
      border-radius: 10px;
      width: 50px;
      height: 50px;
   }
}
.table__button_green {
   svg {
      path {
         fill: $main;
      }
   }
}
.table__button_red {
   svg {
      path {
         fill: $error;
      }
   }
}

.buttons {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: space-between;
}

.attantion {
   font-weight: 400;
   letter-spacing: -0.01em;
   text-align: left;

   font-size: vw(18px, 1920px);
   line-height: vw(22px, 1920px);

   @media screen and (min-width: 1600px) {
      font-size: 18px;
      line-height: 22px;
   }

   &_small {
      font-weight: 500;
      letter-spacing: -0.01em;
      text-align: left;

      font-size: vw(16px, 1920px);
      line-height: vw(17px, 1920px);

      @media screen and (min-width: 1600px) {
         font-size: 16px;
         line-height: 17px;
      }
   }
}

.wrapper {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   justify-content: space-between;
   height: 100%;

   &__accept {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      height: 100%;
      gap: vw(24px, 1920px);
      @media screen and (min-width: 1600px) {
         gap: 24px;
      }
   }
}
