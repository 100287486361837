@import "../../../styles/colors.scss";
@import "../../../styles/effects.scss";
@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";

.LoanStoryList,
.LoanStoryList__heading {
   display: flex;
   flex-direction: column;
}

.LoanStoryList__Item,
.LoanStoryList__wrapper {
   display: flex;
   flex-direction: row;
}

.LoanStoryList {
   gap: vw(12px, 320px);

   @media screen and (min-width: 526px) {
      gap: vw(12px, 744px);
   }
   @media screen and (min-width: 921px) {
      gap: vw(12px);
   }
   @media screen and (min-width: 1440px) {
      gap: 12px;
   }
   &__Item {
      position: relative;
      align-items: flex-start;
      justify-content: space-between;
   }
   &__wrapper {
      gap: vw(12px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(12px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(12px);
      }
      @media screen and (min-width: 1440px) {
         gap: 12px;
      }
   }
   &__icon {
      min-width: vw(24px, 320px);
      min-height: vw(24px, 320px);
      max-width: vw(24px, 320px);
      max-height: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         min-width: vw(32px, 744px);
         min-height: vw(32px, 744px);
         max-width: vw(32px, 744px);
         max-height: vw(32px, 744px);
      }
      @media screen and (min-width: 921px) {
         min-width: vw(32px);
         min-height: vw(32px);
         max-width: vw(32px);
         max-height: vw(32px);
      }
      @media screen and (min-width: 1440px) {
         min-width: 32px;
         min-height: 32px;
         max-width: 32px;
         max-height: 32px;
      }
   }
   &__heading {
      gap: vw(4px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(4px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(4px);
      }
      @media screen and (min-width: 1440px) {
         gap: 4px;
      }
   }
   &__title {
      @include text-medium();
      color: $black;
   }
   &__summ {
      @include text-regular();
      color: $black;
   }
   &__data {
      @include sub-text();
      color: $gray;
   }
   &__link {
      @include sub-text();
      color: $gray;
      position: absolute;
      top: 0px;
      left: 0px;
      right: 0px;
      bottom: 0px;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: flex-end;
      & > span {
         @include sub-text();
         color: $gray;
         transition: color ease-in-out 0.3s;
      }

      &:hover > span {
         color: $sec;
      }
   }

   &__subtitle {
      width: 100%;
      @include sub-text();
      text-align: center;
   }
}
