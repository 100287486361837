@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.floatingTools {
    position: absolute;
    top: 0px;
    right: 0px;
    opacity: 0.5;
    transition: opacity 0.3s ease-in-out;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    gap: 12px;
    padding: 12px;

    &:hover {
        opacity: 1;
    }

    &__button {
        border: solid $light;
        background: $ultra-light;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        transition: background 0.3s ease-in-out;
        cursor: pointer;

        border-width: 2px;
        border-radius: 8px;
        width: 32px;
        height: 32px;

        &:hover {
            background: $light;
        }

        & > svg {
            width: 24px;
            height: 24px;

            & > * {
                fill: $main-dark;
            }
        }
    }
}