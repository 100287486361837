@import "../../../../styles/colors.scss";
@import "../../../../styles/helpers.scss";
@import "../../../../styles/mixins.scss";
@import "../../../../styles/effects.scss";

.creditDoctor {
    display: flex;
    flex-direction: column;
    position: relative;

    @include shadow-bottom-element();
    
    padding: vw(20px, 320px) vw(12px, 320px) vw(12px, 320px) vw(12px, 320px);
    border-radius: vw(12px, 320px);
    margin-bottom: vw(12px, 320px);

    @media screen and (min-width: 526px) {
        padding: vw(20px, 744px) vw(12px, 744px) vw(12px, 744px) vw(12px, 744px);
        border-radius: vw(12px, 744px);
        margin-bottom: vw(12px, 744px);
    }

    @media screen and (min-width: 921px) {
        padding: vw(20px) vw(12px) vw(12px) vw(12px);
        border-radius: vw(12px);
        margin-bottom: vw(12px);
    }

    @media screen and (min-width: 1440px) {
        padding: 20px 12px 12px 12px;
        border-radius: 12px;
        margin-bottom: 12px;
    }

    & picture {
        position: absolute;
        z-index: -1;
        bottom: 0px;

        right: vw(3px, 320px);

        @media screen and (min-width: 526px) {
            right: vw(3px, 744px);
        }

        @media screen and (min-width: 921px) {
            right: vw(3px);
        }

        @media screen and (min-width: 1440px) {
            right: 3px;
        }
    }

    &__img {
        vertical-align: bottom;
        object-fit: contain;
        object-position: center bottom;

        width: vw(180px, 320px);
        height: 90%;

        @media screen and (min-width: 526px) {
            width: vw(180px, 744px);
            height: vw(168px, 744px);
        }

        @media screen and (min-width: 921px) {
            width: vw(180px);
            height: vw(168px);
        }

        @media screen and (min-width: 1440px) {
            width: 180px;
            height: 168px;
        }
    }

    &__supTitle {
        color: $black;
        @include sub-text();
        
        margin-bottom: vw(4px, 320px);

        @media screen and (min-width: 526px) {
            margin-bottom: vw(4px, 744px);
        }

        @media screen and (min-width: 921px) {
            margin-bottom: vw(4px);
        }

        @media screen and (min-width: 1440px) {
            margin-bottom: 4px;
        }
    }

    &__title {
        color: $black;
        @include head-5();
        line-height: 100%;
        
        margin-bottom: vw(24px, 320px);

        @media screen and (min-width: 526px) {
            margin-bottom: vw(24px, 744px);
        }

        @media screen and (min-width: 921px) {
            margin-bottom: vw(24px);
        }

        @media screen and (min-width: 1440px) {
            margin-bottom: 24px;
        }
    }

    &__button {
        display: flex;
        width: min-content;

        gap: vw(8px, 320px);
        margin-bottom: vw(8px, 320px);

        @media screen and (min-width: 526px) {
            gap: vw(8px, 744px);
            margin-bottom: vw(8px, 744px);
        }

        @media screen and (min-width: 921px) {
            gap: vw(8px);
            margin-bottom: vw(8px);
        }

        @media screen and (min-width: 1440px) {
            gap: 8px;
            margin-bottom: 8px;
        }
    }

    &__icon {
        min-width: vw(24px, 320px);
        max-width: vw(24px, 320px);
        min-height: vw(24px, 320px);
        max-height: vw(24px, 320px);

        @media screen and (min-width: 526px) {
            min-width: vw(24px, 744px);
            max-width: vw(24px, 744px);
            min-height: vw(24px, 744px);
            max-height: vw(24px, 744px);
        }

        @media screen and (min-width: 921px) {
            min-width: vw(24px);
            max-width: vw(24px);
            min-height: vw(24px);
            max-height: vw(24px);
        }

        @media screen and (min-width: 1440px) {
            min-width: 24px;
            max-width: 24px;
            min-height: 24px;
            max-height: 24px;
        }
    }

    &__link {
        @include sup-text();
        opacity: 0.5;
        color: $sec-dark;
        
        margin-left: vw(18px, 320px);

        @media screen and (min-width: 526px) {
            margin-left: vw(40px, 744px);
        }

        @media screen and (min-width: 921px) {
            margin-left: vw(40px);
        }

        @media screen and (min-width: 1440px) {
            margin-left: 40px;
        }
    }
}