@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.servicesCard {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: auto;
   padding: vw(32px, 320px) vw(16px, 320px);

   @media screen and (min-width: 526px) {
      padding: vw(32px, 744px) vw(24px, 744px);
   }

   @media screen and (min-width: 921px) {
      padding: vw(64px) 0px;
      width: vw(1200px);
      margin: 0px auto;
   }

   @media screen and (min-width: 1440px) {
      padding: 64px 0px;
      max-width: 1200px;
   }

   &__wrapper {
      display: grid;
      grid-template-columns: 1fr;
      grid-template-rows: repeat(2, auto);
      row-gap: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         grid-template-columns: repeat(2, 1fr);
         grid-template-rows: 1fr;
         row-gap: 0px;
         column-gap: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         column-gap: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         column-gap: 24px;
      }
   }
}

.card {
   &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      background-color: $gray-sx;

      padding: vw(16px, 320px);
      gap: vw(20px, 320px);
      border-radius: vw(20px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(20px, 744px);
         border-radius: vw(20px, 744px);
         padding: vw(16px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(20px);
         border-radius: vw(20px);
         padding: vw(16px);
      }

      @media screen and (min-width: 1440px) {
         gap: 20px;
         border-radius: 20px;
         padding: 16px;
      }
   }

   &__icon {
      width: vw(48px, 320px);
      height: vw(48px, 320px);

      @media screen and (min-width: 526px) {
         width: vw(64px, 744px);
         height: vw(64px, 744px);
      }
      @media screen and (min-width: 921px) {
         width: vw(64px);
         height: vw(64px);
      }
      @media screen and (min-width: 1440px) {
         width: 64px;
         height: 64px;
      }
   }

   &__title {
      @include head-3();
      color: $black;
   }

   &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      gap: vw(20px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(20px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(20px);
      }

      @media screen and (min-width: 1400px) {
         gap: 20px;
      }
   }

   &__content > * {
      @include text-regular();
      color: $black;
   }
}
