@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.login {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;

        gap: vw(26px, 1920px);
        width: vw(600px, 1920px);
        
        @media screen and (min-width: 1600px) {
            gap: 26px;
            width: 600px;
        }
    }

    &__logo {
        width: vw(257.68px, 1920px);
        height: vw(32px, 1920px);
        margin-bottom: vw(38px, 1920px);
        
        @media screen and (min-width: 1600px) {
            width: 257.68px;
            height: 32px;
            margin-bottom: 38px;
        }
    }

    &__form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        gap: vw(48px, 1920px);

        @media screen and (min-width: 1600px) {
            gap: 48px;
        }
    }

    &__input {
        
    }

    &__button {
        width: vw(240px, 1920px);

        @media screen and (min-width: 1600px) {
            width: 240px;
        }
    }

    &__link {
        color: $main;
        font-weight: 500;

        font-size: vw(16px, 1920px);
        line-height: vw(16px, 1920px);

        @media screen and (min-width: 1600px) {
            font-size: 16px;
            line-height: 16px;
        }
    }
}