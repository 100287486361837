@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";
@import "../../../styles/effects.scss";

.blockWrapper {
    background: $white;

    padding: 0px vw(16px, 320px);

    @media screen and (min-width: 526px) {
        padding: vw(70px, 744px) vw(24px, 744px) vw(24px, 744px);
        border-radius: vw(24px, 744px);
        @include shadow-bottom-window();
    }

    @media screen and (min-width: 921px) {
        padding: vw(70px) vw(24px) vw(24px);
        border-radius: vw(24px, 1920px);
    }

    @media screen and (min-width: 1440px) {
        padding: 70px 24px 24px;
        border-radius: 24px;
    }
}