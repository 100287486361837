@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";
@import "../../../styles/effects.scss";
@import "../../../styles/mixins.scss";

.dropDownList {
   display: flex;
   flex-direction: column;
   position: relative;
   width: 100%;

   gap: vw(4px, 320px);

   @media screen and (min-width: 526px) {
      gap: vw(4px, 744px);
   }

   @media screen and (min-width: 921px) {
      gap: vw(4px);
   }

   @media screen and (min-width: 1440px) {
      gap: 4px;
   }

   &__label {
      position: absolute;
      background: $white;
      @include sub-text();
      transform: translateY(-50%);
      color: $gray;

      left: vw(16px, 320px);
      padding: 0px vw(2px, 320px);

      @media screen and (min-width: 526px) {
         left: vw(16px, 744px);
         padding: 0px vw(2px, 744px);
      }

      @media screen and (min-width: 921px) {
         left: vw(16px);
         padding: 0px vw(2px);
      }

      @media screen and (min-width: 1440px) {
         left: 16px;
         padding: 0px 2px;
      }
   }

   &__native {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 1;

      @media screen and (min-width: 921px) {
         display: none;
      }
   }

   &__input {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      background: $white;
      border: solid $light;
      cursor: pointer;

      @include text-regular();

      height: vw(40px, 320px);
      border-radius: vw(10px, 320px);
      padding: 0px vw(10px, 320px) 0px vw(18px, 320px);
      border-width: vw(1px, 320px);

      @media screen and (min-width: 526px) {
         height: vw(42px, 744px);
         border-radius: vw(12px, 744px);
         padding: 0px vw(10px, 744px) 0px vw(18px, 744px);
         border-width: vw(1px, 744px);
      }

      @media screen and (min-width: 921px) {
         height: vw(42px);
         border-radius: vw(12px);
         padding: 0px vw(10px) 0px vw(18px);
         border-width: vw(1px);
      }

      @media screen and (min-width: 1440px) {
         height: 42px;
         border-radius: 12px;
         padding: 0px 10px 0px 18px;
         border-width: 1px;
      }
   }

   &__icon {
      transform: rotate(90deg);

      width: vw(24px, 320px);
      height: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         width: vw(24px, 744px);
         height: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         width: vw(24px);
         height: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         width: 24px;
         height: 24px;
      }
   }

   &__list {
      position: absolute;
      z-index: 5;
      background: $white;
      display: none;
      flex-direction: column;
      align-items: stretch;
      width: 100%;
      max-width: unset;
      overflow-y: auto;

      @media screen and (min-width: 526px) {
      }

      @media screen and (min-width: 921px) {
         @include shadow-bottom-low();
         max-height: vw(500px);
         display: flex;
         padding: vw(8px);
         gap: vw(4px);
         border-radius: vw(12px);
         top: vw(46px);
      }

      @media screen and (min-width: 1440px) {
         max-height: 500px;
         padding: 8px;
         gap: 4px;
         border-radius: 12px;
         top: 46px;
      }
   }

   &__item {
      justify-content: flex-start;

      min-height: vw(40px, 320px);
      padding: 0px vw(16px, 320px);
      border-radius: vw(4px, 320px);

      @media screen and (min-width: 526px) {
         min-height: vw(42px, 744px);
         padding: 0px vw(16px, 744px);
         border-radius: vw(4px, 744px);
      }

      @media screen and (min-width: 921px) {
         min-height: vw(42px);
         padding: 0px vw(16px);
         border-radius: vw(4px);
      }

      @media screen and (min-width: 1440px) {
         min-height: 42px;
         padding: 0px 16px;
         border-radius: 4px;
      }
   }
}
