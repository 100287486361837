@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.accordion {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   align-items: flex-start;
   justify-content: flex-start;
   background: $white;

   gap: vw(10px, 1920px);
   padding: vw(32px, 1920px) vw(64px, 1920px);
   border: vw(1px, 1920px) solid $light;
   border-radius: vw(30px, 1920px);

   @media screen and (min-width: 1600px) {
      gap: 10px;
      padding: 32px 64px;
      border: 1px solid $light;
      border-radius: 30px;
   }
}
