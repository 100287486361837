@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.notice {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: flex-start;
   width: 100%;

   min-height: vw(52px, 1920px);
   padding: vw(15px, 1920px);
   gap: vw(15px, 1920px);
   border: vw(1px, 1920px) solid $error;
   border-radius: vw(15px, 1920px);

   @media screen and (min-width: 1600px) {
      min-height: 52px;
      padding: 15px;
      gap: 15px;
      border: 1px solid $error;
      border-radius: 15px;
   }

   &_yellow {
      border-color: $yellow;
   }

   &_green {
      border-color: $main;
   }

   &_full {
      display: grid;
      grid-template-columns: vw(32px, 1920px) 1fr;
      grid-template-rows: 1fr vw(60px, 1920px);
      column-gap: vw(15px, 1920px);
      row-gap: vw(15px, 1920px);
      border-radius: vw(32px, 1920px);
      justify-items: start;
      align-items: start;

      @media screen and (min-width: 1600px) {
         grid-template-columns: 32px 1fr;
         grid-template-rows: 1fr 60px;
         column-gap: 15px;
         row-gap: 15px;
         border-radius: 32px;
      }
   }

   &__icon {
      min-width: vw(32px, 1920px);
      min-height: vw(32px, 1920px);

      &_full {
         grid-area: 1 / 1 / 2 / 2;
      }

      @media screen and (min-width: 1600px) {
         min-width: 32px;
         min-height: 32px;
      }
   }
   &__text {
      font-weight: 400;
      letter-spacing: -0.01em;
      text-align: left;

      font-size: vw(20px, 1920px);
      line-height: vw(24px, 1920px);

      &_full {
         grid-area: 1 / 2 / 2 / 3;
      }

      @media screen and (min-width: 1600px) {
         font-size: 20px;
         line-height: 24px;
      }
   }

   &__button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: $error;
      color: $white;
      border: none;
      cursor: pointer;
      font-weight: 500;
      letter-spacing: 0em;
      text-align: center;

      padding: 0px vw(30px, 1920px);
      min-width: vw(256px, 1920px);
      min-height: vw(48px, 1920px);
      border-radius: vw(8px, 1920px);
      font-size: vw(18px, 1920px);
      line-height: vw(18px, 1920px);

      margin-left: auto;

      &_yellow {
         background: $yellow;
         color: $main-dark;
      }

      &_green {
         background: $main;
         color: $white;
      }

      &_full {
         grid-area: 2 / 2 / 3 / 3;
         margin-left: 0px;
      }

      @media screen and (min-width: 1600px) {
         padding: 0px 30px;
         min-width: 256px;
         min-height: 48px;
         border-radius: 8px;
         font-size: 18px;
         line-height: 18px;
      }
   }
}
