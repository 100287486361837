@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";

.heading_content_page,
.heading_content_page__wrapper {
   display: flex;
   flex-direction: column;
   align-items: flex-start;
}

.heading_content_page {
   padding: vw(24px, 320px) vw(16px, 320px);
   gap: vw(24px, 320px);
   width: 100%;
   height: auto;

   background: $gray-sx;

   @media screen and (min-width: 526px) {
      padding: vw(24px, 744px);
      gap: vw(24px, 744px);
      height: vw(250px, 744px);
   }
   @media screen and (min-width: 921px) {
      padding: vw(24px);
      gap: vw(24px);
      height: vw(250px);
   }
   @media screen and (min-width: 1440px) {
      padding: 24px;
      gap: 24px;
      height: 250px;
   }

   &__wrapper {
      margin: 0px auto;
      gap: vw(12px, 744px);
      width: 100%;

      @media screen and (min-width: 526px) {
         gap: vw(12px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(12px);
         width: vw(1200px);
      }
      @media screen and (min-width: 1440px) {
         gap: 12px;
         width: 1200px;
      }
   }

   &__title,
   &__text {
      transition: opacity ease-in-out 0.3s;
      opacity: 1;
   }

   &__title_hide,
   &__text_hide {
      opacity: 0;
   }

   &__title {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @include head-2();
      color: $black;
   }

   &__text {
      display: -webkit-box;
      -webkit-line-clamp: 8;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @include text-regular();
      color: $black;
      max-width: none;

      @media screen and (min-width: 526px) {
         max-width: vw(680px, 744px);
      }
      @media screen and (min-width: 921px) {
         max-width: vw(680px);
      }
      @media screen and (min-width: 1440px) {
         max-width: 680px;
      }
   }
}
