@import "../../../styles/helpers.scss";
@import "../../../styles/effects.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.operationsHistoryItem {
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-end;
   padding: vw(16px, 320px) vw(8px, 320px);

   @media screen and (min-width: 526px) {
      flex-direction: row;
      justify-content: space-between;
      padding: 0px;
   }

   &__body {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      width: 100%;
      gap: vw(10px, 320px);
      @media screen and (min-width: 526px) {
         gap: vw(10px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(10px);
      }
      @media screen and (min-width: 1440px) {
         gap: 10px;
      }
   }

   &__heading,
   &__info {
      display: flex;
      flex-direction: column;
      gap: vw(6px, 320px);
      @media screen and (min-width: 526px) {
         gap: vw(6px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(6px);
      }
      @media screen and (min-width: 1440px) {
         gap: 6px;
      }
   }
   &__info {
      flex-direction: row;
      justify-content: space-between;
      width: vw(233px, 320px);
      margin-top: vw(10px, 320px);

      @media screen and (min-width: 526px) {
         flex-direction: column;
         width: auto;
         margin: 0px;
      }
   }
   &__title,
   &__text {
      line-height: 100%;
      color: $black;
   }
   &__title {
      @include text-medium();
      white-space: nowrap;
   }
   &__text {
      @include sub-text();

      &__summ {
         @include text-medium();
         color: $sec;
         &_false {
            color: $prim;
         }
      }
   }

   &__info {
      align-items: flex-end;
   }

   &__icon {
      min-width: vw(28px, 320px);
      min-height: vw(28px, 320px);
      max-width: vw(28px, 320px);
      max-height: vw(28px, 320px);

      @media screen and (min-width: 526px) {
         min-width: vw(34px, 744px);
         min-height: vw(34px, 744px);
         max-width: vw(34px, 744px);
         max-height: vw(34px, 744px);
      }
      @media screen and (min-width: 921px) {
         min-width: vw(34px);
         min-height: vw(34px);
         max-width: vw(34px);
         max-height: vw(34px);
      }
      @media screen and (min-width: 1440px) {
         min-width: 34px;
         min-height: 34px;
         max-width: 34px;
         max-height: 34px;
      }
   }
}
