@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.alert {
   display: flex;
   flex-direction: row;
   background-color: $gray-sx;
   width: 100%;
   gap: vw(12px, 320px);
   border-radius: vw(12px, 320px);
   padding: vw(16px, 320px) vw(12px, 320px);

   @media screen and (min-width: 526px) {
      gap: vw(24px, 744px);
      border-radius: vw(12px, 744px);
      padding: vw(24px, 744px) vw(20px, 744px);
   }

   @media screen and (min-width: 921px) {
      gap: vw(24px);
      border-radius: vw(12px);
      padding: vw(24px) vw(20px);
   }

   @media screen and (min-width: 1440px) {
      gap: 24px;
      border-radius: 12px;
      padding: 24px 20px;
   }

   &__icon {
      max-width: vw(32px, 320px);
      min-width: vw(32px, 320px);
      max-height: vw(32px, 320px);
      min-height: vw(32px, 320px);

      @media screen and (min-width: 526px) {
         max-width: vw(32px, 744px);
         min-width: vw(32px, 744px);
         max-height: vw(32px, 744px);
         min-height: vw(32px, 744px);
      }
      @media screen and (min-width: 921px) {
         max-width: vw(32px);
         min-width: vw(32px);
         max-height: vw(32px);
         min-height: vw(32px);
      }
      @media screen and (min-width: 1440px) {
         max-width: 32px;
         min-width: 32px;
         max-height: 32px;
         min-height: 32px;
      }
   }
   &__container {
      display: flex;
      flex-direction: column;
      gap: vw(8px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(8px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(8px);
      }

      @media screen and (min-width: 1440px) {
         gap: 8px;
      }
   }

   &__title,
   &__text {
      color: $black;
   }

   &__title {
      @include head-4();
   }
   &__text {
      @include text-regular();
   }
}
