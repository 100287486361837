@import "../../../styles/helpers.scss";
@import "../../../styles/effects.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.listCards {
   display: flex;
   flex-direction: column;
   gap: vw(1px, 320px);

   @media screen and (min-width: 526px) {
      gap: vw(1px, 744px);
   }
   @media screen and (min-width: 921px) {
      gap: vw(1px);
   }
   @media screen and (min-width: 1440px) {
      gap: 1px;
   }

   &__addButton,
   &__promo {
      padding: vw(8px, 320px) vw(11px, 320px);
      min-height: vw(64px, 320px);

      @media screen and (min-width: 526px) {
         padding: vw(8px, 744px) vw(11px, 744px);
         min-height: vw(84px, 744px);
      }
      @media screen and (min-width: 921px) {
         padding: vw(8px) vw(11px);
         min-height: vw(84px);
      }
      @media screen and (min-width: 1440px) {
         padding: 8px 11px;
         min-height: 84px;
      }
   }

   &__addButton {
      justify-content: flex-start;
      box-shadow: none;
      transition: background-color ease-in-out 0.3s;
      padding: vw(16px, 320px) vw(8px, 320px);

      &:focus,
      &:active,
      &:hover {
         box-shadow: none;
         background-color: $gray-sx;
      }

      &:focus > *,
      &:active > *,
      &:hover > * {
         color: $black !important;
      }

      @media screen and (min-width: 526px) {
         padding: vw(16px, 744px) vw(8px, 744px);
      }
      @media screen and (min-width: 921px) {
         padding: vw(16px) vw(8px);
      }
      @media screen and (min-width: 1440px) {
         padding: 16px 8px;
      }
   }

   &__promo {
      position: relative;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      transition: box-shadow ease-in-out 0.3s;
      align-items: center;
      width: 100%;
      background-color: $sec;
      gap: vw(10px, 320px);
      border-radius: vw(12px, 320px);
      box-shadow: none;

      @media screen and (min-width: 526px) {
         gap: vw(10px, 744px);
         border-radius: vw(12px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(10px);
         border-radius: vw(12px);
      }
      @media screen and (min-width: 1440px) {
         gap: 10px;
         border-radius: 12px;
      }

      &:hover,
      &:focus,
      &:active {
         @include shadow-bottom-element();
      }

      &__body {
         z-index: 2;
         display: flex;
         flex-direction: column;
         width: 100%;
         gap: vw(6px, 320px);

         @media screen and (min-width: 526px) {
            gap: vw(6px, 744px);
         }
         @media screen and (min-width: 921px) {
            gap: vw(6px);
         }
         @media screen and (min-width: 1440px) {
            gap: 6px;
         }
      }

      &__sale {
         position: absolute;
         right: 0px;
         top: 50%;
         transform: translateY(-50%);
         width: 100%;
         height: 100%;

         background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' width='32' height='32' viewBox='0 0 32 32' %3E%3Cpath fill='%233C7405' d='M13.122 4.6c-.21.19-.43.367-.66.532a3.61 3.61 0 0 1-1.313.544c-.204.04-.417.058-.843.091-1.068.085-1.602.128-2.048.285A3.617 3.617 0 0 0 6.052 8.26c-.158.445-.2.98-.286 2.048a7.61 7.61 0 0 1-.09.843 3.646 3.646 0 0 1-.544 1.313c-.116.173-.255.336-.532.66-.695.816-1.043 1.224-1.247 1.65a3.624 3.624 0 0 0 0 3.12c.204.427.552.835 1.247 1.651.277.324.416.487.532.66.266.398.45.844.544 1.314.04.204.057.417.09.842.086 1.068.128 1.603.286 2.048a3.618 3.618 0 0 0 2.206 2.207c.446.157.98.2 2.048.285.426.034.639.051.843.091.47.093.916.279 1.313.544.174.116.336.255.66.532.816.695 1.224 1.043 1.651 1.247.987.47 2.133.47 3.12 0 .427-.204.835-.552 1.65-1.247.325-.277.487-.416.66-.532a3.64 3.64 0 0 1 1.314-.544c.204-.04.417-.057.843-.09 1.068-.086 1.602-.129 2.048-.286a3.618 3.618 0 0 0 2.206-2.207c.158-.445.2-.98.286-2.048.033-.425.05-.638.09-.842a3.65 3.65 0 0 1 .544-1.314c.116-.173.255-.336.532-.66.695-.816 1.043-1.224 1.247-1.65.47-.987.47-2.134 0-3.12-.204-.427-.552-.835-1.247-1.651-.19-.21-.367-.43-.532-.66a3.618 3.618 0 0 1-.544-1.313 7.611 7.611 0 0 1-.09-.843c-.086-1.068-.128-1.603-.286-2.048a3.617 3.617 0 0 0-2.206-2.207c-.446-.157-.98-.2-2.048-.285a7.61 7.61 0 0 1-.843-.09 3.618 3.618 0 0 1-1.313-.545 7.64 7.64 0 0 1-.66-.532c-.816-.694-1.224-1.042-1.651-1.246a3.617 3.617 0 0 0-3.12 0c-.427.204-.835.552-1.65 1.246z' opacity='0.2' /%3E%3Cpath fill='%233C7405' d='M21.44 11.227a1.085 1.085 0 0 1 0 1.535l-8.68 8.68a1.085 1.085 0 0 1-1.535-1.534l8.68-8.68a1.086 1.086 0 0 1 1.536 0h-.001zm-.043 8.726a1.447 1.447 0 1 1-2.893 0 1.447 1.447 0 0 1 2.893 0zm-8.681-5.788a1.447 1.447 0 1 0 0-2.894 1.447 1.447 0 0 0 0 2.893z' /%3E%3C/svg%3E");
         background-repeat: no-repeat;
         background-position: top 50% right -20%;
         background-clip: content-box;

         background-size: vw(120px, 320px) vw(120px, 320px);
         border-radius: vw(12px, 320px);

         @media screen and (min-width: 526px) {
            background-size: vw(166px, 744px) vw(166px, 744px);
            border-radius: vw(12px, 744px);
         }
         @media screen and (min-width: 921px) {
            background-size: vw(166px) vw(166px);
            border-radius: vw(12px);
         }
         @media screen and (min-width: 1440px) {
            background-size: 166px 166px;
            border-radius: 12px;
         }
      }

      &__icon {
         min-width: vw(32px, 320px);
         min-height: vw(32px, 320px);
         max-width: vw(32px, 320px);
         max-height: vw(32px, 320px);

         @media screen and (min-width: 526px) {
            min-width: vw(32px, 744px);
            min-height: vw(32px, 744px);
            max-width: vw(32px, 744px);
            max-height: vw(32px, 744px);
         }
         @media screen and (min-width: 921px) {
            min-width: vw(32px);
            min-height: vw(32px);
            max-width: vw(32px);
            max-height: vw(32px);
         }
         @media screen and (min-width: 1440px) {
            min-width: 32px;
            min-height: 32px;
            max-width: 32px;
            max-height: 32px;
         }
      }

      &__title,
      &__text {
         color: $white;
      }

      &__title {
         @include text-medium();
      }

      &__text {
         @include sub-text();
      }
   }
}
