@import "../../styles/helpers.scss";
@import "../../styles/effects.scss";
@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.promo,
.promo__actions {
   display: flex;
   flex-direction: row;
}

.promo {
   display: flex;
   flex-direction: row;
   align-items: center;
   justify-content: center;
   padding: vw(100px, 320px) vw(16px, 320px);

   @media screen and (min-width: 526px) {
      padding: vw(200px, 744px) vw(24px, 744px);
   }
   @media screen and (min-width: 921px) {
      align-items: center;
      justify-content: space-between;
      padding: vw(32px) 0px;
   }
   @media screen and (min-width: 1440px) {
      padding: 32px 0px;
   }

   &__content,
   &__heading {
      display: flex;
      flex-direction: column;
   }

   &__content {
      gap: vw(32px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(32px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(32px);
      }
      @media screen and (min-width: 1440px) {
         gap: 32px;
      }
   }

   &__heading {
      gap: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(24px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(24px);
      }
      @media screen and (min-width: 1440px) {
         gap: 24px;
      }
   }

   &__title,
   &__text {
      color: $black;
   }

   &__title {
      @include head-1();
      text-align: center;
      @media screen and (min-width: 921px) {
         text-align: left;
      }
   }

   &__text {
      @include head-4();
      text-align: center;
      @media screen and (min-width: 921px) {
         text-align: left;
      }
   }

   &__actions {
      gap: vw(24px, 320px);
      flex-direction: column;
      align-items: center;

      @media screen and (min-width: 526px) {
         flex-direction: column;
         gap: vw(24px, 744px);
      }
      @media screen and (min-width: 921px) {
         flex-direction: row;
         align-items: flex-start;
         gap: vw(24px);
      }
      @media screen and (min-width: 1440px) {
         gap: 24px;
      }
   }

   &__link {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      transition: background-color ease-in-out 0.3s;
      background-color: $sec;
      color: $white;

      @include text-medium();

      width: 100%;
      padding: vw(12px, 320px) vw(18px, 320px);
      border-radius: vw(12px, 320px);
      min-width: vw(242px, 320px);
      height: vw(42px, 320px);

      &:hover {
         background-color: $sec-light;
      }

      &:active {
         background-color: $sec-dark;
      }

      &:focus,
      &:focus-visible {
         outline: none;
      }

      &:focus-visible {
         @include focus-secondary();
      }

      &_gray {
         color: $black;
         background-color: $gray-sx;

         &:hover {
            background-color: $gray-x;
         }

         &:active {
            background-color: $gray;
         }
      }

      @media screen and (min-width: 526px) {
         width: min-content;
         padding: vw(12px, 744px) vw(18px, 744px);
         border-radius: vw(12px, 744px);
         min-width: vw(242px, 744px);
         height: vw(42px, 744px);
      }
      @media screen and (min-width: 921px) {
         padding: vw(12px) vw(18px);
         border-radius: vw(12px);
         min-width: vw(242px);
         height: vw(42px);
      }
      @media screen and (min-width: 1440px) {
         padding: 12px 18px;
         border-radius: 12px;
         min-width: 242px;
         height: 42px;
      }
   }

   &__img {
      display: none;
      @media screen and (min-width: 921px) {
         display: block;
         width: vw(621px);
         height: vw(582px);
      }
      @media screen and (min-width: 1440px) {
         width: 621px;
         height: 582px;
      }
   }
}

.sing {
   display: flex;
   flex-direction: column;
   gap: vw(32px, 320px);
   padding: 0px vw(16px, 320px);
   padding-bottom: vw(32px, 320px);

   @media screen and (min-width: 526px) {
      gap: vw(32px, 744px);
      padding: 0px vw(24px, 744px);
      padding-bottom: vw(32px, 744px);
   }
   @media screen and (min-width: 921px) {
      gap: vw(32px);
      padding: 0px;
      padding-bottom: vw(32px);
   }
   @media screen and (min-width: 1440px) {
      gap: 32px;
      padding: 0px;
      padding-bottom: 32px;
   }

   &__title {
      @include head-2();
      text-align: center;
      color: $black;
   }

   &__form {
      max-width: none;
      width: 100%;

      @media screen and (min-width: 921px) {
         max-width: vw(690px);
      }
      @media screen and (min-width: 1440px) {
         max-width: 690px;
      }
   }
}
