@import "../../../styles/mixins.scss";
@import "../../../styles/helpers.scss";
@import "../../../styles/colors.scss";
@import "../../../styles/effects.scss";

.documents {
   display: grid;
   grid-template-columns: 1fr;
   grid-template-rows: auto;
   row-gap: vw(12px, 320px);
   column-gap: 0px;
   margin: vw(32px, 320px) vw(16px, 320px) vw(24px, 320px) vw(16px, 320px);
   width: auto;

   @media screen and (min-width: 526px) {
      grid-template-columns: 1fr 1fr;
      row-gap: vw(32px, 744px);
      column-gap: vw(32px, 744px);
      margin: vw(32px, 744px) vw(24px, 744px);
   }

   @media screen and (min-width: 921px) {
      grid-template-columns: vw(388px) vw(388px);
      row-gap: vw(32px);
      column-gap: vw(32px);
      width: vw(1200px);
      margin: vw(64px) auto;
   }

   @media screen and (min-width: 1440px) {
      grid-template-columns: 388px 388px;
      row-gap: 32px;
      column-gap: 32px;
      width: 1200px;
      margin: 64px auto;
   }

   &__link {
      position: relative;

      display: flex;
      flex-direction: row;
      align-items: center;
      gap: vw(12px, 320px);
      padding: vw(16px, 320px) vw(8px, 320px);
      width: 100%;
      height: vw(64px, 320px);
      border-radius: vw(12px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(12px, 744px);
         padding: vw(16px, 744px) vw(8px, 744px);
         height: vw(84px, 744px);
         border-radius: vw(12px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(12px);
         padding: vw(16px) vw(8px);
         height: vw(84px);
         border-radius: vw(12px);
      }

      @media screen and (min-width: 1440px) {
         gap: 12px;
         padding: 16px 8px;
         height: 84px;
         border-radius: 12px;
      }

      &:focus,
      &:active {
         @include focus-secondary();
      }
   }

   &__icon {
      width: vw(32px, 320px);
      height: vw(32px, 320px);
      min-width: vw(32px, 320px);
      min-height: vw(32px, 320px);

      @media screen and (min-width: 526px) {
         width: vw(48px, 744px);
         height: vw(48px, 744px);
         min-width: vw(48px, 744px);
         min-height: vw(48px, 744px);
      }

      @media screen and (min-width: 921px) {
         width: vw(48px);
         height: vw(48px);
         min-width: vw(48px);
         min-height: vw(48px);
      }

      @media screen and (min-width: 1600px) {
         width: 48px;
         height: 48px;
         min-width: 48px;
         min-height: 48px;
      }
   }

   &__text {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
      @include text-regular();
      color: $black;
   }

   &__input {
      display: none;
   }
}
