@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";

.calculator {
   display: flex;
   flex-direction: column;
   width: 100vw;

   padding: vw(32px, 320px) vw(16px, 320px);

   @media screen and (min-width: 526px) {
      padding: vw(68px, 744px) vw(24px, 744px);
   }

   @media screen and (min-width: 921px) {
      width: vw(1200px);
      margin: 0px auto;
      padding: vw(68px) 0px;
   }

   @media screen and (min-width: 1440px) {
      width: 1200px;
      margin: 0px auto;
      padding: 68px 0px;
   }
   
   &__banner {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      
      justify-content: center;
      height: vw(168px, 320px);

      @media screen and (min-width: 526px) {
         padding-top: 0px;
         justify-content: flex-start;
         height: vw(273px, 744px);
      }

      @media screen and (min-width: 921px) {
         height: auto;
         gap: vw(64px);
         padding-top: vw(32px);
      }

      @media screen and (min-width: 1440px) {
         gap: 64px;
         padding-top: 32px;
      }
   }

   &__bannerText {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 100%;
      height: 100%;

      @media screen and (min-width: 526px) {
         width: vw(568px, 744px);
         padding: vw(16px, 744px) 0px 0px vw(72px, 744px);
         margin-bottom: auto;
      }

      @media screen and (min-width: 921px) {
         width: vw(568px);
         padding: vw(16px) 0px 0px vw(72px);
         margin-bottom: auto;
      }

      @media screen and (min-width: 1440px) {
         width: 568px;
         padding: 16px 0px 0px 72px;
      }
   }

   &__bannerH1 {
      line-height: 100%;
      transform: rotate(-4deg);
      font-family: "Raleway", sans-serif;

      font-size: vw(36px, 320px);
      max-width: vw(160px, 320px);
      padding-left: vw(16px, 320px);

      @media screen and (min-width: 526px) {
         font-size: vw(70px, 744px);
         padding-left: 0px;
         max-width: none;
      }

      @media screen and (min-width: 921px) {
         font-size: vw(70px);
      }

      @media screen and (min-width: 1440px) {
         font-size: 70px;
      }
   }

   &__bannerH2 {
      width: max-content;
      background: $prim;
      color: $white;
      font-weight: 500;
      border-radius: 1000px;
      line-height: 100%;
      font-family: "Raleway", sans-serif;

      font-size: vw(16px, 320px);
      padding: vw(6px, 320px) vw(20px, 320px);
      transform: rotate(8deg) translate3d(vw(24px, 320px), vw(44px, 320px), 0px);

      @media screen and (min-width: 526px) {
         font-size: vw(20px, 744px);
         padding: vw(12px, 744px) vw(32px, 744px);
         transform: rotate(8deg) translate3d(vw(32px, 744px), vw(116px, 744px), 0px);
      }

      @media screen and (min-width: 921px) {
         font-size: vw(20px);
         padding: vw(12px) vw(32px);
         transform: rotate(8deg) translate3d(vw(32px), vw(116px), 0px);
      }

      @media screen and (min-width: 1440px) {
         font-size: 20px;
         padding: 12px 32px;
         transform: rotate(8deg) translate3d(32px, 116px, 0px);
      }
   }

   &__bannerH3 {
      width: max-content;
      background: $sec;
      color: $white;
      font-weight: 500;
      border-radius: 1000px;
      line-height: 100%;
      font-family: "Raleway", sans-serif;

      font-size: vw(24px, 320px);
      padding: vw(6px, 320px) vw(20px, 320px);
      transform: rotate(-4deg) translate3d(vw(12px, 320px), vw(-16px, 320px), 0px);

      @media screen and (min-width: 526px) {
         transform: rotate(-4deg);
         font-size: vw(48px, 744px);
         padding: vw(12px, 744px) vw(32px, 744px);
      }

      @media screen and (min-width: 921px) {
         font-size: vw(48px);
         padding: vw(12px) vw(32px);
      }

      @media screen and (min-width: 1440px) {
         font-size: 48px;
         padding: 12px 32px;
      }
   }

   &__bannerImageMobile {
      position: absolute;
      bottom: 0px;

      left: vw(24px, 320px);
      width: vw(241.02px, 320px);
      height: vw(180px, 320px);
      
      @media screen and (min-width: 526px) {
         display: none;
      }
   }

   &__bannerImage {
      object-fit: contain;
      object-position: bottom center;
      display: none;

      @media screen and (min-width: 526px) {
         display: block;
         position: absolute;
         right: 0px;
         bottom: 0px;
         transform: translateX(vw(-32px, 744px));
         width: vw(299px, 744px);
         height: vw(207px, 744px);
      }

      @media screen and (min-width: 921px) {
         position: static;
         transform: none;
         width: vw(441px);
         height: vw(305px);
      }

      @media screen and (min-width: 1440px) {
         width: 441px;
         height: 305px;
      }
   }

   &__form {
      width: 100%;
      display: flex;
      align-items: flex-start;
      background: #f4f8f0;
      
      flex-direction: column;
      gap: vw(32px, 320px);
      padding: vw(24px, 320px) vw(16px, 320px);
      border-radius: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         flex-direction: row;
         gap: vw(48px, 744px);
         padding: vw(24px, 744px);
         border-radius: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(48px);
         padding: vw(24px);
         border-radius: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         gap: 48px;
         padding: 24px;
         border-radius: 24px;
      }
   }

   &__formArea {
      display: flex;
      flex-direction: column;
      width: 100%;
      
      gap: vw(16px, 320px);

      @media screen and (min-width: 526px) {
         min-width: vw(300px, 744px);
         max-width: vw(300px, 744px);
         gap: vw(16px, 744px);
      }

      @media screen and (min-width: 921px) {
         min-width: vw(724px);
         max-width: vw(724px);
         gap: vw(16px);
      }

      @media screen and (min-width: 1440px) {
         min-width: 724px;
         max-width: 724px;
         gap: 16px;
      }
   }

   &__formFields {
      display: flex;
      flex-direction: column;
      width: 100%;
      
      gap: vw(24px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(24px, 744px);
      }

      @media screen and (min-width: 921px) {
         flex-direction: row;
         gap: vw(24px);
      }

      @media screen and (min-width: 1440px) {
         gap: 24px;
      }
   }

   &__formField {
      width: 100%;
      display: flex;
      flex-direction: column;
   
      gap: vw(2px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(4px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(4px);
      }

      @media screen and (min-width: 1440px) {
         gap: 4px;
      }
   }

   &__formFieldLabel {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
   }

   &__formFieldLabelLeft {
      @include head-4();
   }

   &__formFieldLabelRight {
      @include head-4();
   }

   &__formFieldInput {
   

      @media screen and (min-width: 526px) {

      }

      @media screen and (min-width: 921px) {

      }

      @media screen and (min-width: 1440px) {

      }
   }

   &__formDocs {
      @include text-regular();
   }

   &__formDocsLink {
      color: $sec-dark;
      text-decoration: underline;
   }

   &__formInfo {
      width: 100%;
      display: flex;
      flex-direction: column;

      gap: vw(8px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(12px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(12px);
      }

      @media screen and (min-width: 1440px) {
         gap: 12px;
      }
   }

   &__formInfoField {
      width: 100%;
      @include head-4();
   }

   &__formInfoValue {
      @include head-4();
      font-weight: 700;
      color: $sec-dark;
   }

   &__formSubmit {
   
      margin-top: vw(16px, 320px);
      gap: vw(8px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(8px, 744px);
         margin-top: vw(12px, 744px);
         width: vw(220px, 744px);
      }

      @media screen and (min-width: 921px) {
         gap: vw(8px);
         margin-top: vw(12px);
         width: vw(220px);
      }

      @media screen and (min-width: 1440px) {
         gap: 8px;
         margin-top: 12px;
         width: 220px;
      }
   }

   &__formSubmitText {
   

      @media screen and (min-width: 526px) {

      }

      @media screen and (min-width: 921px) {

      }

      @media screen and (min-width: 1440px) {

      }
   }

   &__formSubmitIcon {
      min-width: vw(16px, 320px);
      min-height: vw(16px, 320px);
      max-width: vw(16px, 320px);
      max-height: vw(16px, 320px);

      @media screen and (min-width: 526px) {
         min-width: vw(20px, 744px);
         min-height: vw(20px, 744px);
         max-width: vw(20px, 744px);
         max-height: vw(20px, 744px);
      }

      @media screen and (min-width: 921px) {
         min-width: vw(20px);
         min-height: vw(20px);
         max-width: vw(20px);
         max-height: vw(20px);
      }

      @media screen and (min-width: 1440px) {
         min-width: 20px;
         min-height: 20px;
         max-width: 20px;
         max-height: 20px;
      }
   }
}
