@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/effects.scss";
@import "../../../styles/colors.scss";

.aboutLoanCard {
   display: flex;
   flex-direction: column;
   gap: vw(24px, 320px);
   border-radius: vw(12px, 320px);
   padding: vw(24px, 320px) vw(16px, 320px) vw(16px, 320px) vw(16px, 320px);
   @include shadow-bottom-element();

   &__body,
   &__about {
      display: flex;
      flex-direction: column;
      align-items: center;
   }
   &__about {
      gap: vw(4px, 320px);
   }
   &__suptext,
   &__title {
      text-align: center;
      color: $black;
   }
   &__suptext {
      @include text-regular();
   }
   &__title {
      @include head-1();
   }
   &__procent {
      @include sub-text();
      color: $gray;
   }
   &__footer,
   &__chips {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
   }
   &__icon {
      max-width: vw(24px, 320px);
      max-height: vw(24px, 320px);
      min-width: vw(24px, 320px);
      min-height: vw(24px, 320px);
   }
   &__chips {
      gap: vw(8px, 320px);
      align-items: center;
   }
   &__chips {
      &__body {
         display: flex;
         flex-direction: column;
      }
   }
   &__chips {
      &__text {
         @include sub-text();
         color: $black;
      }
   }
}
