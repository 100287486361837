@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.exception {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   justify-content: center;
   align-items: center;
   text-align: center;
   max-width: 100%;

   margin: 0px auto;

   margin-top: vw(50px, 320px);

   @media screen and (min-width: 526px) {
      max-width: vw(500px, 744px);
      margin-top: vw(50px, 744px);
   }

   @media screen and (min-width: 921px) {
      max-width: vw(500px, 1920px);
      margin-top: vw(50px, 1920px);
   }

   @media screen and (min-width: 1600px) {
      max-width: 500px;
      margin-top: 50px;
   }

   &__icon {
      width: vw(64px, 320px);
      height: vw(64px, 320px);
      margin-bottom: vw(8px, 320px);

      @media screen and (min-width: 526px) {
         width: vw(130px, 744px);
         height: vw(130px, 744px);
         margin-bottom: vw(8px, 744px);
      }

      @media screen and (min-width: 921px) {
         width: vw(130px, 1920px);
         height: vw(130px, 1920px);
         margin-bottom: vw(8px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         width: 130px;
         height: 130px;
         margin-bottom: 8px;
      }
   }

   &__title {
      font-weight: 500;
      letter-spacing: -0.01em;
      text-align: center;

      font-size: vw(18px, 320px);
      line-height: vw(18px, 320px);
      margin-bottom: vw(16px, 320px);

      @media screen and (min-width: 526px) {
         font-size: vw(24px, 744px);
         line-height: vw(24px, 744px);
         margin-bottom: vw(16px, 744px);
      }

      @media screen and (min-width: 921px) {
         font-size: vw(24px, 1920px);
         line-height: vw(24px, 1920px);
         margin-bottom: vw(16px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         font-size: 24px;
         line-height: 24px;
         margin-bottom: 16px;
      }
   }

   &__text {
      font-weight: 400;
      letter-spacing: -0.01em;
      text-align: center;

      font-size: vw(16px, 320px);
      line-height: vw(19px, 320px);
      margin-bottom: vw(32px, 320px);

      @media screen and (min-width: 526px) {
         font-size: vw(16px, 744px);
         line-height: vw(19px, 744px);
         margin-bottom: vw(32px, 744px);
      }

      @media screen and (min-width: 921px) {
         font-size: vw(16px, 1920px);
         line-height: vw(19px, 1920px);
         margin-bottom: vw(32px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         font-size: 16px;
         line-height: 19px;
         margin-bottom: 32px;
      }
   }
}

.red {
   background: $error !important;
   color: $white !important;
}
