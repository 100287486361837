@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.documents__list {
   display: flex;
   flex-direction: column;
   width: 100%;
   gap: vw(12px, 320px);

   @media screen and (min-width: 526px) {
      gap: vw(12px, 744px);
   }
   @media screen and (min-width: 921px) {
      gap: vw(12px);
   }
   @media screen and (min-width: 1440px) {
      gap: 12px;
   }
}
.documents__listItem {
   display: block;
}
