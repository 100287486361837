@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.help {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   align-items: flex-start;
   justify-content: space-between;
   gap: vw(24px, 1920px);
   max-width: vw(720px, 1920px);

   @media screen and (min-width: 1600px) {
      gap: 24px;
      max-width: 720px;
   }

   &__title {
      font-weight: 600;
      letter-spacing: -0.01em;
      text-align: left;

      color: $main-dark;

      font-size: vw(32px, 1920px);
      line-height: vw(32px, 1920px);

      @media screen and (min-width: 1600px) {
         font-size: 32px;
         line-height: 32px;
      }
   }
   &__description {
      font-weight: 400;
      letter-spacing: -0.01em;
      text-align: left;

      color: $main-dark;

      font-size: vw(20px, 1920px);
      line-height: vw(24px, 1920px);

      @media screen and (min-width: 1600px) {
         font-size: 20px;
         line-height: 24px;
      }
   }
}

.list {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   align-items: flex-start;
   justify-content: space-between;
   gap: vw(12px, 1920px);

   @media screen and (min-width: 1600px) {
      gap: vw(12px, 1920px);
   }

   &__item {
      list-style: decimal;

      font-weight: 400;
      letter-spacing: -0.01em;
      text-align: left;

      color: $main-dark;

      font-size: vw(20px, 1920px);
      line-height: vw(24px, 1920px);
      margin-left: vw(42px, 1920px);

      @media screen and (min-width: 1600px) {
         font-size: 20px;
         line-height: 24px;
         margin-left: 42px;
      }
   }
}

.form {
   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   align-items: flex-start;
   justify-content: space-between;
   width: 100%;
   gap: vw(42px, 1920px);

   @media screen and (min-width: 1600px) {
      gap: 42px;
   }

   &__textarea {
      textarea {
         resize: none !important;
      }
   }
}
