@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.link {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 80%;
   background-color: $ultra-light;
   font-weight: 600;
   letter-spacing: 0em;
   text-align: left;

   font-size: vw(16px);
   line-height: vw(20px);
   border-radius: vw(10px);

   @media screen and (min-width: 1440px) {
      font-size: 16px;
      line-height: 20px;
      border-radius: 10px;
   }
}
