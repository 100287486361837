@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.wrapper {
   position: fixed;

   left: 0px;
   top: 0px;
   z-index: 100;
   overflow: hidden;

   display: flex;
   flex-direction: column;
   flex-wrap: nowrap;
   align-items: center;
   justify-content: center;

   width: 100vw;
   height: 100vh;

   background: hsla(253, 28%, 86%, 0.69);
}

.popup {
   display: grid;
   grid-template-columns: 1fr;
   background: $ultra-light;

   grid-template-rows: vw(48px, 320px) 1fr;
   width: vw(300px, 320px);
   min-height: vw(271px, 320px);
   max-height: vw(890px, 320px);
   box-shadow: 0px 0px vw(32px, 320px) hsla(0, 0%, 0%, 0.15);
   border-radius: vw(20px, 320px);

   @media screen and (min-width: 526px) {
      grid-template-rows: vw(48px, 744px) 1fr;
      width: vw(420px, 744px);
      min-height: vw(271px, 744px);
      max-height: vw(890px, 744px);
      box-shadow: 0px 0px vw(32px, 744px) hsla(0, 0%, 0%, 0.15);
      border-radius: vw(20px, 744px);
   }

   @media screen and (min-width: 921px) {
      grid-template-rows: vw(48px, 1920px) 1fr;
      width: vw(530px, 1920px);
      min-height: vw(271px, 1920px);
      max-height: vw(890px, 1920px);
      box-shadow: 0px 0px vw(32px, 1920px) hsla(0, 0%, 0%, 0.15);
      border-radius: vw(20px, 1920px);
   }

   @media screen and (min-width: 1600px) {
      grid-template-rows: 48px 1fr;
      box-shadow: 0px 0px 32px hsla(0, 0%, 0%, 0.15);
      border-radius: 20px;
      width: 530px;
      min-height: 271px;
      max-height: 890px;
   }

   &__header {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: space-between;

      padding: 0px vw(16px, 320px);

      @media screen and (min-width: 526px) {
         padding: 0px vw(16px, 744px);
      }

      @media screen and (min-width: 921px) {
         padding: 0px vw(16px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         padding: 0px 16px;
      }

      button {
         display: flex;
         flex-direction: column;
         flex-wrap: nowrap;
         align-items: center;
         justify-content: center;

         width: vw(24px, 320px);
         height: vw(24px, 320px);

         @media screen and (min-width: 526px) {
            width: vw(24px, 744px);
            height: vw(24px, 744px);
         }

         @media screen and (min-width: 921px) {
            width: vw(24px, 1920px);
            height: vw(24px, 1920px);
         }

         @media screen and (min-width: 1600px) {
            width: 24px;
            height: 24px;
         }
      }
   }
   &__title {
      font-weight: 400;
      letter-spacing: 0em;
      text-align: left;

      font-size: vw(18px, 320px);
      line-height: vw(22px, 320px);

      @media screen and (min-width: 526px) {
         font-size: vw(18px, 744px);
         line-height: vw(22px, 744px);
      }

      @media screen and (min-width: 921px) {
         font-size: vw(18px, 1920px);
         line-height: vw(22px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         font-size: 18px;
         line-height: 22px;
      }
   }
   &__main {
      background: $white;

      padding: vw(24px, 320px);
      border-radius: vw(20px, 320px);

      overflow-y: auto;

      &::-webkit-scrollbar-track {
         -webkit-box-shadow: none;
         background-color: $ultra-light;
      }

      &::-webkit-scrollbar {
         width: 8px;
         background-color: $white;
      }

      &::-webkit-scrollbar-thumb {
         background-color: $main;
         border: none;
      }

      @media screen and (min-width: 526px) {
         padding: vw(24px, 744px);
         border-radius: vw(20px, 744px);
      }

      @media screen and (min-width: 921px) {
         padding: vw(24px, 1920px);
         border-radius: vw(20px, 1920px);
      }

      @media screen and (min-width: 1600px) {
         padding: 24px;
         border-radius: 20px;
      }
   }
}
