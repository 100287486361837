@import "../../../styles/helpers.scss";
@import "../../../styles/mixins.scss";
@import "../../../styles/colors.scss";

.documents,
.documents__heading {
   display: flex;
   flex-direction: column;
}

.documents {
   gap: vw(24px, 320px);

   @media screen and (min-width: 526px) {
      gap: vw(24px, 744px);
   }
   @media screen and (min-width: 921px) {
      gap: vw(24px);
   }
   @media screen and (min-width: 1440px) {
      gap: 24px;
   }

   &__heading {
      gap: vw(4px, 320px);

      @media screen and (min-width: 526px) {
         gap: vw(4px, 744px);
      }
      @media screen and (min-width: 921px) {
         gap: vw(4px);
      }
      @media screen and (min-width: 1440px) {
         gap: 4px;
      }
   }

   &__title,
   &__subtitle {
      color: $black;
   }

   &__title {
      @include head-3();
   }

   &__subtitle {
      @include text-regular();
   }
}
