@import "../../../styles/colors.scss";
@import "../../../styles/helpers.scss";

.crumbs {
   display: flex;
   flex-direction: row;
   flex-wrap: nowrap;
   justify-content: flex-start;
   align-items: center;

   gap: vw(14px, 1920px);

   @media screen and (min-width: 1600px) {
      gap: 14px;
   }

   &__back {
      width: vw(24px, 1920px);
      height: vw(24px, 1920px);

      cursor: pointer;

      @media screen and (min-width: 1600px) {
         width: 24px;
         height: 24px;
      }
   }

   &__link,
   a > span,
   &__block > span {
      font-weight: 500;
      letter-spacing: 0em;
      text-align: left;

      color: $secondary-dark;

      font-size: vw(20px, 1920px);
      line-height: vw(24px, 1920px);

      @media screen and (min-width: 1600px) {
         font-size: 20px;
         line-height: 24px;
      }
   }

   &__block {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
      justify-content: flex-start;

      gap: vw(14px, 1920px);

      @media screen and (min-width: 1600px) {
         gap: 14px;
      }
   }
}
